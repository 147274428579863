@use "global" as *;

.m-container {

  @include media(pc) {
    max-width: calc(1140px + 60px);
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  @include media(sp) {
    padding-left: sp(20);
    padding-right: sp(20);
  }
}

.m-p {
  line-height: 1.777;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-lineHeading {
  line-height: 1.4;
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-lineHeading-top {
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::before {
    display: block;
    content: '';
    background: rgb(79,163,194);
    background: linear-gradient(90deg, rgba(79,163,194,1) 0%, rgba(79,163,194,1) 46.5%, rgba(255,255,255,0) 46.5%, rgba(255,255,255,0) 53.5%, rgba(178,168,110,1) 53.5%);
    @include media(pc) {
      width: 42px;
      height: 4px;
      margin-bottom: 30px;
    }

    @include media(sp) {
      width: sp(30);
      height: sp(4);
      margin-bottom: sp(20);
    }
  }

  &.m-lineHeading-switch {
    &::before {
      display: none;
    }

    &::after {
      display: block;
      content: '';
      background: rgb(79,163,194);
      background: linear-gradient(90deg, rgba(79,163,194,1) 0%, rgba(79,163,194,1) 46.5%, rgba(255,255,255,0) 46.5%, rgba(255,255,255,0) 53.5%, rgba(178,168,110,1) 53.5%);
      @include media(pc) {
        width: 42px;
        height: 4px;
        margin-top: 30px;
      }

      @include media(sp) {
        width: sp(30);
        height: sp(4);
        margin-top: sp(20);
      }
    }
  }
}

.m-lineHeading-bottom {
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::after {
    display: block;
    content: '';
    background: rgb(79,163,194);
    background: linear-gradient(90deg, rgba(79,163,194,1) 0%, rgba(79,163,194,1) 46.5%, rgba(255,255,255,0) 46.5%, rgba(255,255,255,0) 53.5%, rgba(178,168,110,1) 53.5%);
    @include media(pc) {
      width: 42px;
      height: 4px;
      margin-top: 30px;
    }

    @include media(sp) {
      width: sp(30);
      height: sp(4);
      margin-top: sp(20);
    }
  }

  &.m-lineHeading-switch {
    &::after {
      display: none;
    }

    &::before {
      display: block;
      content: '';
      background: rgb(79,163,194);
      background: linear-gradient(90deg, rgba(79,163,194,1) 0%, rgba(79,163,194,1) 46.5%, rgba(255,255,255,0) 46.5%, rgba(255,255,255,0) 53.5%, rgba(178,168,110,1) 53.5%);
      @include media(pc) {
        width: 42px;
        height: 4px;
        margin-top: 30px;
      }

      @include media(sp) {
        width: sp(30);
        height: sp(4);
        margin-top: sp(20);
      }
    }
  }
}

.m-lineHeading-left {
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: calc(50% - 1px);
    background-color: $color-primary;
    @include media(pc) {
    }

    @include media(sp) {
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    height: calc(50% - 1px);
    background-color: $color-secondary;
    @include media(pc) {
    }

    @include media(sp) {
    }
  }

  &.m-lineHeading-large::before,&.m-lineHeading-large::after {
    @include media(pc) {
      width: 8px;
    }

    @include media(sp) {
      width: sp(6);
    }
  }
  &.m-lineHeading-large {
    @include media(pc) {
      padding-left: 40px;
    }

    @include media(sp) {
      padding-left: sp(40);
    }
  }

  &.m-lineHeading-small::before,&.m-lineHeading-small::after {
    @include media(pc) {
      width: 5px;
    }

    @include media(sp) {
      width: sp(5);
    }
  }
  &.m-lineHeading-small {
    @include media(pc) {
      padding-left: 20px;
    }

    @include media(sp) {
      padding-left: sp(20);
    }
  }

  &.m-lineHeading-medium::before,&.m-lineHeading-medium::after {
    @include media(pc) {
      width: 5px;
    }

    @include media(sp) {
      width: sp(5);
    }
  }
  &.m-lineHeading-medium {
    @include media(pc) {
      padding-left: 20px;
    }

    @include media(sp) {
      padding-left: sp(20);
    }
  }


}

.m-lineHeading-large {
  font-weight: 900;
  @include media(pc) {
    font-size: 44px;
  }

  @include media(sp) {
    font-size: sp(30);
  }
}

.m-lineHeading-small {
  font-weight: 700;
  @include media(pc) {
    font-size: 22px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.m-lineHeading-medium {
  font-weight: 800;
  @include media(pc) {
    font-size: 24px;
  }

  @include media(sp) {
    font-size: sp(21);
  }
}

.m-lineHeading-center {
  text-align: center;
  @include media(pc) {

  }

  @include media(sp) {

  }
  &::after {
    margin-left: auto;
    margin-right: auto;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.m-btns {
  display: flex;
  @include media(pc) {

  }

  @include media(sp) {
    flex-direction: column;
    align-items: flex-start;
  }

}

.m-btns-center {
  text-align: center;
  @include media(pc) {
    justify-content: center;
  }

  @include media(sp) {
    align-items: center;
  }
}

.m-btns-center-pc {
  @include media(pc) {
    justify-content: center;
    text-align: center;
  }
}

.m-btns-center-sp {
  @include media(sp) {
    align-items: center;
    text-align: center;
  }
}

.m-btn {
  background-color: #4FA3C2;
  display: flex;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  @include media(pc) {
    border-radius: 35px;
    font-size: 20px;
    padding: 12px 35px;
  }

  @include media(sp) {
    border-radius: sp(40);
    font-size: sp(17);
    padding: sp(12) sp(35);
  }

  transition: all .4s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2D8DB1;
    transition: all .4s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }

  & + & {
    @include media(pc) {
      margin-left: 30px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }

  .m-icon {
    align-self: center;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.m-btn-outline {
  border: 2px solid $color-primary;
  background-color: #fff;
  color: $color-primary;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &:hover {
    &::before {
      display: none;
    }
  }
}

.m-icon {
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::after {
    font-family: 'Material Icons';
    content: attr(data-icon);
    font-weight: 400;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.m-indent {
  display: flex;
}

.m-indent_type {
  flex: 0 0 auto;
}

.m-btn {
  .m-icon {
    &[data-icon="arrow_forward_ios"] {
      margin-top: 0.2rem;
    }
  }
}


.m-lead {
  @include media(pc) {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media(sp) {

  }
}

.m-section {
  @include media(pc) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @include media(1366px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-top: sp(60);
    padding-bottom: sp(60);
  }
}

.m-object-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-link {
  text-decoration: underline;
  @include media(pc) {

  }

  @include media(sp) {

  }
  &:hover {
    text-decoration: none;
  }
}

.m-link {
  text-decoration: underline;
  @include media(pc) {

  }

  @include media(sp) {

  }
  &:hover {
    text-decoration: none;
  }
}

.m-contact {
  position: relative;
  z-index: 8;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-contact_tetsuduki {
  background-color: #fff;
  border: 2px solid #4FA3C2;
  display: flex;
  color: #4FA3C2;
  font-weight: 700;
  @include media(pc) {
    border-radius: 41px;
    font-size: 31px;
    padding: 22px 50px;
  }

  @include media(sp) {
    border-radius: sp(35);
    font-size: sp(22);
    padding: sp(20) sp(50);

  }

  transition: all .5s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #4FA3C2;
    transition: all .5s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;

    }
  }
}

.m-contact_card {
  @include media(pc) {
    display: flex;
    margin-top: 50px;
  }

  @include media(1000px) {
    display: block;
  }

  @include media(sp) {
    margin-top: sp(20);
  }
}

.m-contact_card_item {
  font-weight: 500;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  text-align: center;
  line-height: 1.6;
  @include media(pc) {
    border-radius: 15px;
    width: calc(100% - 60px / 3);
    padding: 35px;
  }

  @include media(1000px) {
    width: 100%;
  }

  @include media(sp) {
    padding: sp(30);
    border-radius: sp(15);
  }

  & + & {
    @include media(1001px, min) {
      margin-left: 30px;
    }
    @include media(1000px) {
      margin-top: 30px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }
}

.m-contact_card_icon {
  color: #4FA3C2;
  line-height: 1;
  @include media(pc) {
    font-size: 60px;
    margin-bottom: 10px;
  }

  @include media(sp) {
    font-size: sp(60);
    margin-bottom: sp(10);
  }
}

.m-contact_card_title {
  color: #B2A86E;
  font-weight: 700;
  line-height: 1;
  @include media(pc) {
    font-size: 22px;
    margin-bottom: 36px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(20);
  }
}

.m-contact_card_tel {
  font-weight: 700;
  color: #333333;
  @include media(pc) {
    font-size: 28px;
    pointer-events: none;
  }

  @include media(sp) {
    font-size: sp(26);
  }
}

.m-contact_card_fax {
  font-weight: 600;
  @include media(pc) {
    font-size: 20px;
    margin-bottom: 10px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(10);
  }
}

.m-contact_card_p {
  text-align: center;
  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {
    font-size: sp(15);
  }
}

.m-contact_card_formBtn {
  line-height: 1.3;
  @include media(pc) {
    font-size: 18px;
    margin-bottom: 32px;
    margin-top: -10px;
  }

  @include media(sp) {
    margin-bottom: sp(20);
  }
}

.m-contact_card_to {
  font-weight: 600;
  @include media(pc) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(20);

  }
}

.m-contact_card_address {
  @include media(pc) {

  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.m-hover-opacity {
  transition: opacity .2s ease;
  &:hover {
    opacity: 0.7;
  }
}

.m-pagetop {
  background-color: #4FA3C2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  color: #fff;
  transition: all .1s ease;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  @include media(pc) {
    position: fixed;
    border-radius: 7px;
    width: 50px;
    height: 50px;
    right: 30px;
    bottom: 30px;
    font-size: 36px;
  }

  @include media(sp) {
    position: absolute;
    border-radius: sp(5);
    width: sp(46);
    height: sp(46);
    right: sp(20);
    bottom: sp(20);
    font-size: sp(30);

  }
  &:active {
    transform: scale(.8);
  }


  @media (any-hover: hover) {
    &:hover {
      filter: brightness(1.1);
    }
  }

  &.is-show {
    opacity: 1;
    pointer-events: auto;
  }
}

.m-headerShift {
  @include media(pc) {
    padding-top: 110px;
  }

  @include media(1300px) {
    padding-top: 80px;
  }

  @include media(1050px) {
    padding-top: 70px;
  }

  @include media(900px) {
    padding-top: 64px;
  }

  @include media(sp) {
    padding-top: sp(74);
  }
}

.m-pageHeader {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media(pc) {
    background-image: url('/_/media/module/pageheader_common.jpg');
  }

  @include media(sp) {
    background-image: url('/_/media/module/pageheader_common-sp.jpg');
  }
}

.m-pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(pc) {
    height: 300px;
  }

  @include media(1366px) {
    height: 240px;
  }

  @include media(sp) {
    height: sp(210);
  }
}
.m-pageHeader_title {
  font-weight: 800;
  @include media(pc) {
    font-size: 54px;
  }

  @include media(sp) {
    font-size: sp(32);
  }
}

.m-breadcrumb {
  background-color: rgba(255,255,255,0.4);
  @include media(pc) {
    padding: 20px 40px;
  }

  @include media(sp) {
    padding: sp(15) sp(20);
  }
}

.m-breadcrumb_item {
  display: inline;
  line-height: 1.4;
  @include media(pc) {
    font-size: 13px;
  }

  @include media(sp) {
    font-size: sp(13);
  }

  & + &::before {
    content: '／';
    margin-left: .6rem;
    margin-right: .8rem;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
  .m-icon {
    color: #4FA3C2;
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.5rem;
    @include media(pc) {
      font-size: 16px;
      margin-top: -2px;
    }

    @include media(sp) {
      font-size: sp(16);
      margin-top: sp(-2);
    }
  }
}

.m-breadcrumb_anchor {
  color: #333;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &:hover {
    text-decoration: underline;
  }
}

.m-column-pc {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }
}

.m-column_item {
  @include media(pc) {
    order: 2;
  }

  @include media(sp) {

  }

  & + & {
    @include media(pc) {

    }

    @include media(sp) {
      margin-top: sp(30);
    }
  }
}

.m-column_item-fix {
  @include media(pc) {
    flex: 0 0 auto;
  }

  @include media(sp) {

  }
}

.m-column-reverse {
  .m-column_item:nth-child(1) { order: 2; }
  .m-column_item:nth-child(2) { order: 1; }
}

.m-hr {
  border: 0;
  margin: 0;
  border-top: 1px solid #DEDEDE;
}

.m-step {
  @include media(pc) {

  }
  @include media(1001px, min) {
    display: flex;
    padding-top: 80px;
    padding-bottom: 150px;
  }
  @include media(1000px) {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  @include media(sp) {
    padding-bottom: sp(60);
  }
}

.m-step_processes {
  @include media(1001px, min) {
    width: 284px;
    flex: 0 0 auto;
  }

  @include media(sp) {
    background-color: #F7F7ED;
    padding-top: sp(30);
    padding-bottom: sp(30);
    margin-left: sp(-20);
    margin-right: sp(-20);
  }
}

.m-step_process {

  @include media(pc) {
    position: sticky;
    position: -webkit-sticky;
    top: calc(110px + 30px);
  }

  @include media(1300px) {
    top: calc(80px + 30px);
  }

  @include media(1050px) {
    top: calc(70px + 30px);
  }

  @include media(900px) {
    top: calc(64px + 30px);
  }
  @include media(sp) {
    padding-left: sp(20);
    padding-right: sp(20);
  }
}

.m-step_process_heading {
  background-color: $color-primary;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,15%);
  @include media(pc) {
    border-radius: 10px;
    height: 56px;
    font-size: 20px;
    margin-bottom: 14px;
  }

  @include media(sp) {
    border-radius: sp(10);
    height: sp(56);
    font-size: sp(20);
    margin-bottom: sp(14);
  }
}

.m-step_content {
  @include media(pc) {
  }
  @include media(1001px, min) {
    margin-left: 60px;
  }

  @include media(1000px) {
    margin-top: 60px;
  }

  @include media(sp) {
    padding-top: sp(30);
  }
}

.m-step_process_list {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-step_process_item {
  background-color: #fff;
  font-weight: 700;
  box-shadow: 0 2px 5px rgba(0,0,0,15%);
  position: relative;
  @include media(pc) {
    border-radius: 10px;
  }

  @include media(sp) {
    border-radius: sp(10);
  }

  & + & {
    @include media(pc) {
      margin-top: 14px;
    }

    @include media(sp) {
      margin-top: sp(13);
    }
    &::before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width:0;
      height:0;
      border-style:solid;
      border-color: #DEDBC2 transparent transparent transparent;
      @include media(pc) {
        top: -13px;
        border-width: 13px 8px 0 8px;
      }

      @include media(sp) {
        top: sp(-13);
        border-width: sp(13) sp(8) 0 sp(8);
      }
    }
  }
}

.m-step_process_anchor {
  display: flex;
  color: #333;
  position: relative;
  transition: opacity .2s ease;
  @include media(pc) {
    padding: 15px 45px 15px 15px;
  }

  @include media(sp) {
    padding: sp(15) sp(45) sp(15) sp(15);
  }

  &::after {
    font-family: 'Material Icons';
    color: $color-primary;
    position: absolute;
    transition: right .2s ease;
    @include media(pc) {
      font-size: 24px;
      right: 15px;
      content: 'chevron_right';
    }

    @include media(sp) {
      font-size: sp(24);
      right: sp(15);
      content: 'expand_more';

    }
  }

  @media (any-hover: hover) {
    &:hover {
      opacity: 0.6;
      &::after {
        @include media(pc) {
          right: 10px;
        }

        @include media(sp) {

        }
      }
    }
  }
}

.m-step_process_num {
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  flex: 0 0 auto;
  @include media(pc) {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    margin-right: 10px;
    padding-bottom: 2px;
  }

  @include media(sp) {
    width: sp(26);
    height: sp(26);
    border-radius: sp(5);
    margin-right: sp(10);
    padding-bottom: sp(2);

  }
}

.m-step_process_text {
  line-height: 1.4;
  @include media(pc) {
  }

  @include media(sp) {
    font-size: sp(19);
  }
}

.m-step_content {
  @include media(pc) {
    width: 100%;
  }

  @include media(sp) {

  }
}

.m-step_heading {
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,15%);
  @include media(pc) {
    padding: 30px;
    border-radius: 10px;
  }

  @include media(sp) {
    padding: sp(20);
    border-radius: sp(10);
  }
}

.m-step_heading_num {
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  flex: 0 0 auto;
  align-self: center;
  @include media(pc) {
    width: 58px;
    height: 58px;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 30px;
  }

  @include media(sp) {
    width: sp(32);
    height: sp(32);
    border-radius: sp(5);
    margin-right: sp(15);
    font-size: sp(19);

  }
}

.m-step_heading_text {
  line-height: 1.4;
  font-weight: 800;
  @include media(pc) {
    font-size: 30px;
    padding-top: 8px;
  }

  @include media(sp) {
    font-size: sp(22);
  }
}

.m-table {
  width: 100%;
  @include media(pc) {
    border-collapse: separate;
    border-spacing: 10px;
  }

  @include media(sp) {

  }
}

.m-table_tbody {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-table_row {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-table_head {
  text-align: left;
  line-height: 1.6;
  white-space: nowrap;
  border-top: 2px solid #4FA3C2;
  @include media(pc) {
    padding: 20px 20px 15px 10px;
    min-width: 150px;
  }

  @include media(sp) {
    padding: sp(15) sp(15) sp(15) 0;
  }
}

.m-table_data {
  line-height: 1.6;
  border-top: 1px solid #DEDEDE;
  @include media(pc) {
    padding: 20px 10px 15px;
  }

  @include media(sp) {
    padding: sp(15) 0 sp(15) sp(15);
  }
}

.m-cardHeading {
  box-shadow: 0 2px 5px rgba(0,0,0,15%);
  background-color: #fff;
  font-weight: 700;
  line-height: 1.45;
  position: relative;
  @include media(pc) {
    font-size: 26px;
    padding: 30px 30px 30px 40px;
    border-radius: 10px;
  }

  @include media(sp) {
    padding: sp(20) sp(20) sp(20) sp(30);
    border-radius: sp(10);
    font-size: sp(22);
  }

  &::before {
    content: '';
    background-color: $color-primary;
    position: absolute;
    left: 0;
    @include media(pc) {
      width: 20px;
      height: 5px;
      top: 46px;
    }

    @include media(sp) {
      width: sp(18);
      height: sp(5);
      top: sp(34);

    }
  }
}

.m-simpleHeader {
  background-color: #FBFBF6;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.m-simpleHeader_content {
  display: flex;
  align-items: center;
  @include media(pc) {
    height: 227px;
  }

  @include media(sp) {
    height: sp(134);
  }
}

.m-simpleHeader_title {
  font-weight: 800;
  @include media(pc) {
    font-size: 40px;
  }

  @include media(sp) {
    font-size: sp(24);
  }
}


.m-inlineContact {
  background-color: #fff;
  @include media(pc) {
    border-radius: 10px;
    border: 4px solid $color-primary;
    display: flex;
    margin-top: 30px;
  }

  @include media(sp) {
    margin-top: sp(20);
    border-radius: sp(10);
    border: sp(4) solid $color-primary;
  }
}

.m-inlineContact_title {
  color: #fff;
  background-color: $color-primary;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media(pc) {
    width: 30%;
  }

  @include media(sp) {
    height: sp(160);
  }
}

.m-inlineContact_body {
  @include media(pc) {
    width: 70%;
    padding: 40px 50px;
  }

  @include media(sp) {
    text-align: center;
    padding: 25px;
  }
}

