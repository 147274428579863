@use "global" as *;

.p-top{
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include media(pc) {
  }

  @include media(sp) {

  }
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}


.p-top_visual {
  position: relative;
  @include media(pc) {
    height: 100vh;
  }

  @include media(sp) {
    height: 100svh;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;

    @include media(pc) {
      background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      top: 0;
      height: 100%;
      width: 70%;
      opacity: 0.5;
    }

    @include media(sp) {
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.99) 99%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      bottom: 0;
      height: 50%;
      width: 100%;
      opacity: 0.7;
    }
  }
}

.p-top_visual_content {
  position: absolute;
  display: flex;
  @include media(pc) {
    padding-left: 120px;
    padding-right: 120px;
    bottom: 190px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  @include media(1920px) {
    padding-left: calc(120 / 1920 * 100vw);
    padding-right: calc(120 / 1920 * 100vw);
  }

  @include media(sp) {
    flex-direction: column;
    bottom: sp(112);
    padding-left: sp(20);
  }
}

.p-top_visual_copy {
  color: #fff;
  @include media(pc) {
  }

  @include media(sp) {
    order: 2;
  }
}

.p-top_visual_copy_main {
  font-weight: 900;
  line-height: 1.276;
  @include media(pc) {
    font-size: 94px;
  }

  @include media(1920px) {
    font-size: calc(94 / 1920 * 100vw);
  }

  @include media(sp) {
    font-size: sp(39);
  }
}

.p-top_visual_copy_lead {
  font-weight: 700;
  line-height: 1.7;
  @include media(pc) {
    font-size: 36px;
    margin-top: 20px;
  }

  @include media(1920px) {
    font-size: calc(36 / 1920 * 100vw);
  }

  @include media(sp) {
    font-size: sp(19);
    margin-top: sp(12);
  }
}

.p-top_visual_university {

  @include media(pc) {
  }

  @include media(sp) {
    order: 1;
    align-self: flex-end;
    margin-bottom: sp(10);
  }
}

.p-top_visual_university_item {
  font-weight: 700;
  color: #fff;
  display: none;
  background-color: rgba(#000, 40%);
  @include media(pc) {
    padding: 20px 35px;
    border-radius: 35px;
    font-size: 30px;
  }

  @include media(1400px) {
    font-size: calc(30 / 1400 * 100vw);
    padding: calc(20 / 1400 * 100vw) calc(35 / 1400 * 100vw);
  }

  @include media(sp) {
    padding: sp(10) sp(20);
    border-radius: sp(30);
    font-size: sp(15);
  }

  &:nth-child(2) { @include media(pc) { margin-top: -30px; } @include media(1400px) { margin-top: calc(-30 / 1400 * 100vw); } @include media(sp) { margin-top: sp(-15); } }
  &:nth-child(3) { @include media(pc) { margin-top: -30px; } @include media(1400px) { margin-top: calc(-30 / 1400 * 100vw); } @include media(sp) { margin-top: sp(-15); } }
  &:nth-child(4) { @include media(pc) { margin-top: -30px; } @include media(1400px) { margin-top: calc(-30 / 1400 * 100vw); } @include media(sp) { margin-top: sp(-15); } }
  &:nth-child(5) { @include media(pc) { margin-top: -30px; } @include media(1400px) { margin-top: calc(-30 / 1400 * 100vw); } @include media(sp) { margin-top: sp(-15); } }
}


.p-top_action {
  transition: width .3s ease;
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @include media(pc) {
    width: 993px;
  }

  @include media(1080px) {
    width: 700px;
  }

  @include media(sp) {

  }
}

.p-top_action_anchor {
  display: block;
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::before {
    transition: opacity .2s ease;
    opacity: 0;
    display: block;
    content: '';
    background-color: rgba(255,255,255,.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include media(pc) {
      border-radius: 20px 20px 0 0;
    }

    @include media(sp) {

    }
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.p-top_action_image {
  width: 100%;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &:hover {
    opacity: 0.8;
  }
}

.p-top_kentai {
  background-color: rgba(255,255,255,80%);
  backdrop-filter: blur(20px);
  position: relative;
  @include media(pc) {
    padding-top: 200px;
    padding-bottom: 150px;
    margin-top: -60px;
  }
  @include media(1336px) {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: -60px;
  }

  @include media(sp) {
    padding-top: sp(60);
    padding-bottom: sp(60);
  }
}

.p-top_kentai_container {
  @include media(pc) {
    display: flex;
  }

  @include media(900px) {
    display: block;
  }

  @include media(sp) {

  }
}

.p-top_kentai_image {
  @include media(pc) {
    order: 2;
    flex-grow: 1;
    margin-left: 40px;
  }

  @include media(900px) {
    max-width: 60%;
    margin-left: 0;
    margin-bottom: 40px;
  }

  @include media(sp) {
    margin-bottom: sp(30);
  }
}

.p-top_kentai_image_item {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_kentai_main {
  @include media(pc) {
    flex-shrink: 0;
    width: 450px;
  }

  @include media(900px) {
    width: 100%;
  }

  @include media(sp) {

  }
}

.p-top_kentai_phrase {
  font-weight: 900;
  line-height: 1.26;
  @include media(pc) {
    font-size: 54px;
    margin-bottom: 20px;
  }

  @include media(sp) {
    font-size: sp(38);
    margin-bottom: sp(15);
  }
}

.p-top_medicine {
  position: relative;

  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_medicine_phrase {
  font-weight: 800;
  line-height: 1.476;
  @include media(pc) {
    font-size: 42px;
    text-align: center;
  }

  @include media(1336px) {
    font-size: 32px;
  }

  @include media(sp) {
    font-size: sp(26);
  }
}

.p-top_medicine_collaboration {
  font-weight: 800;
  border-top: 1px solid #E5E2CD;
  border-bottom: 1px solid #E5E2CD;
  padding: 1rem 0;
  line-height: 1.555;

  @include media(pc) {
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 36px;
  }

  @include media(sp) {
    margin-top: sp(30);
    margin-bottom: sp(30);
    font-size: sp(18);

  }
}

.p-top_medicine_university{
  display: flex;
  align-items: center;
  @include media(pc) {
    gap: 4.2%;
  }
  @include media(sp) {
    flex-wrap: wrap;
    gap: sp(18) sp(30);
  }
}

.p-top_medicine_university_item{
  @include media(sp) {
    width: calc(50% - sp(15));
  }
}

.p-top_medicine_university_item img{
  transition: opacity 0.2s ease;
  @include media(sp) {
    width: 100%;
    display: block;
  }
  &:hover{
    opacity: 0.7;
  }
}
.p-top_medicine_president {
  @include media(pc) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
  }
  @include media(1336px) {
    max-width: (360 * 2 + 30px);
  }

  @include media(sp) {
    margin-top: sp(50);
  }
}

.p-top_medicine_president_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    flex: 0 0 calc((100% - 60px) / 3);
    max-width: 360px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }

  @include media(1336px) {
    flex: 0 0 calc((100% - 30px) / 2);
  }

  @include media(sp) {
    border-radius: sp(15);
  }

  & + & {
    @include media(pc) {

    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }

}

.p-top_medicine_president_comment {
  line-height: 1.777;
  font-weight: 500;
  @include media(pc) {
    padding: 40px 40px 0 40px;
  }

  @include media(sp) {
    padding: sp(30) sp(30) 0 sp(30);

  }
}

.p-top_medicine_president_name {
  line-height: 1.4;
  font-weight: 600;
  @include media(pc) {
    padding: 20px 40px 40px 40px;
    font-size: 20px;
    margin-top: auto;
  }

  @include media(sp) {
    padding: sp(15) sp(30) sp(30) sp(30);
    font-size: sp(18);
  }
}

.p-top_medicine_president_image {
  width: 100%;

  @include media(pc) {
    border-radius: 0 0 15px 15px;
  }

  @include media(sp) {
    border-radius: 0 0 sp(15) sp(15);

  }
}

.p-top_interview {
  position: relative;
  background-color: rgba(255,255,255,80%);
  backdrop-filter: blur(20px);
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_interview_message {
  @include media(pc) {
  }

  @include media(1367px, min) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media(1336px) {
    text-align: center;
  }

  @include media(sp) {
    text-align: center;
  }
}

.p-top_interview_message_number {
  font-weight: 900;
  color: #4FA3C2;
  @include media(pc) {
    font-size: 68px;
    flex: 0 0 auto;
  }

  @include media(1336px) {
    margin-bottom: 20px;
  }


  @include media(sp) {
    font-size: sp(50);
  }
}

.p-top_interview_message_desc {
  font-weight: 700;
  line-height: 1.7;
  @include media(pc) {
    font-size: 22px;
  }

  @include media(1367px, min) {
    margin-left: 20px;
  }

  @include media(sp) {
    font-size: sp(19);
    margin-top: sp(30);
  }
}

.p-top_interview_slider {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_interview_slider_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    display: flex;
    border-radius: 15px;
    min-width: 860px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  @include media(1336px) {
    min-width: 600px;
  }

  @include media(sp) {
    margin-top: sp(36);
    border-radius: sp(15);
    margin-bottom: sp(20);
  }
}

.p-top_interview_slider_image {
  overflow: hidden;
  @include media(pc) {
    border-radius: 15px 0 0 15px;
    width: 50%;
  }

  @include media(sp) {
    border-radius: sp(15) sp(15) 0 0;
  }
}

.p-top_interview_slider_image_item {
  @include media(pc) {
  }

  @include media(sp) {

  }
}

.p-top_interview_slider_content {
  @include media(pc) {
    width: 50%;
    padding: 65px 50px;
  }

  @include media(1336px) {
    padding: 35px 30px;
  }

  @include media(sp) {
    padding: sp(30);
  }
}

.p-top_interview_slider_content_comment {
  font-weight: 700;
  line-height: 1.636;
  @include media(pc) {
    font-size: 22px;
  }

  @include media(1336px) {
    font-size: 20px;
  }

  @include media(sp) {
    font-size: sp(17);
  }
}

.p-top_interview_slider_content_name {
  font-weight: 600;
  line-height: 1.5;
  @include media(pc) {
    margin-top: 20px;
  }

  @include media(sp) {
    font-size: sp(15);
    margin-top: sp(15);
  }
}

.p-top_interview_slider_content_name_initial {
  font-weight: 700;
  color: #B2A86E;
  @include media(pc) {
    font-size: 24px;
  }

  @include media(sp) {
    font-size: sp(22);
  }
}

.p-top_interview_slider_content_anchor {
  color: #4FA3C2;
  font-weight: 700;
  display: flex;
  align-items: center;

  @include media(pc) {
    font-size: 18px;
    margin-top: 20px;
  }

  @include media(sp) {
    font-size: sp(15);
    margin-top: sp(20);
  }
  .m-icon {
    margin-top: 0.2rem;
    @include media(pc) {
    }

    @include media(sp) {

    }
  }
}

.p-top_interview_slider_controller {
  display: flex;
  justify-content: center;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_interview_slider_prev,
.p-top_interview_slider_next {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #4FA3C2;
  color: #4FA3C2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform .1s ease;
  padding: 0;
  margin: 0;
  @include media(pc) {
    width: 44px;
    height: 44px;
    font-size: 30px;
  }

  @include media(sp) {
    width: sp(44);
    height: sp(44);
    font-size: sp(30);

  }

  &:active {
    transform: scale(0.9);
  }

  &::before {
    transition: margin .2s ease;
  }

  &[disabled] {
    filter: saturate(0%);
  }
}

.p-top_interview_slider_prev {
  @include media(pc) {

  }

  @include media(sp) {

  }

  &::before {
    font-family: 'Material Icons';
    content: 'chevron_left';
    @include media(pc) {

    }

    @include media(sp) {

    }
  }

  @media (any-hover: hover) {
    &:hover {
      &::before {
        margin-right: 0.25rem;
      }
    }
  }
}

.p-top_interview_slider_next {
  @include media(pc) {
    margin-left: 20px;

  }

  @include media(sp) {
    margin-left: sp(20);
  }

  &::before {
    font-family: 'Material Icons';
    content: 'chevron_right';
    @include media(pc) {
    }

    @include media(sp) {

    }
  }

  @media (any-hover: hover) {
    &:hover {
      &::before {
        margin-left: 0.25rem;
      }
    }
  }
}

.p-top_about {
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_about_card {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  @include media(1336px) {
    display: block;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.p-top_about_card_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    border-radius: 15px;
    width: 48.5%;
    padding: 50px;
  }

  @include media(1336px) {
    width: 100%;
  }

  @include media(sp) {
    padding: sp(40) sp(30);
    border-radius: sp(15);
  }

  & + & {
    @include media(1336px) {
      margin-top: 30px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }
}

.p-top_about_card_title {
  color: #B2A86E;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #DEDEDE;
  @include media(pc) {
    font-size: 22px;
    padding-bottom: 25px;
  }

  @include media(sp) {
    font-size: sp(20);
    padding-bottom: sp(20);
  }
}

.p-top_about_card_value {
  font-weight: 800;
  color: #4FA3C2;
  text-align: center;
  border-bottom: 1px solid #DEDEDE;
  @include media(pc) {
    font-size: 38px;
    padding: 20px 0;
  }

  @include media(sp) {
    font-size: sp(30);
    padding: sp(20) 0;
  }
}

.p-top_about_card_area {
  font-weight: 800;
  color: #4FA3C2;
  text-align: center;
  @include media(pc) {
    font-size: 38px;
    padding: 20px 0 25px;
  }

  @include media(sp) {
    padding: sp(20) 0 sp(25);
    font-size: sp(30);
  }
}

.p-top_about_card_areaitem {
  font-weight: 500;
  line-height: 1.7;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_about_card_list {
  border-bottom: 1px solid #DEDEDE;
  @include media(pc) {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }

  @include media(sp) {
  }
}

.p-top_about_card_list_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  @include media(pc) {
  }

  @include media(sp) {
    padding: sp(20) 0;
  }

  & + & {
    @include media(pc) {
      border-left: 1px solid #DEDEDE;
      margin-left: 20px;
      padding-left: 20px;
    }

    @include media(sp) {
      border-top: 1px solid #DEDEDE;
    }
  }
}

.p-top_about_card_list_term {
  font-weight: 500;
  @include media(pc) {
    margin-right: 15px;
  }

  @include media(sp) {
    margin-right: sp(20);
  }
}

.p-top_about_card_list_value {
  font-weight: 800;
  @include media(pc) {
    font-size: 28px;
  }

  @include media(sp) {
    font-size: sp(26);
  }
}

.p-top_about_card_date {
  font-weight: 500;
  text-align: center;
  @include media(pc) {
    font-size: 14px;
    padding-top: 25px;
  }

  @include media(sp) {
    font-size: sp(14);
    padding-top: sp(20);
  }
}


.p-top_other {
  background-color: rgba(255,255,255,80%);
  backdrop-filter: blur(20px);
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_other_card {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
  }

  @include media(sp) {

  }
}

.p-top_other_card_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    width: 48.5%;
    border-radius: 15px;
  }

  @include media(sp) {
    border-radius: sp(15);
  }

  & + & {
    @include media(sp) {
      margin-top: sp(20);
    }
  }
}

.p-top_other_card_image {
  overflow: hidden;
  @include media(pc) {
    border-radius: 15px 15px 0 0;
  }

  @include media(sp) {
    border-radius: sp(15) sp(15) 0 0;
  }
}

.p-top_other_card_content {
  @include media(pc) {
    padding: 40px 60px 50px;
  }
  @include media(1336px) {
    padding: 35px 40px 45px;
  }

  @include media(sp) {
    padding: sp(30) sp(30) sp(40) sp(30);
  }
}

.p-top_other_card_title {
  font-weight: 900;
  text-align: center;
  @include media(pc) {
    font-size: 38px;
    margin-bottom: 25px;
  }

  @include media(1336px) {
    font-size: 30px;
  }

  @include media(sp) {
    font-size: sp(28);
  }
}

.p-top_other_card_body {
  line-height: 1.777;
  @include media(pc) {
    margin-bottom: 30px;
  }

  @include media(sp) {
    margin-bottom: sp(20);
  }
}

.p-top_other_card_anchor {
  font-weight: 700;
  color: #4FA3C2;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_membership {
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_membership_card {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
  }

  @include media(1336px) {
    display: block;
  }

  @include media(sp) {

  }
}

.p-top_membership_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    border-radius: 15px;
    width: 48.5%;
    padding: 50px;
  }

  @include media(1336px) {
    width: 100%;
  }

  @include media(sp) {
    border-radius: sp(15);
    padding: sp(40) sp(30);
  }

  & + & {
    @include media(1336px) {
      margin-top: 30px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }

}

.p-top_membership_title {
  font-weight: 700;
  text-align: center;
  @include media(pc) {
    font-size: 26px;
    margin-bottom: 24px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(20);
  }
}

.p-top_membership_list {
  border-bottom: 1px solid #DEDEDE;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_membership_list_wrap {
  font-weight: 500;
  border-top: 1px solid #DEDEDE;
  line-height: 1.5;
  @include media(pc) {
    display: flex;
    padding: 15px 0;
  }

  @include media(sp) {
    padding: sp(15) 0;

  }
}

.p-top_membership_list_term {
  color: #B2A86E;
  @include media(pc) {
    margin-right: 10px;
    width: 7.5rem;
    flex: 0 0 auto;
  }

  @include media(sp) {
    margin-bottom: sp(5);
  }
}

.p-top_membership_list_desc {
  @include media(pc) {
    font-size: 20px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.p-top_membership_magazineImage {
  box-shadow: 0 3px 6px rgba(0,0,0,16%);
  @include media(pc) {
    width: 280px;
    margin: 0 auto 40px;
  }

  @include media(sp) {
    width: sp(280);
    margin: 0 auto sp(30);
  }
}

.p-top_membership_magazineFile {
  border-top: 1px solid #DEDEDE;
  text-align: center;
  color: #4FA3C2;
  font-weight: 500;
  @include media(pc) {
    padding: 15px 0;
    font-size: 20px;
  }

  @include media(sp) {
    padding: sp(15) 0;
    font-size: sp(18);
  }

  .m-icon {
    vertical-align: middle;
    margin-left: 0.5rem;
    @include media(pc) {
      font-size: 28px;
    }

    @include media(sp) {
      font-size: sp(24);
    }
  }
}

.p-top_membership_magazineFile_anchor {
  line-height: 1.4;
  text-decoration: underline;
  color: #4FA3C2;
  @include media(pc) {
  }

  @include media(sp) {

  }

  &:hover {
    text-decoration: none;
  }
}

.p-top_membership_magazine_backnumber {
  border-top: 1px solid #DEDEDE;
  @include media(pc) {
    padding-top: 30px;
  }

  @include media(sp) {
    padding-top: sp(30);
  }
}

.p-top_membership_magazine_backnumber_anchor {
  color: #4FA3C2;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_news {
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-top_news_container {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }
}

.p-top_news_head {
  @include media(pc) {
    flex: 0 0 auto;
    margin-right: 50px;
  }

  @include media(sp) {

  }
}

.p-top_news_content {
  @include media(pc) {
    width: 100%;
  }

  @include media(sp) {

  }
}

.p-top_news_list {
  border-bottom: 1px solid #DEDEDE;
  @include media(pc) {
  }

  @include media(sp) {

  }
}

.p-top_news_list_item {
  border-top: 1px solid #DEDEDE;
  line-height: 1.6;
  display: flex;
  align-items: flex-start;
  @include media(pc) {
    font-size: 16px;
    padding: 30px 10px;
  }

  @include media(sp) {
    flex-wrap: wrap;
    padding: sp(20) 0;
  }
}

.p-top_news_list_time {
  font-weight: 500;
  @include media(pc) {
    flex: 0 0 auto;
  }

  @include media(sp) {

  }
}

.p-top_news_list_category {
  font-weight: 500;
  color: #fff;
  padding: 0.15rem 2rem;
  border-radius: 4px;
  @include media(pc) {
    font-size: 14px;
    margin: 0 20px;
    flex: 0 0 auto;
  }

  @include media(sp) {
    margin-left: sp(20);
    font-size: sp(14);
  }

  &[data-category="info"] { background-color: #4FA3C2; }
  &[data-category="update"] { background-color: #B2A86E; }
}


.p-top_news_list_title {
  @include media(pc) {

  }

  @include media(sp) {
    width: 100%;
    margin-top: sp(10);
  }
}

.p-top_news_list_title_anchor {
  text-decoration: underline;
  color: inherit;
  @include media(pc) {

  }

  @include media(sp) {

  }

  &:hover {
    text-decoration: none;
  }
}

.p-top_blank {
  @include media(pc) {
    height: 100vh;
  }

  @include media(sp) {

  }
}
