@use "global" as *;

.p-kentai_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(pc) { background-image: url(/_/media/page/kentai/pageheader.jpg); }
  @include media(sp) { background-image: url(/_/media/page/kentai/pageheader-sp.jpg); }
}

.p-kentai_about {
  @include media(pc) {
    padding-top: 70px;
    padding-bottom: 110px;
  }

  @include media(sp) {
    padding-top: sp(60);
    padding-bottom: sp(60);
  }
}


.p-kentai_about_column {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }
}

.p-kentai_about_column_content {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-kentai_title {
  font-weight: 700;
  line-height: 1.583;
  @include media(pc) {
    font-size: 24px;
    margin-bottom: 30px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(20);
  }
}

.p-kentai_about_column_image {
  @include media(pc) {
    flex: 0 0 auto;
    margin-left: 40px;
  }

  @include media(1366px) {
    flex: 0 0 auto;
    margin-left: 40px;
    width: 40%;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.p-kentai_about_eye {
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  @include media(pc) {
    padding: 40px 0;
    margin-top: 60px;
  }

  @include media(sp) {
    padding: sp(30) 0;
    margin-top: sp(40);
  }
}

.p-kentai_about_eye_title {
  @include media(pc) {
    font-size: 20px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.p-kentai_flow_column {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {

  }
}

.p-kentai_flow_column_content {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-kentai_flow_column_image {
  @include media(pc) {
    margin-top: 40px;
    margin-left: 40px;
    flex: 0 0 auto;
  }

  @include media(1366px) {
    width: 40%;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.p-kentai_flow_btns {
  @include media(1040px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .m-btn {
    @include media(1040px) {

    }

    & + .m-btn {
      @include media(1040px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
