@use "global" as *;

.p-medicine_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(pc) { background-image: url(/_/media/page/medicine/pageheader.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/pageheader-sp.jpg); }
}

.p-medicine_university {
  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 150px;
  }

  @include media(sp) {
    padding-top: sp(60);
    padding-bottom: sp(60);
  }
}

.p-medicine_university_message {
  text-align: center;
  font-weight: 800;
  line-height: 1.476;
  @include media(pc) {
    font-size: 42px;
  }

  @include media(1366px) {
    font-size: 32px;
  }

  @include media(sp) {
    font-size: sp(26);
  }
}

.p-medicine_university_commentTitle {
  font-weight: 800;
  border-top: 1px solid #E5E2CD;
  border-bottom: 1px solid #E5E2CD;
  padding: 1rem 0;
  line-height: 1.555;

  @include media(pc) {
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 36px;
  }

  @include media(sp) {
    margin-top: sp(30);
    margin-bottom: sp(30);
    font-size: sp(18);
    text-align: center;
  }
}

.p-medicine_university_president {
  @include media(pc) {
    margin-top: 60px;
  }

  @include media(sp) {
    margin-top: sp(40);
  }
}

.p-medicine_university_president_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    border-radius: 15px;
    display: flex;
  }

  @include media(sp) {
    border-radius: sp(15);
  }

  & + & {
    @include media(pc) {
      margin-top: 30px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }
}

.p-medicine_university_president_image {
  @include media(pc) {
    width: 38%;
    flex: 0 0 auto;
  }

  @include media(sp) {

  }
}

.p-medicine_university_president_image_item {
  object-fit: cover;
  width: 100%;
  height: 100%;
  @include media(pc) {
    border-radius: 15px 0 0 15px;
  }

  @include media(sp) {
    border-radius: sp(15) sp(15) 0 0;
  }
}

.p-medicine_university_president_content {
  @include media(pc) {
    padding: 60px;
  }

  @include media(1366px) {
    padding: 40px;
  }

  @include media(sp) {
    padding: sp(40) sp(30);
  }
}

.p-medicine_university_president_header {
  @include media(pc) {
    margin-bottom: 30px;
  }
  @include media(1001px, min) {
    display: flex;
    justify-content: space-between;
  }

  @include media(1366px) {

  }

  @include media(sp) {
    margin-bottom: sp(20);
  }
}

.p-medicine_university_president_name {
  font-weight: 700;
  line-height: 1.5;
  @include media(pc) {

  }

  @include media(sp) {
    margin-bottom: sp(20);
  }
}

.p-medicine_university_president_name_full {
  @include media(pc) {
    font-size: 26px;
  }

  @include media(sp) {
    font-size: sp(24);
  }
}

.p-medicine_university_president_logo {
  @include media(pc) {

  }
  @include media(1366px) {
    margin-top: 20px;
  }

  @include media(sp) {
    font-size: sp(24);
    max-width: sp(154);
  }
}

.p-medicine_university_president_body {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-medicine_special {
  @include media(pc) {
  }

  @include media(sp) {

  }
}

.p-medicine_special_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    border-radius: 15px;
    display: flex;
  }

  @include media(sp) {
    border-radius: sp(15);
  }

}

.p-medicine_special_image {
  @include media(pc) {
    width: 38%;
    flex: 0 0 auto;
  }

  @include media(sp) {

  }
}

.p-medicine_special_image_item {
  object-fit: cover;
  width: 100%;
  height: 100%;
  @include media(pc) {
    border-radius: 15px 0 0 15px;
  }

  @include media(sp) {
    border-radius: sp(15) sp(15) 0 0;
  }
}

.p-medicine_special_content {
  @include media(pc) {
    padding: 60px;
  }

  @include media(1366px) {
    padding: 40px;
  }

  @include media(sp) {
    padding: sp(40) sp(30);
  }
}


.p-medicine_special_subTitle {
  font-weight: 700;
  color: $color-primary;
  @include media(pc) {
    font-size: 20px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.p-medicine_special_title {
  font-weight: 800;
  line-height: 1.5;
  @include media(pc) {
    font-size: 32px;
    margin-top: 15px;
  }

  @include media(sp) {
    font-size: sp(24);
    margin-top: sp(15);
  }
}

.p-medicine_special_name {
  font-weight: 700;
  line-height: 1.5;
  @include media(pc) {
    margin-top: 20px;
  }

  @include media(sp) {
    margin-top: sp(20);
  }
}

.p-medicine_special_name_full {
  @include media(pc) {
    font-size: 26px;
  }

  @include media(sp) {
    font-size: sp(24);
  }
}

.p-medicine_interview_lead {
  @include media(pc) {

  }

  @include media(sp) {
    font-size: sp(19);
  }
}

.p-medicine_interview_box {
  @include media(pc) {
    margin-top: 80px;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.p-medicine_interview_box_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  @include media(pc) {
    border-radius: 15px;
    display: flex;
  }

  @include media(sp) {
    border-radius: sp(15);
  }

  & + & {
    @include media(pc) {
      margin-top: 30px;
    }

    @include media(sp) {
      margin-top: sp(20);
    }
  }
}

.p-medicine_interview_box_image {
  @include media(pc) {
    width: 38%;
    flex: 0 0 auto;
  }

  @include media(sp) {

  }
}

.p-medicine_interview_box_image_item {
  object-fit: cover;
  width: 100%;
  height: 100%;
  @include media(pc) {
    border-radius: 15px 0 0 15px;
  }

  @include media(sp) {
    border-radius: sp(15) sp(15) 0 0;
  }
}

.p-medicine_interview_box_content {
  @include media(pc) {
    padding: 88px 60px;
  }

  @include media(1366px) {
    padding: 58px 40px;
  }

  @include media(sp) {
    padding: sp(30);
  }
}

.p-medicine_interview_box_comment {
  font-weight: 700;
  line-height: 1.5;
  @include media(pc) {
    font-size: 24px;
    margin-bottom: 15px;
  }

  @include media(sp) {
    font-size: sp(17);
    margin-bottom: sp(15);
  }
}

.p-medicine_interview_box_name {
  line-height: 1.5;
  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {
    font-size: sp(15);
  }
}

.p-medicine_interview_box_name_initial {
  color: $color-secondary;
  font-weight: 700;
  @include media(pc) {
    font-size: 24px;
  }

  @include media(sp) {
    font-size: sp(22);
  }
}


.p-medicineInterview_pageHeader {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media(pc) {
    height: 790px;
  }

  @include media(1366px) {
    height: 500px;
  }

  @include media(sp) {
    height: sp(446);
  }
}

.p-medicineInterview_pageHeader-special {
  @include media(pc) { background-image: url(/_/media/page/medicine/interview/pageheader_special.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/interview/pageheader_special-sp.jpg); }
}

.p-medicineInterview_pageHeader-05 {
  @include media(pc) { background-image: url(/_/media/page/medicine/interview/pageheader_05.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/interview/pageheader_05-sp.jpg); }
}

.p-medicineInterview_pageHeader-04 {
  @include media(pc) { background-image: url(/_/media/page/medicine/interview/pageheader_04.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/interview/pageheader_04-sp.jpg); }
}

.p-medicineInterview_pageHeader-03 {
  @include media(pc) { background-image: url(/_/media/page/medicine/interview/pageheader_03.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/interview/pageheader_03-sp.jpg); }
}

.p-medicineInterview_pageHeader-02 {
  @include media(pc) { background-image: url(/_/media/page/medicine/interview/pageheader_02.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/interview/pageheader_02-sp.jpg); }
}

.p-medicineInterview_pageHeader-01 {
  @include media(pc) { background-image: url(/_/media/page/medicine/interview/pageheader_01.jpg); }
  @include media(sp) { background-image: url(/_/media/page/medicine/interview/pageheader_01-sp.jpg); }
}


.p-medicineInterview_section {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-medicineInterview_container {
  background-color: #fff;
  @include media(pc) {
    padding-top: 70px;
    padding-bottom: 150px;
    margin-top: -240px;
    border-radius: 15px;
    padding-left: 120px;
    padding-right: 120px;
  }

  @include media(1366px) {
    margin-top: -180px;
    padding-left: 80px;
    padding-right: 80px;
  }

  @include media(sp) {
    padding-top: sp(50);
    padding-bottom: sp(50);
  }
}

.p-medicineInterview_heading {
  font-weight: 900;
  line-height: 1.312;
  text-align: center;
  @include media(pc) {
    font-size: 64px;
  }

  @include media(1366px) {
    font-size: 48px;
  }

  @include media(sp) {
    font-size: sp(40);
  }
}

.p-medicineInterview_name {
  font-weight: 700;
  text-align: center;
  @include media(pc) {
    margin-top: 30px;
    font-size: 20px;
  }

  @include media(sp) {
    margin-top: sp(20);
    font-size: sp(18);
  }
}

.p-medicineInterview_name_initial {
  color: $color-secondary;
  @include media(pc) {
    font-size: 30px;
  }

  @include media(sp) {
    font-size: sp(28);

  }
}

.p-medicineInterview_item {
  @include media(pc) {
    margin-top: 100px;
  }

  @include media(sp) {
    margin-top: sp(60);
  }
}

.p-medicineInterview_question {
  font-weight: 700;
  color: $color-primary;
  line-height: 1.444;
  text-align: center;
  @include media(pc) {
    font-size: 22px;
    margin-bottom: 30px;
  }

  @include media(1366px) {
    font-size: 20px;
  }

  @include media(sp) {
    margin-bottom: sp(20);
  }
}

.p-medicineInterview_answerTitle {
  line-height: 1.333;
  font-weight: 800;
  text-align: center;
  @include media(pc) {
    font-size: 44px;
    margin-bottom: 30px;
  }

  @include media(1366px) {
    font-size: 38px;
  }

  @include media(sp) {
    margin-bottom: sp(30);
    font-size: sp(30);
  }
}

.p-medicineInterview_answerBody {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-medicineInterview_answerImage {
  @include media(pc) {
    margin-top: 60px;
  }

  @include media(sp) {
    margin-top: sp(40);
    margin-left: sp(-20);
    margin-right: sp(-20);
  }
}

.p-medicineInterview_pagination {
  display: flex;
  justify-content: center;
  @include media(pc) {
    margin-top: 100px;
    margin-left: -10px;
  }

  @include media(sp) {
    margin-top: sp(40);
  }
}

.p-medicineInterview_pagination_prev {
  @include media(pc) {
    width: 40px;
  }

  @include media(sp) {
    width: sp(40);
  }
  transition: opacity .2s ease;
  &:hover {
    opacity: 0.5;
  }

  .p-medicineInterview_pagination_icon {
    @include media(pc) {
      font-size: 30px;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    @include media(sp) {
      font-size: sp(30);
      margin-top: sp(6);
      margin-bottom: sp(20);
    }
  }
}

.p-medicineInterview_pagination_list {
  @include media(pc) {
    margin-left: 100px;
    margin-right: 120px;
  }

  @include media(sp) {
    margin-left: sp(60);
    margin-right: sp(70);
  }
  transition: opacity .2s ease;
  &:hover {
    opacity: 0.5;
  }
}

.p-medicineInterview_pagination_next {
  @include media(pc) {
    width: 40px;
  }

  @include media(sp) {
    width: sp(40);
  }
  transition: opacity .2s ease;
  &:hover {
    opacity: 0.5;
  }

  .p-medicineInterview_pagination_icon {
    @include media(pc) {
      font-size: 30px;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    @include media(sp) {
      font-size: sp(30);
      margin-top: sp(6);
      margin-bottom: sp(20);
    }
  }
}

.p-medicineInterview_pagination_icon {
  color: $color-primary;
  display: block;
  text-align: center;
  @include media(pc) {
    font-size: 40px;
    margin-bottom: 15px;
  }

  @include media(sp) {
    font-size: sp(40);
    margin-bottom: sp(15);
  }
}

.p-medicineInterview_pagination_text {
  color: $color-primary;
  display: block;
  text-align: center;
  font-weight: 700;
  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {

  }
}
