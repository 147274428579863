@use "global" as *;

.p-tetsuduki {
  background-color: #FBFBF6;
}
.p-tetsuduki_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(pc) { background-image: url(/_/media/page/tetsuduki/pageheader.jpg); }
  @include media(sp) { background-image: url(/_/media/page/tetsuduki/pageheader-sp.jpg); }
}

.p-tetsuduki_pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media(pc) {
    height: 510px;
    padding-bottom: 100px;
  }
  @include media(1366px) {
    height: 410px;
    padding-bottom: 150px;
  }

  @include media(sp) {
    height: sp(340);
    padding-bottom: sp(100);
  }
}

.p-tetsuduki_pageHeader_title {
  font-weight: 800;
  @include media(pc) {
    font-size: 54px;
  }

  @include media(1366px) {
    font-size: 50px;
  }

  @include media(sp) {
    font-size: sp(32);
  }
}

.p-tetsuduki_contact {
  background-color: #fff;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0,0,0,16%);
  @include media(pc) {
    border-radius: 20px;
    border: 8px solid $color-primary;
    padding: 60px;
    margin-top: -150px;
  }

  @include media(sp) {
    border-radius: sp(15);
    border: sp(4) solid $color-primary;
    margin-top: sp(-100);
    padding: sp(40) sp(26);
  }
}

.p-tetsuduki_card {
  @include media(pc) {

  }

  @include media(sp) {
    width: sp(280);
    margin-left: auto;
    margin-right: auto;
  }
}

.p-tetsuduki_contact_title {
  color: $color-primary;
  font-weight: 800;
  line-height: 1.3;
  @include media(pc) {
    font-size: 40px;
    margin-bottom: 24px;
  }

  @include media(sp) {
    font-size: sp(32);
    margin-bottom: sp(15);
  }
}

.p-tetsuduki_contact_body {
  font-weight: 700;
  line-height: 1.8;
  @include media(pc) {
    font-size: 20px;
  }

  @include media(sp) {
    font-size: sp(18);
  }
}

.p-tetsuduki_numberList {
  border-bottom: 1px solid #D6D6D6;

  @include media(pc) {
    margin-top: 30px;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.p-tetsuduki_numberList_item {
  border-top: 1px solid #D6D6D6;
  counter-increment: numberList;
  font-weight: 600;
  display: flex;
  line-height: 1.777;
  @include media(pc) {
    padding: 15px 0;
  }

  @include media(sp) {
    padding: sp(13) 0;

  }

  &::before {
    font-weight: 800;
    color: $color-secondary;
    content: counter(numberList)". ";
    flex: 0 0 auto;
    width: 1.3rem;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.p-tetsuduki_address {
  background-color: #fff;
  @include media(pc) {
    padding: 35px 40px;
  }

  @include media(sp) {
    padding: sp(20);
  }
}

.p-tetsuduki_address_title {
  font-weight: 700;
  display: flex;
  @include media(pc) {
    font-size: 22px;
    margin-bottom: 20px;
  }

  @include media(sp) {
    font-size: sp(20);
    margin-bottom: sp(15);
  }

  &::before {
    content: '';
    background-color: $color-primary;
    flex: 0 0 auto;
    @include media(pc) {
      width: 17px;
      height: 5px;
      margin-right: 15px;
      margin-top: 10px;
    }

    @include media(sp) {
      width: sp(17);
      height: sp(5);
      margin-right: sp(15);
      margin-top: sp(7);

    }
  }
}

.p-tetsuduki_address_list {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-tetsuduki_address_list_wrap {
  border-top: 1px solid #E8E8E8;
  line-height: 1.4;
  @include media(pc) {
    display: flex;
    align-items: center;
    padding: 13px 0;
  }

  @include media(sp) {
    padding: sp(10) 0;
  }
}

.p-tetsuduki_address_list_time {
  @include media(pc) {
    width: 35%;
    margin-right: 1rem;
  }

  @include media(sp) {
    margin-bottom: sp(5);
  }
}

.p-tetsuduki_address_list_data {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-tetsuduki_cardlost {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-tetsuduki_cardlost_header {
  color: #fff;
  text-align: center;
  font-weight: 700;
  background-color: $color-primary;
  @include media(pc) {
    padding: 25px 25px 20px 25px;
    font-size: 22px;
    border-radius: 10px 10px 0 0;
    line-height: 1.3;
  }

  @include media(sp) {
    line-height: 1.5;
    padding: sp(10) sp(20) sp(15) sp(20);
    font-size: sp(19);
    border-radius: sp(10) sp(10) 0 0;

  }
}

.p-tetsuduki_cardlost_body {
  background-color: #fff;
  @include media(pc) {
    border: 4px solid $color-primary;
    padding: 30px 30px 40px 30px;
    border-radius: 0 0 10px 10px;
  }

  @include media(sp) {
    border: sp(4) solid $color-primary;
    padding: sp(25) sp(25) sp(30) sp(25);
    border-radius: 0 0 sp(10) sp(10);
  }
}
