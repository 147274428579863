@use "global" as *;

.l-footer {
  position: relative;
  z-index: 8;
  background-color: #fff;

  @include media(pc) {
    padding-top: 100px;
    padding-bottom: 150px;
  }

  @include media(1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @include media(sp) {
    padding-top: sp(60);
    padding-bottom: sp(60);
  }
}

.l-footer_main {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
  }
  @include media(1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @include media(sp) {

  }
}

.l-footer_main_info {
  @include media(pc) {

  }
  @include media(1080px) {
    text-align: center;
  }

  @include media(sp) {

  }
}

.l-footer_main_info_logo {
  @include media(pc) {
    margin-bottom: 20px;
  }

  @include media(sp) {
    margin-bottom: sp(20);
    width: sp(304);
    margin-left: auto;
    margin-right: auto;
  }
}

.l-footer_main_info_logo_anchor {

  @include media(pc) {

  }

  @include media(sp) {

  }
}

.l-footer_main_info_address {
  line-height: 1.75;
  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {
    text-align: center;
  }
}

.l-footer_main_info_action {
  background-color: #4FA3C2;
  display: flex;
  align-items: center;
  @include media(pc) {
    height: 48px;
    border-radius: 24px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    max-width: 330px;
  }

  @include media(1080px) {
    margin-left: auto;
    margin-right: auto;
  }

  @include media(sp) {
    height: sp(48);
    border-radius: sp(24);
    padding-left: sp(30);
    padding-right: sp(30);
    margin-top: sp(40);
    justify-content: center;
  }
}

.l-footer_main_info_action_item {
  @include media(pc) {

  }

  @include media(sp) {
    font-size: sp(18);
  }

  & + & {
    @include media(pc) {
      margin-left: 35px;
    }

    @include media(sp) {
      margin-left: sp(40);
    }
  }
}

.l-footer_main_info_action_anchor {
  color: #fff;
  font-weight: 700;
  @include media(pc) {

  }

  @include media(sp) {

  }
  transition: opacity .2s ease;
  &:hover {
    opacity: 0.6;
  }
}

.l-footer_main_nav {
  @include media(pc) {

  }

  @include media(1080px) {
    margin-top: 40px;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.l-footer_main_nav_list {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {
    border-top: 1px solid #e0e0e0;
  }
}

.l-footer_main_nav_item {
  line-height: 1.5;
  @include media(pc) {

    font-size: 16px;
  }

  @include media(sp) {

  }

  & + & {
    @include media(pc) {
      margin-left: 50px;
    }
  }
}

.l-footer_main_nav_anchor {
  color: #333;
  font-weight: 700;
  transition: color .2s ease;
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {
    display: block;
    padding: sp(15) sp(55) sp(15) sp(20);
    border-bottom: 1px solid #e0e0e0;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $color-primary;
    }
  }

  &::after {
    @include media(sp) {
      font-weight: 400;
      pointer-events: none;
      position: absolute;
      font-family: 'Material Icons';
      content: 'chevron_right';
      color: #4FA3C2;
      font-size: sp(22);
      right: sp(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.l-footer_main_nav_anchor-accordion {
  &::after {
    @include media(sp) {
      content: 'add_circle';
      transition: transform .2s ease;
    }
  }

  &.is-open {
    &::after {
      @include media(sp) {
        transform: translateY(-50%) rotate(180deg);
        content: 'remove_circle_outline';
      }
    }
  }
  @include media(pc) {

  }

  @include media(sp) {
    pointer-events: none;
  }
}

.l-footer_main_nav_childList {
  @include media(pc) {
    margin-top: 16px;
    display: block !important;
  }

  @include media(sp) {
    display: none;
  }
}

.l-footer_main_nav_childItem {
  @include media(pc) {
    font-size: 14px;
    margin-bottom: 10px;
  }

  @include media(sp) {

  }
}

.l-footer_main_nav_childAnchor {
  color: #333;
  transition: color .2s ease;
  font-weight: 700;
  position: relative;
  @include media(pc) {

  }

  @include media(sp) {
    display: block;
    padding: sp(15) sp(55) sp(15) sp(30);
    border-bottom: 1px solid #e0e0e0;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $color-secondary;
    }
  }

  &::after {
    @include media(sp) {
      font-weight: 400;
      pointer-events: none;
      position: absolute;
      font-family: 'Material Icons';
      content: 'chevron_right';
      color: #4FA3C2;
      font-size: sp(22);
      right: sp(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.l-footer_sub {
  @include media(pc) {
    display: flex;
    margin-top: 40px;
    align-items: center;
  }

  @include media(1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
  }

  @include media(sp) {
    margin-top: sp(30);
  }
}

.l-footer_sub_list {
  @include media(pc) {

  }

  @include media(sp) {
    text-align: center;
  }
}

.l-footer_sub_list_item {
  display: inline-block;
  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {
    font-size: sp(15);
  }

  & + &::before {
    content: '|';
    margin-left: 1rem;
    margin-right: 1rem;
    @include media(pc) {

    }

    @include media(sp) {

    }
  }
}

.l-footer_sub_list_anchor {
  color: #333;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.l-footer_sub_copyright {
  font-weight: 400;
  @include media(pc) {
    margin-left: 50px;
    font-size: 13px;
  }

  @include media(1080px) {
    display: block;
    margin-top: 50px;
    margin-left: 0;
  }

  @include media(sp) {
    display: block;
    line-height: 1.777;
    font-size: sp(13);
    margin-top: sp(30);
  }
}

