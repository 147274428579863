@use "global" as *;

.p-contact_flow {
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  @include media(pc) {
    margin: 60px 0 50px;
  }

  @include media(sp) {
    margin: sp(40) 0;
  }
}

.p-contact_flow_item {
  width: 33.33%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #989898;
  letter-spacing: 0.26rem;
  position: relative;
  @include media(pc) {
    height: 60px;
  }

  @include media(sp) {
    height: sp(50);
  }

  &:nth-child(1) {
    border-top: 1px solid #989898;
    border-left: 1px solid #989898;
    border-bottom: 1px solid #989898;
    @include media(pc) {
      border-radius: 10px 0 0 10px;
    }

    @include media(sp) {
      border-radius: sp(10) 0 0 sp(10);

    }
  }

  &:nth-child(2) {
    border-top: 1px solid #989898;
    border-bottom: 1px solid #989898;
    @include media(pc) {

    }

    @include media(sp) {
      padding-left: sp(10);
    }
  }

  &:nth-child(3) {
    border-top: 1px solid #989898;
    border-right: 1px solid #989898;
    border-bottom: 1px solid #989898;
    @include media(pc) {
      border-radius: 0 10px 10px 0;
    }

    @include media(sp) {
      border-radius: 0 sp(10) sp(10) 0;
      padding-left: sp(10);
    }

    &::before, &::after {
      display: none;
    }
    span {
      &::before, &::after {
        display: none;
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #989898;
    z-index: 2;
    @include media(pc) {
      border-width: 30px 0 30px 13px;
      right: -13px;
    }

    @include media(sp) {
      border-width: sp(25) 0 sp(25) sp(13);
      right: sp(-13);
    }
  }

  &::after {
    position: absolute;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    z-index: 2;
    @include media(pc) {
      border-width: 30px 0 30px 13px;
      right: -12px;
    }

    @include media(sp) {
      border-width: sp(25) 0 sp(25) sp(13);
      right: sp(-12);
    }
  }

  &.is-current {
    color: #fff;
    background-color: #4FA3C2;
    span {
      &::before {
        position: absolute;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent #4FA3C2;
        z-index: 3;
        @include media(pc) {
          border-width: 30px 0 30px 13px;
          right: -12px;
        }

        @include media(sp) {
          border-width: sp(25) 0 sp(25) sp(13);
          right: sp(-12);
        }
      }
    }
  }
}


#js-formSubmit {
  pointer-events: none;
  filter: grayscale(100%);
  &.is-active {
    pointer-events: auto;
    filter: grayscale(0);
  }
}

#mfp_confirm_table {
  width: 100%;
  tr {
    border-top: 1px solid #DEDEDE;
    line-height: 1.5;

    @include media(sp) {
      display: block;
    }
  }

  th {
    text-align: left;
    vertical-align: top;
    @include media(pc) {
      padding: 35px;
      width: 30%;

    }

    @include media(sp) {
      display: block;
      padding: sp(20) 0 sp(15) 0;
      font-size: sp(18);
    }

  }

  td {
    @include media(pc) {
      padding: 35px 35px 35px 0;
    }

    @include media(sp) {
      display: block;
      padding: 0 0 sp(20) 0;

    }
  }
}

.mfp_buttons {
  display: flex;
  @include media(pc) {
    justify-content: center;
  }

  @include media(sp) {
    flex-direction: column;
    align-items: center;
  }

}
#mfp_button_send {
  order: 2;
  background-color: #4FA3C2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s;
  border: 2px solid #4FA3C2;
  cursor: pointer;

  @include media(pc) {
    width: 240px;
    border-radius: 35px;
    font-size: 20px;
    padding: 12px 35px;
    margin-left: 30px;
  }

  @include media(sp) {
    width: sp(240);
    margin-top: sp(30);
    border-radius: sp(40);
    font-size: sp(17);
    padding: sp(12) sp(35);
  }
  &:hover {
    opacity: 0.7;
  }

  &::after {
    font-family: "Material Icons";
    content: 'chevron_right';
    margin-left: 0.6rem;
  }
}
#mfp_button_cancel {
  background-color: #4FA3C2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  order: 1;
  cursor: pointer;
  border: 2px solid #4FA3C2;
  background-color: #fff;
  color: #4FA3C2;
  @include media(pc) {
    width: 240px;
    border-radius: 35px;
    font-size: 20px;
    padding: 12px 35px;

  }

  @include media(sp) {
    width: sp(240);
    border-radius: sp(40);
    font-size: sp(17);
    padding: sp(12) sp(35);

  }
  &:hover {
    opacity: 0.7;
  }

  &::before {
    font-family: "Material Icons";
    content: 'chevron_left';
    margin-right: 0.6rem;
  }
}

.mfp_err {
  color: #C93B3B;
  display: block;
  @include media(pc) {
    font-size: 16px;
    margin-top: 10px;
  }

  @include media(sp) {
    font-size: sp(14);
    margin-top: sp(5);
  }
}

.p-contact_err {
  color: #C93B3B;
  border: 2px solid #C93B3B;
  line-height: 1.6;
  @include media(pc) {
    font-size: 18px;
    padding: 26px;
    border-radius: 10px;
    text-align: center;
  }

  @include media(sp) {
    border-radius: sp(10);
    padding: sp(15);

  }
}

.formgrid .mfp_err {
  @include media(pc) {

  }

  @include media(sp) {
    grid-row: 2;
    grid-column: 1/span 2;
  }
}

[for="js-agreement"] + .mfp_err {
  margin-top: 1rem;
}
