@use "global" as *;


.p-donation_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(pc) { background-image: url(/_/media/page/donation/pageheader.jpg); }
  @include media(sp) { background-image: url(/_/media/page/donation/pageheader-sp.jpg); }
}

.p-donation_pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media(pc) {
    height: 510px;
    padding-bottom: 100px;
  }
  @include media(1366px) {
    height: 410px;
    padding-bottom: 150px;
  }

  @include media(sp) {
    height: sp(340);
    padding-bottom: sp(100);
  }
}

.p-donation_pageHeader_title {
  font-weight: 800;
  @include media(pc) {
    font-size: 54px;
  }

  @include media(1366px) {
    font-size: 50px;
  }

  @include media(sp) {
    font-size: sp(32);
  }
}
