@use "global" as *;

.l-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: background-color .3s ease ,box-shadow .3s ease;
  @include media(pc) {
  }

  @include media(sp) {
    
  }

  &.is-scrolled {
    background-color: #fff;
    @include media(pc) {
      box-shadow: 0 0 16px rgba(0,0,0,.1);
    }

    @include media(sp) {

    }
  }
  &:not(.is-scrolled).is-transparent {
    background-color: transparent;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(229,229,229,0) 100%);
      z-index: 1;
    }

  }
}

.l-header_container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media(pc) {
    height: 110px;
  }

  @include media(1300px) {
    height: 80px;
  }

  @include media(1050px) {
    height: 70px;
  }

  @include media(900px) {
    height: 64px;
  }

  @include media(sp) {
    height: sp(74);
  }
}

.l-header_logo {
  @include media(pc) {
    padding-left: 30px;
  }

  @include media(1050px) {
    padding-left: 20px;
  }

  @include media(900px) {
    padding-left: 10px;
  }

  @include media(sp) {
    padding-left: sp(15);
  }
}

.l-header_logo_anchor {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.l-header_logo_image {
  @include media(pc) {

  }

  @include media(1300px) {
    width: 240px;
  }

  @include media(1050px) {
    width: 200px;
  }

  @include media(900px) {
    width: 180px;
  }

  @include media(sp) {
    width: sp(245);
  }
}
