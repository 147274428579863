@use "global" as *;



.faq-box-question .faq-open-icon:before{
  content:"add";
  display: block;
  font-family: "Material Icons";
  line-height: 1;
  transition: transform 0.2s ease;
}
.faq-box-question.is-open{
  opacity: 1!important;
}
.faq-box-question.is-open .faq-open-icon:before{
  content:'remove' ;
  transform: rotate(180deg);
}