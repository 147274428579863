@use "global" as *;

html {
  background: #fff;
  color: #333333;
  font-family: 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
  overflow-x: hidden;
  font-weight: 500;

  @include media(pc) {
    font-size: 18px;
  }

  @include media(sp) {
    font-size: sp(16);
  }
}

input, textarea, button {
  font-family: 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', sans-serif;
  @include media(pc) {
    font-size: 16px;
  }

  @include media(sp) {

  }
}
