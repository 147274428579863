@use "global" as *;

.l-nav {
  @include media(pc) {
  }

  @include media(sp) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background-color: #FBFBF6;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;
}

  &.is-open {
    @include media(pc) {

    }

    @include media(sp) {
      opacity: 1;
      pointer-events: auto;
    }

  }
}

.l-nav_content {
  display: flex;
  @include media(pc) {
    align-items: center;
  }

  @include media(sp) {
    flex-direction: column;
    height: calc(100dvh - sp(74));
    padding-left: sp(20);
    padding-right: sp(20);
    overflow-y: auto;
    overscroll-behavior-y: none;
  }
}

.l-nav_main {
  @include media(pc) {
    display: flex;
  }

  @include media(sp) {
    order: 2;
    font-weight: 700;
    margin-top: sp(30);
    border-top: 1px solid #e0e0e0;
    padding-bottom: sp(40);
  }
}

.l-nav_main_item {

  @include media(pc) {
    font-weight: 600;
    font-size: 18px;
    margin-left: 40px;
  }

  @include media(1300px) {
    font-size: 16px;
    margin-left: 30px;
  }

  @include media(1050px) {
    font-size: 14px;
    margin-left: 20px;
  }

  @include media(900px) {
    font-size: 13px;
    margin-left: 16px;
  }

  @include media(sp) {

  }
}

.l-nav_main_anchor {
  color: #333;
  text-decoration: none;
  position: relative;
  @include media(pc) {
    transition: opacity .2s ease;
    &:hover {
      opacity: .7;
    }
  }

  @include media(sp) {
    color: #333 !important;
    display: block;
    padding: sp(15) sp(55) sp(15) sp(20);
    border-bottom: 1px solid #e0e0e0;
    line-height: 1.5;
  }

  &::after {
    @include media(sp) {
      font-weight: 400;
      pointer-events: none;
      position: absolute;
      font-family: 'Material Icons';
      content: 'chevron_right';
      color: #4FA3C2;
      font-size: sp(22);
      right: sp(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @at-root {
    .l-header {
      &.is-transparent {
        .l-nav_main_anchor {
          color: #fff;
        }

        &.is-scrolled {
          .l-nav_main_anchor {
            color: #333;
          }
        }
      }

    }
  }
}

.l-nav_main_anchor-accordion {
  @include media(pc) {

  }

  @include media(sp) {
    pointer-events: none;
  }
  &::after {
    @include media(sp) {
      content: 'add_circle';
      transition: transform .2s ease;
    }
  }

  &.is-open {
    &::after {
      @include media(sp) {
        transform: translateY(-50%) rotate(180deg);
        content: 'remove_circle_outline';
      }
    }
  }
}

.l-nav_mainChild {
  @include media(pc) {
    display: none !important;
  }

  @include media(sp) {
    display: none;
  }
}

.l-nav_mainChild_item {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.l-nav_mainChild_anchor {
  @include media(pc) {

  }

  @include media(sp) {
    position: relative;
    color: #333 !important;
    display: block;
    padding: sp(15) sp(55) sp(15) sp(30);
    border-bottom: 1px solid #e0e0e0;
    line-height: 1.5;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $color-secondary;
    }
  }

  &::after {
    @include media(sp) {
      font-weight: 400;
      pointer-events: none;
      position: absolute;
      font-family: 'Material Icons';
      content: 'chevron_right';
      color: #4FA3C2;
      font-size: sp(22);
      right: sp(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }

}

.l-nav_action {
  background-color: #4FA3C2;
  display: flex;
  align-items: center;
  @include media(pc) {
    margin-left: 30px;
    padding-left: 45px;
    height: 56px;
    border-radius: 28px 0 0 28px;
  }

  @include media(1300px) {
    font-size: 16px;
    margin-left: 20px;
    padding-left: 30px;
    height: 42px;
    border-radius: 23px 0 0 23px;
  }

  @include media(1050px) {
    font-size: 14px;
    margin-left: 15px;
    padding-left: 20px;
    height: 36px;
    border-radius: 18px 0 0 18px;
  }

  @include media(900px) {
    font-size: 13px;
    height: 32px;
    order: 1;
  }

  @include media(sp) {
    height: sp(48);
    border-radius: sp(24);
    justify-content: center;
    flex: 0 0 auto;
  }
}

.l-nav_action_item {
  @include media(pc) {
    font-weight: 700;
    font-size: 18px;
    margin-right: 30px;
  }

  @include media(1300px) {
    font-size: 16px;
    margin-right: 20px;
  }

  @include media(1050px) {
    font-size: 14px;
    margin-right: 15px;
  }

  @include media(900px) {
    font-size: 13px;
    margin-right: 12px;
  }

  @include media(sp) {
    font-size: sp(18);
  }

  & + & {
    @include media(sp) {
      margin-left: sp(35);
    }
  }
}

.l-nav_action_anchor {
  color: #fff;
  @include media(pc) {
    transition: opacity .2s ease;
    &:hover {
      opacity: .6;
    }

  }
}

.l-hamburger {
  @include media(pc) {
    display: none;
  }

  @include media(sp) {
    position: absolute;
    right: sp(15);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    margin: 0;
    color: #fff;
    appearance: none;
    cursor: pointer;
    background-color: #4FA3C2;
    height: sp(46);
    width: sp(46);
    border-radius: sp(5);
    font-size: sp(30);
  }
}


.l-hamburgerClose {
  @include media(pc) {
    display: none;
  }

  @include media(sp) {
    display: flex;
    align-items: center;
    height: sp(74);
    padding-right: sp(15);
  }
}

.l-hamburgerClose_item {
  @include media(sp) {
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    margin: 0;
    color: #4FA3C2;
    appearance: none;
    cursor: pointer;
    height: sp(46);
    width: sp(46);
    position: relative;
    overflow-x: hidden;
    border-radius: sp(5);
    font-size: sp(30);
    margin-left: auto;
  }
}
