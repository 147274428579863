@use "global" as *;

.p-nyukai {
  background-color: #FBFBF6;
}

.p-nyukai_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(pc) { background-image: url(/_/media/page/nyukai/pageheader.jpg); }
  @include media(sp) { background-image: url(/_/media/page/nyukai/pageheader-sp.jpg); }
}

.p-nyukai_target {
  background-color: #fff;
  border: 1px solid #D6D6D6;
  @include media(pc) {
    margin-top: 40px;
    padding: 40px;
    border-radius: 10px;
  }

  @include media(sp) {
    padding: sp(20);
    border-radius: sp(10);
    margin-top: sp(30);
  }
}

.p-nyukai_target_age {
  display: flex;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-nyukai_target_age_title {
  @include media(pc) {
    margin-right: 30px;
  }

  @include media(sp) {
    margin-right: sp(30);
  }
}

.p-nyukai_target_age_body {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-nyukai_target_area {
  border-top: 1px solid #D6D6D6;
  @include media(pc) {
    margin-top: 20px;
    padding-top: 20px;
  }

  @include media(sp) {
    margin-top: sp(20);
    padding-top: sp(20);

  }
}

.p-nyukai_target_area_title {
  @include media(pc) {
    margin-bottom: 13px;
  }

  @include media(sp) {
    margin-bottom: sp(13);
  }
}

.p-nyukai_target_area_body {
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-nyukai_target_area_body_items {
  @include media(pc) {
    padding-left: 80px;
    margin-top: 10px;
  }

  @include media(sp) {
    margin-top: sp(10);
  }
}

.p-nyukai_target_area_body_items_detail {
  padding-left: 1rem;
  @include media(pc) {

  }

  @include media(sp) {

  }
}
