@use "global" as *;

.p-kentai_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include media(pc) { background-image: url(/_/media/page/kentai/pageheader.jpg); }
  @include media(sp) { background-image: url(/_/media/page/kentai/pageheader-sp.jpg); }
}

.p-about_nav {
  @include media(pc) {
    padding-top: 130px;
    padding-bottom: 150px;
  }

  @include media(sp) {
    padding-top: sp(20);
    padding-bottom: sp(40);
  }
}

.p-about_nav_list {
  @include media(pc) {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }

  @include media(850px) {
    gap: 20px;
  }

  @include media(sp) {

  }
}

.p-about_nav_list_item {
  box-shadow: 0 6px 8px rgba(0,0,0,10%);
  background-color: #fff;
  @include media(pc) {
    width: 30%;
    border-radius: 15px;
  }

  @include media(1000px) {
    width: 48%;
  }

  @include media(sp) {
    margin-bottom: sp(20);
    border-radius: sp(15);
  }
}

.p-about_nav_list_anchor {
  display: block;
  @include media(pc) {

  }

  @include media(sp) {

  }
}

.p-about_nav_list_image {
  @include media(pc) {
    border-radius: 15px 15px 0 0;
  }

  @include media(sp) {
    border-radius: sp(15) sp(15) 0 0;
  }
}

.p-about_nav_list_text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 700;
  @include media(pc) {
    padding: 30px 0;
  }

  @include media(1000px) {
    font-size: 16px;
  }

  @include media(sp) {
    padding: sp(30) 0;
    font-size: sp(18);
  }

  .m-icon {
    color: $color-primary;
    margin-left: .4rem;
    margin-top: .2rem;
  }
}

