@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700;800;900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
abbr, acronym, address, big, cite, code, del,
dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote::before, blockquote::after, q::before, q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  background: #fff;
  color: #333333;
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", sans-serif;
  overflow-x: hidden;
  font-weight: 500;
}
@media print, screen and (min-width: 781px) {
  html {
    font-size: 18px;
  }
}
@media print, screen and (max-width: 780px) {
  html {
    font-size: 4.1025641026vw;
  }
}

input, textarea, button {
  font-family: "Noto Sans JP", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", sans-serif;
}
@media print, screen and (min-width: 781px) {
  input, textarea, button {
    font-size: 16px;
  }
}
.l-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.l-header.is-scrolled {
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .l-header.is-scrolled {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }
}
.l-header:not(.is-scrolled).is-transparent {
  background-color: transparent;
}
.l-header:not(.is-scrolled).is-transparent::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(229, 229, 229, 0) 100%);
  z-index: 1;
}

.l-header_container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media print, screen and (min-width: 781px) {
  .l-header_container {
    height: 110px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .l-header_container {
    height: 80px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .l-header_container {
    height: 70px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .l-header_container {
    height: 64px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-header_container {
    height: 18.9743589744vw;
  }
}

@media print, screen and (min-width: 781px) {
  .l-header_logo {
    padding-left: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .l-header_logo {
    padding-left: 20px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .l-header_logo {
    padding-left: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-header_logo {
    padding-left: 3.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .l-header_logo_image {
    width: 240px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .l-header_logo_image {
    width: 200px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .l-header_logo_image {
    width: 180px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-header_logo_image {
    width: 62.8205128205vw;
  }
}

@media print, screen and (max-width: 780px) {
  .l-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background-color: #FBFBF6;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav.is-open {
    opacity: 1;
    pointer-events: auto;
  }
}

.l-nav_content {
  display: flex;
}
@media print, screen and (min-width: 781px) {
  .l-nav_content {
    align-items: center;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_content {
    flex-direction: column;
    height: calc(100dvh - 18.9743589744vw);
    padding-left: 5.1282051282vw;
    padding-right: 5.1282051282vw;
    overflow-y: auto;
    overscroll-behavior-y: none;
  }
}

@media print, screen and (min-width: 781px) {
  .l-nav_main {
    display: flex;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_main {
    order: 2;
    font-weight: 700;
    margin-top: 7.6923076923vw;
    border-top: 1px solid #e0e0e0;
    padding-bottom: 10.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .l-nav_main_item {
    font-weight: 600;
    font-size: 18px;
    margin-left: 40px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .l-nav_main_item {
    font-size: 16px;
    margin-left: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .l-nav_main_item {
    font-size: 14px;
    margin-left: 20px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .l-nav_main_item {
    font-size: 13px;
    margin-left: 16px;
  }
}
.l-nav_main_anchor {
  color: #333;
  text-decoration: none;
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .l-nav_main_anchor {
    transition: opacity 0.2s ease;
  }
  .l-nav_main_anchor:hover {
    opacity: 0.7;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_main_anchor {
    color: #333 !important;
    display: block;
    padding: 3.8461538462vw 14.1025641026vw 3.8461538462vw 5.1282051282vw;
    border-bottom: 1px solid #e0e0e0;
    line-height: 1.5;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_main_anchor::after {
    font-weight: 400;
    pointer-events: none;
    position: absolute;
    font-family: "Material Icons";
    content: "chevron_right";
    color: #4FA3C2;
    font-size: 5.641025641vw;
    right: 5.1282051282vw;
    top: 50%;
    transform: translateY(-50%);
  }
}
.l-header.is-transparent .l-nav_main_anchor {
  color: #fff;
}
.l-header.is-transparent.is-scrolled .l-nav_main_anchor {
  color: #333;
}

@media print, screen and (max-width: 780px) {
  .l-nav_main_anchor-accordion {
    pointer-events: none;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_main_anchor-accordion::after {
    content: "add_circle";
    transition: transform 0.2s ease;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_main_anchor-accordion.is-open::after {
    transform: translateY(-50%) rotate(180deg);
    content: "remove_circle_outline";
  }
}

@media print, screen and (min-width: 781px) {
  .l-nav_mainChild {
    display: none !important;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_mainChild {
    display: none;
  }
}

@media print, screen and (max-width: 780px) {
  .l-nav_mainChild_anchor {
    position: relative;
    color: #333 !important;
    display: block;
    padding: 3.8461538462vw 14.1025641026vw 3.8461538462vw 7.6923076923vw;
    border-bottom: 1px solid #e0e0e0;
    line-height: 1.5;
  }
}
@media (any-hover: hover) {
  .l-nav_mainChild_anchor:hover {
    color: #B2A86E;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_mainChild_anchor::after {
    font-weight: 400;
    pointer-events: none;
    position: absolute;
    font-family: "Material Icons";
    content: "chevron_right";
    color: #4FA3C2;
    font-size: 5.641025641vw;
    right: 5.1282051282vw;
    top: 50%;
    transform: translateY(-50%);
  }
}

.l-nav_action {
  background-color: #4FA3C2;
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 781px) {
  .l-nav_action {
    margin-left: 30px;
    padding-left: 45px;
    height: 56px;
    border-radius: 28px 0 0 28px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .l-nav_action {
    font-size: 16px;
    margin-left: 20px;
    padding-left: 30px;
    height: 42px;
    border-radius: 23px 0 0 23px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .l-nav_action {
    font-size: 14px;
    margin-left: 15px;
    padding-left: 20px;
    height: 36px;
    border-radius: 18px 0 0 18px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .l-nav_action {
    font-size: 13px;
    height: 32px;
    order: 1;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_action {
    height: 12.3076923077vw;
    border-radius: 6.1538461538vw;
    justify-content: center;
    flex: 0 0 auto;
  }
}

@media print, screen and (min-width: 781px) {
  .l-nav_action_item {
    font-weight: 700;
    font-size: 18px;
    margin-right: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .l-nav_action_item {
    font-size: 16px;
    margin-right: 20px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .l-nav_action_item {
    font-size: 14px;
    margin-right: 15px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .l-nav_action_item {
    font-size: 13px;
    margin-right: 12px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_action_item {
    font-size: 4.6153846154vw;
  }
}
@media print, screen and (max-width: 780px) {
  .l-nav_action_item + .l-nav_action_item {
    margin-left: 8.9743589744vw;
  }
}

.l-nav_action_anchor {
  color: #fff;
}
@media print, screen and (min-width: 781px) {
  .l-nav_action_anchor {
    transition: opacity 0.2s ease;
  }
  .l-nav_action_anchor:hover {
    opacity: 0.6;
  }
}

@media print, screen and (min-width: 781px) {
  .l-hamburger {
    display: none;
  }
}
@media print, screen and (max-width: 780px) {
  .l-hamburger {
    position: absolute;
    right: 3.8461538462vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    margin: 0;
    color: #fff;
    appearance: none;
    cursor: pointer;
    background-color: #4FA3C2;
    height: 11.7948717949vw;
    width: 11.7948717949vw;
    border-radius: 1.2820512821vw;
    font-size: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .l-hamburgerClose {
    display: none;
  }
}
@media print, screen and (max-width: 780px) {
  .l-hamburgerClose {
    display: flex;
    align-items: center;
    height: 18.9743589744vw;
    padding-right: 3.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .l-hamburgerClose_item {
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    margin: 0;
    color: #4FA3C2;
    appearance: none;
    cursor: pointer;
    height: 11.7948717949vw;
    width: 11.7948717949vw;
    position: relative;
    overflow-x: hidden;
    border-radius: 1.2820512821vw;
    font-size: 7.6923076923vw;
    margin-left: auto;
  }
}

.l-footer {
  position: relative;
  z-index: 8;
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .l-footer {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer {
    padding-top: 15.3846153846vw;
    padding-bottom: 15.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .l-footer_main {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer_main {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer_main_info {
    text-align: center;
  }
}
@media print, screen and (min-width: 781px) {
  .l-footer_main_info_logo {
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_info_logo {
    margin-bottom: 5.1282051282vw;
    width: 77.9487179487vw;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-footer_main_info_address {
  line-height: 1.75;
}
@media print, screen and (min-width: 781px) {
  .l-footer_main_info_address {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_info_address {
    text-align: center;
  }
}

.l-footer_main_info_action {
  background-color: #4FA3C2;
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 781px) {
  .l-footer_main_info_action {
    height: 48px;
    border-radius: 24px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    max-width: 330px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer_main_info_action {
    margin-left: auto;
    margin-right: auto;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_info_action {
    height: 12.3076923077vw;
    border-radius: 6.1538461538vw;
    padding-left: 7.6923076923vw;
    padding-right: 7.6923076923vw;
    margin-top: 10.2564102564vw;
    justify-content: center;
  }
}

@media print, screen and (max-width: 780px) {
  .l-footer_main_info_action_item {
    font-size: 4.6153846154vw;
  }
}
@media print, screen and (min-width: 781px) {
  .l-footer_main_info_action_item + .l-footer_main_info_action_item {
    margin-left: 35px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_info_action_item + .l-footer_main_info_action_item {
    margin-left: 10.2564102564vw;
  }
}

.l-footer_main_info_action_anchor {
  color: #fff;
  font-weight: 700;
  transition: opacity 0.2s ease;
}
.l-footer_main_info_action_anchor:hover {
  opacity: 0.6;
}

@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer_main_nav {
    margin-top: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav {
    margin-top: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .l-footer_main_nav_list {
    display: flex;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_list {
    border-top: 1px solid #e0e0e0;
  }
}

.l-footer_main_nav_item {
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .l-footer_main_nav_item {
    font-size: 16px;
  }
}
@media print, screen and (min-width: 781px) {
  .l-footer_main_nav_item + .l-footer_main_nav_item {
    margin-left: 50px;
  }
}

.l-footer_main_nav_anchor {
  color: #333;
  font-weight: 700;
  transition: color 0.2s ease;
  position: relative;
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_anchor {
    display: block;
    padding: 3.8461538462vw 14.1025641026vw 3.8461538462vw 5.1282051282vw;
    border-bottom: 1px solid #e0e0e0;
  }
}
@media (any-hover: hover) {
  .l-footer_main_nav_anchor:hover {
    color: #4FA3C2;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_anchor::after {
    font-weight: 400;
    pointer-events: none;
    position: absolute;
    font-family: "Material Icons";
    content: "chevron_right";
    color: #4FA3C2;
    font-size: 5.641025641vw;
    right: 5.1282051282vw;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_anchor-accordion::after {
    content: "add_circle";
    transition: transform 0.2s ease;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_anchor-accordion.is-open::after {
    transform: translateY(-50%) rotate(180deg);
    content: "remove_circle_outline";
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_anchor-accordion {
    pointer-events: none;
  }
}

@media print, screen and (min-width: 781px) {
  .l-footer_main_nav_childList {
    margin-top: 16px;
    display: block !important;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_childList {
    display: none;
  }
}

@media print, screen and (min-width: 781px) {
  .l-footer_main_nav_childItem {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.l-footer_main_nav_childAnchor {
  color: #333;
  transition: color 0.2s ease;
  font-weight: 700;
  position: relative;
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_childAnchor {
    display: block;
    padding: 3.8461538462vw 14.1025641026vw 3.8461538462vw 7.6923076923vw;
    border-bottom: 1px solid #e0e0e0;
  }
}
@media (any-hover: hover) {
  .l-footer_main_nav_childAnchor:hover {
    color: #B2A86E;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_main_nav_childAnchor::after {
    font-weight: 400;
    pointer-events: none;
    position: absolute;
    font-family: "Material Icons";
    content: "chevron_right";
    color: #4FA3C2;
    font-size: 5.641025641vw;
    right: 5.1282051282vw;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media print, screen and (min-width: 781px) {
  .l-footer_sub {
    display: flex;
    margin-top: 40px;
    align-items: center;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer_sub {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_sub {
    margin-top: 7.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .l-footer_sub_list {
    text-align: center;
  }
}

.l-footer_sub_list_item {
  display: inline-block;
}
@media print, screen and (min-width: 781px) {
  .l-footer_sub_list_item {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_sub_list_item {
    font-size: 3.8461538462vw;
  }
}
.l-footer_sub_list_item + .l-footer_sub_list_item::before {
  content: "|";
  margin-left: 1rem;
  margin-right: 1rem;
}
.l-footer_sub_list_anchor {
  color: #333;
}
.l-footer_sub_copyright {
  font-weight: 400;
}
@media print, screen and (min-width: 781px) {
  .l-footer_sub_copyright {
    margin-left: 50px;
    font-size: 13px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .l-footer_sub_copyright {
    display: block;
    margin-top: 50px;
    margin-left: 0;
  }
}
@media print, screen and (max-width: 780px) {
  .l-footer_sub_copyright {
    display: block;
    line-height: 1.777;
    font-size: 3.3333333333vw;
    margin-top: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .m-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-container {
    padding-left: 5.1282051282vw;
    padding-right: 5.1282051282vw;
  }
}

.m-p {
  line-height: 1.777;
}
.m-lineHeading {
  line-height: 1.4;
  position: relative;
}
.m-lineHeading-top::before {
  display: block;
  content: "";
  background: rgb(79, 163, 194);
  background: linear-gradient(90deg, rgb(79, 163, 194) 0%, rgb(79, 163, 194) 46.5%, rgba(255, 255, 255, 0) 46.5%, rgba(255, 255, 255, 0) 53.5%, rgb(178, 168, 110) 53.5%);
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-top::before {
    width: 42px;
    height: 4px;
    margin-bottom: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-top::before {
    width: 7.6923076923vw;
    height: 1.0256410256vw;
    margin-bottom: 5.1282051282vw;
  }
}
.m-lineHeading-top.m-lineHeading-switch::before {
  display: none;
}
.m-lineHeading-top.m-lineHeading-switch::after {
  display: block;
  content: "";
  background: rgb(79, 163, 194);
  background: linear-gradient(90deg, rgb(79, 163, 194) 0%, rgb(79, 163, 194) 46.5%, rgba(255, 255, 255, 0) 46.5%, rgba(255, 255, 255, 0) 53.5%, rgb(178, 168, 110) 53.5%);
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-top.m-lineHeading-switch::after {
    width: 42px;
    height: 4px;
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-top.m-lineHeading-switch::after {
    width: 7.6923076923vw;
    height: 1.0256410256vw;
    margin-top: 5.1282051282vw;
  }
}

.m-lineHeading-bottom::after {
  display: block;
  content: "";
  background: rgb(79, 163, 194);
  background: linear-gradient(90deg, rgb(79, 163, 194) 0%, rgb(79, 163, 194) 46.5%, rgba(255, 255, 255, 0) 46.5%, rgba(255, 255, 255, 0) 53.5%, rgb(178, 168, 110) 53.5%);
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-bottom::after {
    width: 42px;
    height: 4px;
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-bottom::after {
    width: 7.6923076923vw;
    height: 1.0256410256vw;
    margin-top: 5.1282051282vw;
  }
}
.m-lineHeading-bottom.m-lineHeading-switch::after {
  display: none;
}
.m-lineHeading-bottom.m-lineHeading-switch::before {
  display: block;
  content: "";
  background: rgb(79, 163, 194);
  background: linear-gradient(90deg, rgb(79, 163, 194) 0%, rgb(79, 163, 194) 46.5%, rgba(255, 255, 255, 0) 46.5%, rgba(255, 255, 255, 0) 53.5%, rgb(178, 168, 110) 53.5%);
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-bottom.m-lineHeading-switch::before {
    width: 42px;
    height: 4px;
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-bottom.m-lineHeading-switch::before {
    width: 7.6923076923vw;
    height: 1.0256410256vw;
    margin-top: 5.1282051282vw;
  }
}

.m-lineHeading-left {
  position: relative;
}
.m-lineHeading-left::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: calc(50% - 1px);
  background-color: #4FA3C2;
}
.m-lineHeading-left::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: calc(50% - 1px);
  background-color: #B2A86E;
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-left.m-lineHeading-large::before, .m-lineHeading-left.m-lineHeading-large::after {
    width: 8px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-left.m-lineHeading-large::before, .m-lineHeading-left.m-lineHeading-large::after {
    width: 1.5384615385vw;
  }
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-left.m-lineHeading-large {
    padding-left: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-left.m-lineHeading-large {
    padding-left: 10.2564102564vw;
  }
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-left.m-lineHeading-small::before, .m-lineHeading-left.m-lineHeading-small::after {
    width: 5px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-left.m-lineHeading-small::before, .m-lineHeading-left.m-lineHeading-small::after {
    width: 1.2820512821vw;
  }
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-left.m-lineHeading-small {
    padding-left: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-left.m-lineHeading-small {
    padding-left: 5.1282051282vw;
  }
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-left.m-lineHeading-medium::before, .m-lineHeading-left.m-lineHeading-medium::after {
    width: 5px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-left.m-lineHeading-medium::before, .m-lineHeading-left.m-lineHeading-medium::after {
    width: 1.2820512821vw;
  }
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-left.m-lineHeading-medium {
    padding-left: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-left.m-lineHeading-medium {
    padding-left: 5.1282051282vw;
  }
}

.m-lineHeading-large {
  font-weight: 900;
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-large {
    font-size: 44px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-large {
    font-size: 7.6923076923vw;
  }
}

.m-lineHeading-small {
  font-weight: 700;
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-small {
    font-size: 22px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-small {
    font-size: 4.6153846154vw;
  }
}

.m-lineHeading-medium {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .m-lineHeading-medium {
    font-size: 24px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-lineHeading-medium {
    font-size: 5.3846153846vw;
  }
}

.m-lineHeading-center {
  text-align: center;
}
.m-lineHeading-center::after {
  margin-left: auto;
  margin-right: auto;
}
.m-btns {
  display: flex;
}
@media print, screen and (max-width: 780px) {
  .m-btns {
    flex-direction: column;
    align-items: flex-start;
  }
}

.m-btns-center {
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .m-btns-center {
    justify-content: center;
  }
}
@media print, screen and (max-width: 780px) {
  .m-btns-center {
    align-items: center;
  }
}

@media print, screen and (min-width: 781px) {
  .m-btns-center-pc {
    justify-content: center;
    text-align: center;
  }
}

@media print, screen and (max-width: 780px) {
  .m-btns-center-sp {
    align-items: center;
    text-align: center;
  }
}

.m-btn {
  background-color: #4FA3C2;
  display: flex;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media print, screen and (min-width: 781px) {
  .m-btn {
    border-radius: 35px;
    font-size: 20px;
    padding: 12px 35px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-btn {
    border-radius: 10.2564102564vw;
    font-size: 4.358974359vw;
    padding: 3.0769230769vw 8.9743589744vw;
  }
}
.m-btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #2D8DB1;
  transition: all 0.4s;
  border-radius: 10rem;
  z-index: -1;
}
.m-btn:hover:before {
  width: 100%;
}
@media print, screen and (min-width: 781px) {
  .m-btn + .m-btn {
    margin-left: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-btn + .m-btn {
    margin-top: 5.1282051282vw;
  }
}
.m-btn .m-icon {
  align-self: center;
}
.m-btn-outline {
  border: 2px solid #4FA3C2;
  background-color: #fff;
  color: #4FA3C2;
}
.m-btn-outline:hover::before {
  display: none;
}

.m-icon::after {
  font-family: "Material Icons";
  content: attr(data-icon);
  font-weight: 400;
}
.m-indent {
  display: flex;
}

.m-indent_type {
  flex: 0 0 auto;
}

.m-btn .m-icon[data-icon=arrow_forward_ios] {
  margin-top: 0.2rem;
}

@media print, screen and (min-width: 781px) {
  .m-lead {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media print, screen and (min-width: 781px) {
  .m-section {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .m-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-section {
    padding-top: 15.3846153846vw;
    padding-bottom: 15.3846153846vw;
  }
}

.m-object-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.m-link {
  text-decoration: underline;
}
.m-link:hover {
  text-decoration: none;
}

.m-link {
  text-decoration: underline;
}
.m-link:hover {
  text-decoration: none;
}

.m-contact {
  position: relative;
  z-index: 8;
}
.m-contact_tetsuduki {
  background-color: #fff;
  border: 2px solid #4FA3C2;
  display: flex;
  color: #4FA3C2;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media print, screen and (min-width: 781px) {
  .m-contact_tetsuduki {
    border-radius: 41px;
    font-size: 31px;
    padding: 22px 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_tetsuduki {
    border-radius: 8.9743589744vw;
    font-size: 5.641025641vw;
    padding: 5.1282051282vw 12.8205128205vw;
  }
}
.m-contact_tetsuduki:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #4FA3C2;
  transition: all 0.5s;
  z-index: -1;
}
.m-contact_tetsuduki:hover {
  color: #fff;
}
.m-contact_tetsuduki:hover:before {
  width: 100%;
}

@media print, screen and (min-width: 781px) {
  .m-contact_card {
    display: flex;
    margin-top: 50px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .m-contact_card {
    display: block;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card {
    margin-top: 5.1282051282vw;
  }
}

.m-contact_card_item {
  font-weight: 500;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 1.6;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_item {
    border-radius: 15px;
    width: calc(100% - 20px);
    padding: 35px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .m-contact_card_item {
    width: 100%;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_item {
    padding: 7.6923076923vw;
    border-radius: 3.8461538462vw;
  }
}
@media print, screen and (min-width: 1001px) {
  .m-contact_card_item + .m-contact_card_item {
    margin-left: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .m-contact_card_item + .m-contact_card_item {
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_item + .m-contact_card_item {
    margin-top: 5.1282051282vw;
  }
}

.m-contact_card_icon {
  color: #4FA3C2;
  line-height: 1;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_icon {
    font-size: 60px;
    margin-bottom: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_icon {
    font-size: 15.3846153846vw;
    margin-bottom: 2.5641025641vw;
  }
}

.m-contact_card_title {
  color: #B2A86E;
  font-weight: 700;
  line-height: 1;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_title {
    font-size: 22px;
    margin-bottom: 36px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_title {
    font-size: 5.1282051282vw;
    margin-bottom: 5.1282051282vw;
  }
}

.m-contact_card_tel {
  font-weight: 700;
  color: #333333;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_tel {
    font-size: 28px;
    pointer-events: none;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_tel {
    font-size: 6.6666666667vw;
  }
}

.m-contact_card_fax {
  font-weight: 600;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_fax {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_fax {
    font-size: 5.1282051282vw;
    margin-bottom: 2.5641025641vw;
  }
}

.m-contact_card_p {
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_p {
    font-size: 3.8461538462vw;
  }
}

.m-contact_card_formBtn {
  line-height: 1.3;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_formBtn {
    font-size: 18px;
    margin-bottom: 32px;
    margin-top: -10px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_formBtn {
    margin-bottom: 5.1282051282vw;
  }
}

.m-contact_card_to {
  font-weight: 600;
}
@media print, screen and (min-width: 781px) {
  .m-contact_card_to {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-contact_card_to {
    font-size: 5.1282051282vw;
    margin-bottom: 5.1282051282vw;
  }
}

@media print, screen and (max-width: 780px) {
  .m-contact_card_address {
    font-size: 4.6153846154vw;
  }
}

.m-hover-opacity {
  transition: opacity 0.2s ease;
}
.m-hover-opacity:hover {
  opacity: 0.7;
}

.m-pagetop {
  background-color: #4FA3C2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  color: #fff;
  transition: all 0.1s ease;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
}
@media print, screen and (min-width: 781px) {
  .m-pagetop {
    position: fixed;
    border-radius: 7px;
    width: 50px;
    height: 50px;
    right: 30px;
    bottom: 30px;
    font-size: 36px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-pagetop {
    position: absolute;
    border-radius: 1.2820512821vw;
    width: 11.7948717949vw;
    height: 11.7948717949vw;
    right: 5.1282051282vw;
    bottom: 5.1282051282vw;
    font-size: 7.6923076923vw;
  }
}
.m-pagetop:active {
  transform: scale(0.8);
}
@media (any-hover: hover) {
  .m-pagetop:hover {
    filter: brightness(1.1);
  }
}
.m-pagetop.is-show {
  opacity: 1;
  pointer-events: auto;
}

@media print, screen and (min-width: 781px) {
  .m-headerShift {
    padding-top: 110px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .m-headerShift {
    padding-top: 80px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .m-headerShift {
    padding-top: 70px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .m-headerShift {
    padding-top: 64px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-headerShift {
    padding-top: 18.9743589744vw;
  }
}

.m-pageHeader {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .m-pageHeader {
    background-image: url("/_/media/module/pageheader_common.jpg");
  }
}
@media print, screen and (max-width: 780px) {
  .m-pageHeader {
    background-image: url("/_/media/module/pageheader_common-sp.jpg");
  }
}

.m-pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print, screen and (min-width: 781px) {
  .m-pageHeader_content {
    height: 300px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .m-pageHeader_content {
    height: 240px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-pageHeader_content {
    height: 53.8461538462vw;
  }
}

.m-pageHeader_title {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .m-pageHeader_title {
    font-size: 54px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-pageHeader_title {
    font-size: 8.2051282051vw;
  }
}

.m-breadcrumb {
  background-color: rgba(255, 255, 255, 0.4);
}
@media print, screen and (min-width: 781px) {
  .m-breadcrumb {
    padding: 20px 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-breadcrumb {
    padding: 3.8461538462vw 5.1282051282vw;
  }
}

.m-breadcrumb_item {
  display: inline;
  line-height: 1.4;
}
@media print, screen and (min-width: 781px) {
  .m-breadcrumb_item {
    font-size: 13px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-breadcrumb_item {
    font-size: 3.3333333333vw;
  }
}
.m-breadcrumb_item + .m-breadcrumb_item::before {
  content: "／";
  margin-left: 0.6rem;
  margin-right: 0.8rem;
}
.m-breadcrumb_item .m-icon {
  color: #4FA3C2;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.5rem;
}
@media print, screen and (min-width: 781px) {
  .m-breadcrumb_item .m-icon {
    font-size: 16px;
    margin-top: -2px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-breadcrumb_item .m-icon {
    font-size: 4.1025641026vw;
    margin-top: -0.5128205128vw;
  }
}

.m-breadcrumb_anchor {
  color: #333;
}
.m-breadcrumb_anchor:hover {
  text-decoration: underline;
}

@media print, screen and (min-width: 781px) {
  .m-column-pc {
    display: flex;
  }
}
@media print, screen and (min-width: 781px) {
  .m-column_item {
    order: 2;
  }
}
@media print, screen and (max-width: 780px) {
  .m-column_item + .m-column_item {
    margin-top: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .m-column_item-fix {
    flex: 0 0 auto;
  }
}
.m-column-reverse .m-column_item:nth-child(1) {
  order: 2;
}
.m-column-reverse .m-column_item:nth-child(2) {
  order: 1;
}

.m-hr {
  border: 0;
  margin: 0;
  border-top: 1px solid #DEDEDE;
}

@media print, screen and (min-width: 1001px) {
  .m-step {
    display: flex;
    padding-top: 80px;
    padding-bottom: 150px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .m-step {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step {
    padding-bottom: 15.3846153846vw;
  }
}

@media print, screen and (min-width: 1001px) {
  .m-step_processes {
    width: 284px;
    flex: 0 0 auto;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_processes {
    background-color: #F7F7ED;
    padding-top: 7.6923076923vw;
    padding-bottom: 7.6923076923vw;
    margin-left: -5.1282051282vw;
    margin-right: -5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .m-step_process {
    position: sticky;
    position: -webkit-sticky;
    top: 140px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1300px) {
  .m-step_process {
    top: 110px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1050px) {
  .m-step_process {
    top: 100px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .m-step_process {
    top: 94px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process {
    padding-left: 5.1282051282vw;
    padding-right: 5.1282051282vw;
  }
}

.m-step_process_heading {
  background-color: #4FA3C2;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
@media print, screen and (min-width: 781px) {
  .m-step_process_heading {
    border-radius: 10px;
    height: 56px;
    font-size: 20px;
    margin-bottom: 14px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_heading {
    border-radius: 2.5641025641vw;
    height: 14.358974359vw;
    font-size: 5.1282051282vw;
    margin-bottom: 3.5897435897vw;
  }
}

@media print, screen and (min-width: 1001px) {
  .m-step_content {
    margin-left: 60px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .m-step_content {
    margin-top: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_content {
    padding-top: 7.6923076923vw;
  }
}

.m-step_process_item {
  background-color: #fff;
  font-weight: 700;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .m-step_process_item {
    border-radius: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_item {
    border-radius: 2.5641025641vw;
  }
}
@media print, screen and (min-width: 781px) {
  .m-step_process_item + .m-step_process_item {
    margin-top: 14px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_item + .m-step_process_item {
    margin-top: 3.3333333333vw;
  }
}
.m-step_process_item + .m-step_process_item::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #DEDBC2 transparent transparent transparent;
}
@media print, screen and (min-width: 781px) {
  .m-step_process_item + .m-step_process_item::before {
    top: -13px;
    border-width: 13px 8px 0 8px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_item + .m-step_process_item::before {
    top: -3.3333333333vw;
    border-width: 3.3333333333vw 2.0512820513vw 0 2.0512820513vw;
  }
}

.m-step_process_anchor {
  display: flex;
  color: #333;
  position: relative;
  transition: opacity 0.2s ease;
}
@media print, screen and (min-width: 781px) {
  .m-step_process_anchor {
    padding: 15px 45px 15px 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_anchor {
    padding: 3.8461538462vw 11.5384615385vw 3.8461538462vw 3.8461538462vw;
  }
}
.m-step_process_anchor::after {
  font-family: "Material Icons";
  color: #4FA3C2;
  position: absolute;
  transition: right 0.2s ease;
}
@media print, screen and (min-width: 781px) {
  .m-step_process_anchor::after {
    font-size: 24px;
    right: 15px;
    content: "chevron_right";
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_anchor::after {
    font-size: 6.1538461538vw;
    right: 3.8461538462vw;
    content: "expand_more";
  }
}
@media (any-hover: hover) {
  .m-step_process_anchor:hover {
    opacity: 0.6;
  }
}
@media print and (any-hover: hover), screen and (any-hover: hover) and (min-width: 781px) {
  .m-step_process_anchor:hover::after {
    right: 10px;
  }
}
.m-step_process_num {
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4FA3C2;
  flex: 0 0 auto;
}
@media print, screen and (min-width: 781px) {
  .m-step_process_num {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    margin-right: 10px;
    padding-bottom: 2px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_process_num {
    width: 6.6666666667vw;
    height: 6.6666666667vw;
    border-radius: 1.2820512821vw;
    margin-right: 2.5641025641vw;
    padding-bottom: 0.5128205128vw;
  }
}

.m-step_process_text {
  line-height: 1.4;
}
@media print, screen and (max-width: 780px) {
  .m-step_process_text {
    font-size: 4.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .m-step_content {
    width: 100%;
  }
}
.m-step_heading {
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
@media print, screen and (min-width: 781px) {
  .m-step_heading {
    padding: 30px;
    border-radius: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_heading {
    padding: 5.1282051282vw;
    border-radius: 2.5641025641vw;
  }
}

.m-step_heading_num {
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4FA3C2;
  flex: 0 0 auto;
  align-self: center;
}
@media print, screen and (min-width: 781px) {
  .m-step_heading_num {
    width: 58px;
    height: 58px;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_heading_num {
    width: 8.2051282051vw;
    height: 8.2051282051vw;
    border-radius: 1.2820512821vw;
    margin-right: 3.8461538462vw;
    font-size: 4.8717948718vw;
  }
}

.m-step_heading_text {
  line-height: 1.4;
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .m-step_heading_text {
    font-size: 30px;
    padding-top: 8px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-step_heading_text {
    font-size: 5.641025641vw;
  }
}

.m-table {
  width: 100%;
}
@media print, screen and (min-width: 781px) {
  .m-table {
    border-collapse: separate;
    border-spacing: 10px;
  }
}
.m-table_head {
  text-align: left;
  line-height: 1.6;
  white-space: nowrap;
  border-top: 2px solid #4FA3C2;
}
@media print, screen and (min-width: 781px) {
  .m-table_head {
    padding: 20px 20px 15px 10px;
    min-width: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-table_head {
    padding: 3.8461538462vw 3.8461538462vw 3.8461538462vw 0;
  }
}

.m-table_data {
  line-height: 1.6;
  border-top: 1px solid #DEDEDE;
}
@media print, screen and (min-width: 781px) {
  .m-table_data {
    padding: 20px 10px 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-table_data {
    padding: 3.8461538462vw 0 3.8461538462vw 3.8461538462vw;
  }
}

.m-cardHeading {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  font-weight: 700;
  line-height: 1.45;
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .m-cardHeading {
    font-size: 26px;
    padding: 30px 30px 30px 40px;
    border-radius: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-cardHeading {
    padding: 5.1282051282vw 5.1282051282vw 5.1282051282vw 7.6923076923vw;
    border-radius: 2.5641025641vw;
    font-size: 5.641025641vw;
  }
}
.m-cardHeading::before {
  content: "";
  background-color: #4FA3C2;
  position: absolute;
  left: 0;
}
@media print, screen and (min-width: 781px) {
  .m-cardHeading::before {
    width: 20px;
    height: 5px;
    top: 46px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-cardHeading::before {
    width: 4.6153846154vw;
    height: 1.2820512821vw;
    top: 8.7179487179vw;
  }
}

.m-simpleHeader {
  background-color: #FBFBF6;
}
.m-simpleHeader_content {
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 781px) {
  .m-simpleHeader_content {
    height: 227px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-simpleHeader_content {
    height: 34.358974359vw;
  }
}

.m-simpleHeader_title {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .m-simpleHeader_title {
    font-size: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-simpleHeader_title {
    font-size: 6.1538461538vw;
  }
}

.m-inlineContact {
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .m-inlineContact {
    border-radius: 10px;
    border: 4px solid #4FA3C2;
    display: flex;
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-inlineContact {
    margin-top: 5.1282051282vw;
    border-radius: 2.5641025641vw;
    border: 1.0256410256vw solid #4FA3C2;
  }
}

.m-inlineContact_title {
  color: #fff;
  background-color: #4FA3C2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media print, screen and (min-width: 781px) {
  .m-inlineContact_title {
    width: 30%;
  }
}
@media print, screen and (max-width: 780px) {
  .m-inlineContact_title {
    height: 41.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .m-inlineContact_body {
    width: 70%;
    padding: 40px 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .m-inlineContact_body {
    text-align: center;
    padding: 25px;
  }
}

.p-kentai_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-kentai_pageHeader {
    background-image: url(/_/media/page/kentai/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_pageHeader {
    background-image: url(/_/media/page/kentai/pageheader-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-about_nav {
    padding-top: 130px;
    padding-bottom: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-about_nav {
    padding-top: 5.1282051282vw;
    padding-bottom: 10.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-about_nav_list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 850px) {
  .p-about_nav_list {
    gap: 20px;
  }
}
.p-about_nav_list_item {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .p-about_nav_list_item {
    width: 30%;
    border-radius: 15px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .p-about_nav_list_item {
    width: 48%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-about_nav_list_item {
    margin-bottom: 5.1282051282vw;
    border-radius: 3.8461538462vw;
  }
}

.p-about_nav_list_anchor {
  display: block;
}
@media print, screen and (min-width: 781px) {
  .p-about_nav_list_image {
    border-radius: 15px 15px 0 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-about_nav_list_image {
    border-radius: 3.8461538462vw 3.8461538462vw 0 0;
  }
}

.p-about_nav_list_text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 700;
}
@media print, screen and (min-width: 781px) {
  .p-about_nav_list_text {
    padding: 30px 0;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1000px) {
  .p-about_nav_list_text {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-about_nav_list_text {
    padding: 7.6923076923vw 0;
    font-size: 4.6153846154vw;
  }
}
.p-about_nav_list_text .m-icon {
  color: #4FA3C2;
  margin-left: 0.4rem;
  margin-top: 0.2rem;
}

.p-contact_flow {
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow {
    margin: 60px 0 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow {
    margin: 10.2564102564vw 0;
  }
}

.p-contact_flow_item {
  width: 33.33%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #989898;
  letter-spacing: 0.26rem;
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow_item {
    height: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item {
    height: 12.8205128205vw;
  }
}
.p-contact_flow_item:nth-child(1) {
  border-top: 1px solid #989898;
  border-left: 1px solid #989898;
  border-bottom: 1px solid #989898;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow_item:nth-child(1) {
    border-radius: 10px 0 0 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item:nth-child(1) {
    border-radius: 2.5641025641vw 0 0 2.5641025641vw;
  }
}
.p-contact_flow_item:nth-child(2) {
  border-top: 1px solid #989898;
  border-bottom: 1px solid #989898;
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item:nth-child(2) {
    padding-left: 2.5641025641vw;
  }
}
.p-contact_flow_item:nth-child(3) {
  border-top: 1px solid #989898;
  border-right: 1px solid #989898;
  border-bottom: 1px solid #989898;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow_item:nth-child(3) {
    border-radius: 0 10px 10px 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item:nth-child(3) {
    border-radius: 0 2.5641025641vw 2.5641025641vw 0;
    padding-left: 2.5641025641vw;
  }
}
.p-contact_flow_item:nth-child(3)::before, .p-contact_flow_item:nth-child(3)::after {
  display: none;
}
.p-contact_flow_item:nth-child(3) span::before, .p-contact_flow_item:nth-child(3) span::after {
  display: none;
}
.p-contact_flow_item::before {
  position: absolute;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #989898;
  z-index: 2;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow_item::before {
    border-width: 30px 0 30px 13px;
    right: -13px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item::before {
    border-width: 6.4102564103vw 0 6.4102564103vw 3.3333333333vw;
    right: -3.3333333333vw;
  }
}
.p-contact_flow_item::after {
  position: absolute;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  z-index: 2;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow_item::after {
    border-width: 30px 0 30px 13px;
    right: -12px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item::after {
    border-width: 6.4102564103vw 0 6.4102564103vw 3.3333333333vw;
    right: -3.0769230769vw;
  }
}
.p-contact_flow_item.is-current {
  color: #fff;
  background-color: #4FA3C2;
}
.p-contact_flow_item.is-current span::before {
  position: absolute;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #4FA3C2;
  z-index: 3;
}
@media print, screen and (min-width: 781px) {
  .p-contact_flow_item.is-current span::before {
    border-width: 30px 0 30px 13px;
    right: -12px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_flow_item.is-current span::before {
    border-width: 6.4102564103vw 0 6.4102564103vw 3.3333333333vw;
    right: -3.0769230769vw;
  }
}

#js-formSubmit {
  pointer-events: none;
  filter: grayscale(100%);
}
#js-formSubmit.is-active {
  pointer-events: auto;
  filter: grayscale(0);
}

#mfp_confirm_table {
  width: 100%;
}
#mfp_confirm_table tr {
  border-top: 1px solid #DEDEDE;
  line-height: 1.5;
}
@media print, screen and (max-width: 780px) {
  #mfp_confirm_table tr {
    display: block;
  }
}
#mfp_confirm_table th {
  text-align: left;
  vertical-align: top;
}
@media print, screen and (min-width: 781px) {
  #mfp_confirm_table th {
    padding: 35px;
    width: 30%;
  }
}
@media print, screen and (max-width: 780px) {
  #mfp_confirm_table th {
    display: block;
    padding: 5.1282051282vw 0 3.8461538462vw 0;
    font-size: 4.6153846154vw;
  }
}
@media print, screen and (min-width: 781px) {
  #mfp_confirm_table td {
    padding: 35px 35px 35px 0;
  }
}
@media print, screen and (max-width: 780px) {
  #mfp_confirm_table td {
    display: block;
    padding: 0 0 5.1282051282vw 0;
  }
}

.mfp_buttons {
  display: flex;
}
@media print, screen and (min-width: 781px) {
  .mfp_buttons {
    justify-content: center;
  }
}
@media print, screen and (max-width: 780px) {
  .mfp_buttons {
    flex-direction: column;
    align-items: center;
  }
}

#mfp_button_send {
  order: 2;
  background-color: #4FA3C2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s;
  border: 2px solid #4FA3C2;
  cursor: pointer;
}
@media print, screen and (min-width: 781px) {
  #mfp_button_send {
    width: 240px;
    border-radius: 35px;
    font-size: 20px;
    padding: 12px 35px;
    margin-left: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  #mfp_button_send {
    width: 61.5384615385vw;
    margin-top: 7.6923076923vw;
    border-radius: 10.2564102564vw;
    font-size: 4.358974359vw;
    padding: 3.0769230769vw 8.9743589744vw;
  }
}
#mfp_button_send:hover {
  opacity: 0.7;
}
#mfp_button_send::after {
  font-family: "Material Icons";
  content: "chevron_right";
  margin-left: 0.6rem;
}

#mfp_button_cancel {
  background-color: #4FA3C2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  order: 1;
  cursor: pointer;
  border: 2px solid #4FA3C2;
  background-color: #fff;
  color: #4FA3C2;
}
@media print, screen and (min-width: 781px) {
  #mfp_button_cancel {
    width: 240px;
    border-radius: 35px;
    font-size: 20px;
    padding: 12px 35px;
  }
}
@media print, screen and (max-width: 780px) {
  #mfp_button_cancel {
    width: 61.5384615385vw;
    border-radius: 10.2564102564vw;
    font-size: 4.358974359vw;
    padding: 3.0769230769vw 8.9743589744vw;
  }
}
#mfp_button_cancel:hover {
  opacity: 0.7;
}
#mfp_button_cancel::before {
  font-family: "Material Icons";
  content: "chevron_left";
  margin-right: 0.6rem;
}

.mfp_err {
  color: #C93B3B;
  display: block;
}
@media print, screen and (min-width: 781px) {
  .mfp_err {
    font-size: 16px;
    margin-top: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .mfp_err {
    font-size: 3.5897435897vw;
    margin-top: 1.2820512821vw;
  }
}

.p-contact_err {
  color: #C93B3B;
  border: 2px solid #C93B3B;
  line-height: 1.6;
}
@media print, screen and (min-width: 781px) {
  .p-contact_err {
    font-size: 18px;
    padding: 26px;
    border-radius: 10px;
    text-align: center;
  }
}
@media print, screen and (max-width: 780px) {
  .p-contact_err {
    border-radius: 2.5641025641vw;
    padding: 3.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .formgrid .mfp_err {
    grid-row: 2;
    grid-column: 1/span 2;
  }
}

[for=js-agreement] + .mfp_err {
  margin-top: 1rem;
}

.p-donation_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-donation_pageHeader {
    background-image: url(/_/media/page/donation/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-donation_pageHeader {
    background-image: url(/_/media/page/donation/pageheader-sp.jpg);
  }
}

.p-donation_pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media print, screen and (min-width: 781px) {
  .p-donation_pageHeader_content {
    height: 510px;
    padding-bottom: 100px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-donation_pageHeader_content {
    height: 410px;
    padding-bottom: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-donation_pageHeader_content {
    height: 87.1794871795vw;
    padding-bottom: 25.641025641vw;
  }
}

.p-donation_pageHeader_title {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .p-donation_pageHeader_title {
    font-size: 54px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-donation_pageHeader_title {
    font-size: 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-donation_pageHeader_title {
    font-size: 8.2051282051vw;
  }
}

.faq-box-question .faq-open-icon:before {
  content: "add";
  display: block;
  font-family: "Material Icons";
  line-height: 1;
  transition: transform 0.2s ease;
}

.faq-box-question.is-open {
  opacity: 1 !important;
}

.faq-box-question.is-open .faq-open-icon:before {
  content: "remove";
  transform: rotate(180deg);
}

.p-kentai_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-kentai_pageHeader {
    background-image: url(/_/media/page/kentai/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_pageHeader {
    background-image: url(/_/media/page/kentai/pageheader-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-kentai_about {
    padding-top: 70px;
    padding-bottom: 110px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_about {
    padding-top: 15.3846153846vw;
    padding-bottom: 15.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-kentai_about_column {
    display: flex;
  }
}
.p-kentai_title {
  font-weight: 700;
  line-height: 1.583;
}
@media print, screen and (min-width: 781px) {
  .p-kentai_title {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_title {
    font-size: 5.1282051282vw;
    margin-bottom: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-kentai_about_column_image {
    flex: 0 0 auto;
    margin-left: 40px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-kentai_about_column_image {
    flex: 0 0 auto;
    margin-left: 40px;
    width: 40%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_about_column_image {
    margin-top: 7.6923076923vw;
  }
}

.p-kentai_about_eye {
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
}
@media print, screen and (min-width: 781px) {
  .p-kentai_about_eye {
    padding: 40px 0;
    margin-top: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_about_eye {
    padding: 7.6923076923vw 0;
    margin-top: 10.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-kentai_about_eye_title {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_about_eye_title {
    font-size: 4.6153846154vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-kentai_flow_column {
    display: flex;
  }
}
@media print, screen and (min-width: 781px) {
  .p-kentai_flow_column_image {
    margin-top: 40px;
    margin-left: 40px;
    flex: 0 0 auto;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-kentai_flow_column_image {
    width: 40%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-kentai_flow_column_image {
    margin-top: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) and (max-width: 1040px) {
  .p-kentai_flow_btns {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1040px) {
  .p-kentai_flow_btns .m-btn + .m-btn {
    margin-left: 0;
    margin-top: 20px;
  }
}

.p-medicine_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_pageHeader {
    background-image: url(/_/media/page/medicine/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_pageHeader {
    background-image: url(/_/media/page/medicine/pageheader-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_university {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university {
    padding-top: 15.3846153846vw;
    padding-bottom: 15.3846153846vw;
  }
}

.p-medicine_university_message {
  text-align: center;
  font-weight: 800;
  line-height: 1.476;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_university_message {
    font-size: 42px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicine_university_message {
    font-size: 32px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_message {
    font-size: 6.6666666667vw;
  }
}

.p-medicine_university_commentTitle {
  font-weight: 800;
  border-top: 1px solid #E5E2CD;
  border-bottom: 1px solid #E5E2CD;
  padding: 1rem 0;
  line-height: 1.555;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_university_commentTitle {
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 36px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_commentTitle {
    margin-top: 7.6923076923vw;
    margin-bottom: 7.6923076923vw;
    font-size: 4.6153846154vw;
    text-align: center;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_university_president {
    margin-top: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president {
    margin-top: 10.2564102564vw;
  }
}

.p-medicine_university_president_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_item {
    border-radius: 15px;
    display: flex;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_item {
    border-radius: 3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_item + .p-medicine_university_president_item {
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_item + .p-medicine_university_president_item {
    margin-top: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_image {
    width: 38%;
    flex: 0 0 auto;
  }
}
.p-medicine_university_president_image_item {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_image_item {
    border-radius: 15px 0 0 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_image_item {
    border-radius: 3.8461538462vw 3.8461538462vw 0 0;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_content {
    padding: 60px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicine_university_president_content {
    padding: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_content {
    padding: 10.2564102564vw 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_header {
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 1001px) {
  .p-medicine_university_president_header {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_header {
    margin-bottom: 5.1282051282vw;
  }
}

.p-medicine_university_president_name {
  font-weight: 700;
  line-height: 1.5;
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_name {
    margin-bottom: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_university_president_name_full {
    font-size: 26px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_name_full {
    font-size: 6.1538461538vw;
  }
}

@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicine_university_president_logo {
    margin-top: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_university_president_logo {
    font-size: 6.1538461538vw;
    max-width: 39.4871794872vw;
  }
}

.p-medicine_special_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-medicine_special_item {
    border-radius: 15px;
    display: flex;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_item {
    border-radius: 3.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_special_image {
    width: 38%;
    flex: 0 0 auto;
  }
}
.p-medicine_special_image_item {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_special_image_item {
    border-radius: 15px 0 0 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_image_item {
    border-radius: 3.8461538462vw 3.8461538462vw 0 0;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_special_content {
    padding: 60px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicine_special_content {
    padding: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_content {
    padding: 10.2564102564vw 7.6923076923vw;
  }
}

.p-medicine_special_subTitle {
  font-weight: 700;
  color: #4FA3C2;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_special_subTitle {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_subTitle {
    font-size: 4.6153846154vw;
  }
}

.p-medicine_special_title {
  font-weight: 800;
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_special_title {
    font-size: 32px;
    margin-top: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_title {
    font-size: 6.1538461538vw;
    margin-top: 3.8461538462vw;
  }
}

.p-medicine_special_name {
  font-weight: 700;
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_special_name {
    margin-top: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_name {
    margin-top: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_special_name_full {
    font-size: 26px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_special_name_full {
    font-size: 6.1538461538vw;
  }
}

@media print, screen and (max-width: 780px) {
  .p-medicine_interview_lead {
    font-size: 4.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box {
    margin-top: 80px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box {
    margin-top: 7.6923076923vw;
  }
}

.p-medicine_interview_box_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_item {
    border-radius: 15px;
    display: flex;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_item {
    border-radius: 3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_item + .p-medicine_interview_box_item {
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_item + .p-medicine_interview_box_item {
    margin-top: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_image {
    width: 38%;
    flex: 0 0 auto;
  }
}
.p-medicine_interview_box_image_item {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_image_item {
    border-radius: 15px 0 0 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_image_item {
    border-radius: 3.8461538462vw 3.8461538462vw 0 0;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_content {
    padding: 88px 60px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicine_interview_box_content {
    padding: 58px 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_content {
    padding: 7.6923076923vw;
  }
}

.p-medicine_interview_box_comment {
  font-weight: 700;
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_comment {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_comment {
    font-size: 4.358974359vw;
    margin-bottom: 3.8461538462vw;
  }
}

.p-medicine_interview_box_name {
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_name {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_name {
    font-size: 3.8461538462vw;
  }
}

.p-medicine_interview_box_name_initial {
  color: #B2A86E;
  font-weight: 700;
}
@media print, screen and (min-width: 781px) {
  .p-medicine_interview_box_name_initial {
    font-size: 24px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicine_interview_box_name_initial {
    font-size: 5.641025641vw;
  }
}

.p-medicineInterview_pageHeader {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader {
    height: 790px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicineInterview_pageHeader {
    height: 500px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader {
    height: 114.358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader-special {
    background-image: url(/_/media/page/medicine/interview/pageheader_special.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader-special {
    background-image: url(/_/media/page/medicine/interview/pageheader_special-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader-05 {
    background-image: url(/_/media/page/medicine/interview/pageheader_05.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader-05 {
    background-image: url(/_/media/page/medicine/interview/pageheader_05-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader-04 {
    background-image: url(/_/media/page/medicine/interview/pageheader_04.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader-04 {
    background-image: url(/_/media/page/medicine/interview/pageheader_04-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader-03 {
    background-image: url(/_/media/page/medicine/interview/pageheader_03.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader-03 {
    background-image: url(/_/media/page/medicine/interview/pageheader_03-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader-02 {
    background-image: url(/_/media/page/medicine/interview/pageheader_02.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader-02 {
    background-image: url(/_/media/page/medicine/interview/pageheader_02-sp.jpg);
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pageHeader-01 {
    background-image: url(/_/media/page/medicine/interview/pageheader_01.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pageHeader-01 {
    background-image: url(/_/media/page/medicine/interview/pageheader_01-sp.jpg);
  }
}

.p-medicineInterview_container {
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_container {
    padding-top: 70px;
    padding-bottom: 150px;
    margin-top: -240px;
    border-radius: 15px;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicineInterview_container {
    margin-top: -180px;
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_container {
    padding-top: 12.8205128205vw;
    padding-bottom: 12.8205128205vw;
  }
}

.p-medicineInterview_heading {
  font-weight: 900;
  line-height: 1.312;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_heading {
    font-size: 64px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicineInterview_heading {
    font-size: 48px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_heading {
    font-size: 10.2564102564vw;
  }
}

.p-medicineInterview_name {
  font-weight: 700;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_name {
    margin-top: 30px;
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_name {
    margin-top: 5.1282051282vw;
    font-size: 4.6153846154vw;
  }
}

.p-medicineInterview_name_initial {
  color: #B2A86E;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_name_initial {
    font-size: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_name_initial {
    font-size: 7.1794871795vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_item {
    margin-top: 100px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_item {
    margin-top: 15.3846153846vw;
  }
}

.p-medicineInterview_question {
  font-weight: 700;
  color: #4FA3C2;
  line-height: 1.444;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_question {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicineInterview_question {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_question {
    margin-bottom: 5.1282051282vw;
  }
}

.p-medicineInterview_answerTitle {
  line-height: 1.333;
  font-weight: 800;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_answerTitle {
    font-size: 44px;
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-medicineInterview_answerTitle {
    font-size: 38px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_answerTitle {
    margin-bottom: 7.6923076923vw;
    font-size: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-medicineInterview_answerImage {
    margin-top: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_answerImage {
    margin-top: 10.2564102564vw;
    margin-left: -5.1282051282vw;
    margin-right: -5.1282051282vw;
  }
}

.p-medicineInterview_pagination {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination {
    margin-top: 100px;
    margin-left: -10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination {
    margin-top: 10.2564102564vw;
  }
}

.p-medicineInterview_pagination_prev {
  transition: opacity 0.2s ease;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_prev {
    width: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination_prev {
    width: 10.2564102564vw;
  }
}
.p-medicineInterview_pagination_prev:hover {
  opacity: 0.5;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_prev .p-medicineInterview_pagination_icon {
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination_prev .p-medicineInterview_pagination_icon {
    font-size: 7.6923076923vw;
    margin-top: 1.5384615385vw;
    margin-bottom: 5.1282051282vw;
  }
}

.p-medicineInterview_pagination_list {
  transition: opacity 0.2s ease;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_list {
    margin-left: 100px;
    margin-right: 120px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination_list {
    margin-left: 15.3846153846vw;
    margin-right: 17.9487179487vw;
  }
}
.p-medicineInterview_pagination_list:hover {
  opacity: 0.5;
}

.p-medicineInterview_pagination_next {
  transition: opacity 0.2s ease;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_next {
    width: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination_next {
    width: 10.2564102564vw;
  }
}
.p-medicineInterview_pagination_next:hover {
  opacity: 0.5;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_next .p-medicineInterview_pagination_icon {
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination_next .p-medicineInterview_pagination_icon {
    font-size: 7.6923076923vw;
    margin-top: 1.5384615385vw;
    margin-bottom: 5.1282051282vw;
  }
}

.p-medicineInterview_pagination_icon {
  color: #4FA3C2;
  display: block;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_icon {
    font-size: 40px;
    margin-bottom: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-medicineInterview_pagination_icon {
    font-size: 10.2564102564vw;
    margin-bottom: 3.8461538462vw;
  }
}

.p-medicineInterview_pagination_text {
  color: #4FA3C2;
  display: block;
  text-align: center;
  font-weight: 700;
}
@media print, screen and (min-width: 781px) {
  .p-medicineInterview_pagination_text {
    font-size: 16px;
  }
}
.p-membership_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-membership_pageHeader {
    background-image: url(/_/media/page/membership/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-membership_pageHeader {
    background-image: url(/_/media/page/membership/pageheader-sp.jpg);
  }
}

.p-membership_pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media print, screen and (min-width: 781px) {
  .p-membership_pageHeader_content {
    height: 510px;
    padding-bottom: 100px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-membership_pageHeader_content {
    height: 410px;
    padding-bottom: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-membership_pageHeader_content {
    height: 87.1794871795vw;
    padding-bottom: 25.641025641vw;
  }
}

.p-membership_pageHeader_title {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .p-membership_pageHeader_title {
    font-size: 54px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-membership_pageHeader_title {
    font-size: 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-membership_pageHeader_title {
    font-size: 8.2051282051vw;
  }
}

.p-nyukai {
  background-color: #FBFBF6;
}

.p-nyukai_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-nyukai_pageHeader {
    background-image: url(/_/media/page/nyukai/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-nyukai_pageHeader {
    background-image: url(/_/media/page/nyukai/pageheader-sp.jpg);
  }
}

.p-nyukai_target {
  background-color: #fff;
  border: 1px solid #D6D6D6;
}
@media print, screen and (min-width: 781px) {
  .p-nyukai_target {
    margin-top: 40px;
    padding: 40px;
    border-radius: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-nyukai_target {
    padding: 5.1282051282vw;
    border-radius: 2.5641025641vw;
    margin-top: 7.6923076923vw;
  }
}

.p-nyukai_target_age {
  display: flex;
}
@media print, screen and (min-width: 781px) {
  .p-nyukai_target_age_title {
    margin-right: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-nyukai_target_age_title {
    margin-right: 7.6923076923vw;
  }
}

.p-nyukai_target_area {
  border-top: 1px solid #D6D6D6;
}
@media print, screen and (min-width: 781px) {
  .p-nyukai_target_area {
    margin-top: 20px;
    padding-top: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-nyukai_target_area {
    margin-top: 5.1282051282vw;
    padding-top: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-nyukai_target_area_title {
    margin-bottom: 13px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-nyukai_target_area_title {
    margin-bottom: 3.3333333333vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-nyukai_target_area_body_items {
    padding-left: 80px;
    margin-top: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-nyukai_target_area_body_items {
    margin-top: 2.5641025641vw;
  }
}

.p-nyukai_target_area_body_items_detail {
  padding-left: 1rem;
}
.p-tetsuduki {
  background-color: #FBFBF6;
}

.p-tetsuduki_pageHeader {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_pageHeader {
    background-image: url(/_/media/page/tetsuduki/pageheader.jpg);
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_pageHeader {
    background-image: url(/_/media/page/tetsuduki/pageheader-sp.jpg);
  }
}

.p-tetsuduki_pageHeader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_pageHeader_content {
    height: 510px;
    padding-bottom: 100px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-tetsuduki_pageHeader_content {
    height: 410px;
    padding-bottom: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_pageHeader_content {
    height: 87.1794871795vw;
    padding-bottom: 25.641025641vw;
  }
}

.p-tetsuduki_pageHeader_title {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_pageHeader_title {
    font-size: 54px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1366px) {
  .p-tetsuduki_pageHeader_title {
    font-size: 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_pageHeader_title {
    font-size: 8.2051282051vw;
  }
}

.p-tetsuduki_contact {
  background-color: #fff;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_contact {
    border-radius: 20px;
    border: 8px solid #4FA3C2;
    padding: 60px;
    margin-top: -150px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_contact {
    border-radius: 3.8461538462vw;
    border: 1.0256410256vw solid #4FA3C2;
    margin-top: -25.641025641vw;
    padding: 10.2564102564vw 6.6666666667vw;
  }
}

@media print, screen and (max-width: 780px) {
  .p-tetsuduki_card {
    width: 71.7948717949vw;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-tetsuduki_contact_title {
  color: #4FA3C2;
  font-weight: 800;
  line-height: 1.3;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_contact_title {
    font-size: 40px;
    margin-bottom: 24px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_contact_title {
    font-size: 8.2051282051vw;
    margin-bottom: 3.8461538462vw;
  }
}

.p-tetsuduki_contact_body {
  font-weight: 700;
  line-height: 1.8;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_contact_body {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_contact_body {
    font-size: 4.6153846154vw;
  }
}

.p-tetsuduki_numberList {
  border-bottom: 1px solid #D6D6D6;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_numberList {
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_numberList {
    margin-top: 7.6923076923vw;
  }
}

.p-tetsuduki_numberList_item {
  border-top: 1px solid #D6D6D6;
  counter-increment: numberList;
  font-weight: 600;
  display: flex;
  line-height: 1.777;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_numberList_item {
    padding: 15px 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_numberList_item {
    padding: 3.3333333333vw 0;
  }
}
.p-tetsuduki_numberList_item::before {
  font-weight: 800;
  color: #B2A86E;
  content: counter(numberList) ". ";
  flex: 0 0 auto;
  width: 1.3rem;
}
.p-tetsuduki_address {
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_address {
    padding: 35px 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_address {
    padding: 5.1282051282vw;
  }
}

.p-tetsuduki_address_title {
  font-weight: 700;
  display: flex;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_address_title {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_address_title {
    font-size: 5.1282051282vw;
    margin-bottom: 3.8461538462vw;
  }
}
.p-tetsuduki_address_title::before {
  content: "";
  background-color: #4FA3C2;
  flex: 0 0 auto;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_address_title::before {
    width: 17px;
    height: 5px;
    margin-right: 15px;
    margin-top: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_address_title::before {
    width: 4.358974359vw;
    height: 1.2820512821vw;
    margin-right: 3.8461538462vw;
    margin-top: 1.7948717949vw;
  }
}

.p-tetsuduki_address_list_wrap {
  border-top: 1px solid #E8E8E8;
  line-height: 1.4;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_address_list_wrap {
    display: flex;
    align-items: center;
    padding: 13px 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_address_list_wrap {
    padding: 2.5641025641vw 0;
  }
}

@media print, screen and (min-width: 781px) {
  .p-tetsuduki_address_list_time {
    width: 35%;
    margin-right: 1rem;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_address_list_time {
    margin-bottom: 1.2820512821vw;
  }
}

.p-tetsuduki_cardlost_header {
  color: #fff;
  text-align: center;
  font-weight: 700;
  background-color: #4FA3C2;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_cardlost_header {
    padding: 25px 25px 20px 25px;
    font-size: 22px;
    border-radius: 10px 10px 0 0;
    line-height: 1.3;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_cardlost_header {
    line-height: 1.5;
    padding: 2.5641025641vw 5.1282051282vw 3.8461538462vw 5.1282051282vw;
    font-size: 4.8717948718vw;
    border-radius: 2.5641025641vw 2.5641025641vw 0 0;
  }
}

.p-tetsuduki_cardlost_body {
  background-color: #fff;
}
@media print, screen and (min-width: 781px) {
  .p-tetsuduki_cardlost_body {
    border: 4px solid #4FA3C2;
    padding: 30px 30px 40px 30px;
    border-radius: 0 0 10px 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-tetsuduki_cardlost_body {
    border: 1.0256410256vw solid #4FA3C2;
    padding: 6.4102564103vw 6.4102564103vw 7.6923076923vw 6.4102564103vw;
    border-radius: 0 0 2.5641025641vw 2.5641025641vw;
  }
}

.p-top_video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.p-top_video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.p-top_visual {
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-top_visual {
    height: 100vh;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual {
    height: 100svh;
  }
}
.p-top_visual::before {
  content: "";
  position: absolute;
  left: 0;
}
@media print, screen and (min-width: 781px) {
  .p-top_visual::before {
    background: linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    top: 0;
    height: 100%;
    width: 70%;
    opacity: 0.5;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual::before {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.99) 99%, rgb(0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    bottom: 0;
    height: 50%;
    width: 100%;
    opacity: 0.7;
  }
}

.p-top_visual_content {
  position: absolute;
  display: flex;
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_content {
    padding-left: 120px;
    padding-right: 120px;
    bottom: 190px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1920px) {
  .p-top_visual_content {
    padding-left: 6.25vw;
    padding-right: 6.25vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_content {
    flex-direction: column;
    bottom: 28.7179487179vw;
    padding-left: 5.1282051282vw;
  }
}

.p-top_visual_copy {
  color: #fff;
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_copy {
    order: 2;
  }
}

.p-top_visual_copy_main {
  font-weight: 900;
  line-height: 1.276;
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_copy_main {
    font-size: 94px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1920px) {
  .p-top_visual_copy_main {
    font-size: 4.8958333333vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_copy_main {
    font-size: 10vw;
  }
}

.p-top_visual_copy_lead {
  font-weight: 700;
  line-height: 1.7;
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_copy_lead {
    font-size: 36px;
    margin-top: 20px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1920px) {
  .p-top_visual_copy_lead {
    font-size: 1.875vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_copy_lead {
    font-size: 4.8717948718vw;
    margin-top: 3.0769230769vw;
  }
}

@media print, screen and (max-width: 780px) {
  .p-top_visual_university {
    order: 1;
    align-self: flex-end;
    margin-bottom: 2.5641025641vw;
  }
}

.p-top_visual_university_item {
  font-weight: 700;
  color: #fff;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_university_item {
    padding: 20px 35px;
    border-radius: 35px;
    font-size: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1400px) {
  .p-top_visual_university_item {
    font-size: 2.1428571429vw;
    padding: 1.4285714286vw 2.5vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_university_item {
    padding: 2.5641025641vw 5.1282051282vw;
    border-radius: 7.6923076923vw;
    font-size: 3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_university_item:nth-child(2) {
    margin-top: -30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1400px) {
  .p-top_visual_university_item:nth-child(2) {
    margin-top: -2.1428571429vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_university_item:nth-child(2) {
    margin-top: -3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_university_item:nth-child(3) {
    margin-top: -30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1400px) {
  .p-top_visual_university_item:nth-child(3) {
    margin-top: -2.1428571429vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_university_item:nth-child(3) {
    margin-top: -3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_university_item:nth-child(4) {
    margin-top: -30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1400px) {
  .p-top_visual_university_item:nth-child(4) {
    margin-top: -2.1428571429vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_university_item:nth-child(4) {
    margin-top: -3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_visual_university_item:nth-child(5) {
    margin-top: -30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1400px) {
  .p-top_visual_university_item:nth-child(5) {
    margin-top: -2.1428571429vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_visual_university_item:nth-child(5) {
    margin-top: -3.8461538462vw;
  }
}

.p-top_action {
  transition: width 0.3s ease;
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 781px) {
  .p-top_action {
    width: 993px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1080px) {
  .p-top_action {
    width: 700px;
  }
}
.p-top_action_anchor {
  display: block;
  position: relative;
}
.p-top_action_anchor::before {
  transition: opacity 0.2s ease;
  opacity: 0;
  display: block;
  content: "";
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 781px) {
  .p-top_action_anchor::before {
    border-radius: 20px 20px 0 0;
  }
}
.p-top_action_anchor:hover::before {
  opacity: 1;
}

.p-top_action_image {
  width: 100%;
}
.p-top_action_image:hover {
  opacity: 0.8;
}

.p-top_kentai {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-top_kentai {
    padding-top: 200px;
    padding-bottom: 150px;
    margin-top: -60px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_kentai {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: -60px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_kentai {
    padding-top: 15.3846153846vw;
    padding-bottom: 15.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-top_kentai_container {
    display: flex;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .p-top_kentai_container {
    display: block;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_kentai_image {
    order: 2;
    flex-grow: 1;
    margin-left: 40px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .p-top_kentai_image {
    max-width: 60%;
    margin-left: 0;
    margin-bottom: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_kentai_image {
    margin-bottom: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-top_kentai_main {
    flex-shrink: 0;
    width: 450px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 900px) {
  .p-top_kentai_main {
    width: 100%;
  }
}
.p-top_kentai_phrase {
  font-weight: 900;
  line-height: 1.26;
}
@media print, screen and (min-width: 781px) {
  .p-top_kentai_phrase {
    font-size: 54px;
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_kentai_phrase {
    font-size: 9.7435897436vw;
    margin-bottom: 3.8461538462vw;
  }
}

.p-top_medicine {
  position: relative;
}
.p-top_medicine_phrase {
  font-weight: 800;
  line-height: 1.476;
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_phrase {
    font-size: 42px;
    text-align: center;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_medicine_phrase {
    font-size: 32px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_phrase {
    font-size: 6.6666666667vw;
  }
}

.p-top_medicine_collaboration {
  font-weight: 800;
  border-top: 1px solid #E5E2CD;
  border-bottom: 1px solid #E5E2CD;
  padding: 1rem 0;
  line-height: 1.555;
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_collaboration {
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 36px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_collaboration {
    margin-top: 7.6923076923vw;
    margin-bottom: 7.6923076923vw;
    font-size: 4.6153846154vw;
  }
}

.p-top_medicine_university {
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_university {
    gap: 4.2%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_university {
    flex-wrap: wrap;
    gap: 4.6153846154vw 7.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .p-top_medicine_university_item {
    width: calc(50% - 3.8461538462vw);
  }
}

.p-top_medicine_university_item img {
  transition: opacity 0.2s ease;
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_university_item img {
    width: 100%;
    display: block;
  }
}
.p-top_medicine_university_item img:hover {
  opacity: 0.7;
}

@media print, screen and (min-width: 781px) {
  .p-top_medicine_president {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_medicine_president {
    max-width: 750px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_president {
    margin-top: 12.8205128205vw;
  }
}

.p-top_medicine_president_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_president_item {
    flex: 0 0 calc((100% - 60px) / 3);
    max-width: 360px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_medicine_president_item {
    flex: 0 0 calc((100% - 30px) / 2);
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_president_item {
    border-radius: 3.8461538462vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_president_item + .p-top_medicine_president_item {
    margin-top: 5.1282051282vw;
  }
}

.p-top_medicine_president_comment {
  line-height: 1.777;
  font-weight: 500;
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_president_comment {
    padding: 40px 40px 0 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_president_comment {
    padding: 7.6923076923vw 7.6923076923vw 0 7.6923076923vw;
  }
}

.p-top_medicine_president_name {
  line-height: 1.4;
  font-weight: 600;
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_president_name {
    padding: 20px 40px 40px 40px;
    font-size: 20px;
    margin-top: auto;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_president_name {
    padding: 3.8461538462vw 7.6923076923vw 7.6923076923vw 7.6923076923vw;
    font-size: 4.6153846154vw;
  }
}

.p-top_medicine_president_image {
  width: 100%;
}
@media print, screen and (min-width: 781px) {
  .p-top_medicine_president_image {
    border-radius: 0 0 15px 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_medicine_president_image {
    border-radius: 0 0 3.8461538462vw 3.8461538462vw;
  }
}

.p-top_interview {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
}
@media print, screen and (min-width: 1367px) {
  .p-top_interview_message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_interview_message {
    text-align: center;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_message {
    text-align: center;
  }
}

.p-top_interview_message_number {
  font-weight: 900;
  color: #4FA3C2;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_message_number {
    font-size: 68px;
    flex: 0 0 auto;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_interview_message_number {
    margin-bottom: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_message_number {
    font-size: 12.8205128205vw;
  }
}

.p-top_interview_message_desc {
  font-weight: 700;
  line-height: 1.7;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_message_desc {
    font-size: 22px;
  }
}
@media print, screen and (min-width: 1367px) {
  .p-top_interview_message_desc {
    margin-left: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_message_desc {
    font-size: 4.8717948718vw;
    margin-top: 7.6923076923vw;
  }
}

.p-top_interview_slider_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_item {
    display: flex;
    border-radius: 15px;
    min-width: 860px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_interview_slider_item {
    min-width: 600px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_item {
    margin-top: 9.2307692308vw;
    border-radius: 3.8461538462vw;
    margin-bottom: 5.1282051282vw;
  }
}

.p-top_interview_slider_image {
  overflow: hidden;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_image {
    border-radius: 15px 0 0 15px;
    width: 50%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_image {
    border-radius: 3.8461538462vw 3.8461538462vw 0 0;
  }
}

@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_content {
    width: 50%;
    padding: 65px 50px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_interview_slider_content {
    padding: 35px 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_content {
    padding: 7.6923076923vw;
  }
}

.p-top_interview_slider_content_comment {
  font-weight: 700;
  line-height: 1.636;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_content_comment {
    font-size: 22px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_interview_slider_content_comment {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_content_comment {
    font-size: 4.358974359vw;
  }
}

.p-top_interview_slider_content_name {
  font-weight: 600;
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_content_name {
    margin-top: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_content_name {
    font-size: 3.8461538462vw;
    margin-top: 3.8461538462vw;
  }
}

.p-top_interview_slider_content_name_initial {
  font-weight: 700;
  color: #B2A86E;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_content_name_initial {
    font-size: 24px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_content_name_initial {
    font-size: 5.641025641vw;
  }
}

.p-top_interview_slider_content_anchor {
  color: #4FA3C2;
  font-weight: 700;
  display: flex;
  align-items: center;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_content_anchor {
    font-size: 18px;
    margin-top: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_content_anchor {
    font-size: 3.8461538462vw;
    margin-top: 5.1282051282vw;
  }
}
.p-top_interview_slider_content_anchor .m-icon {
  margin-top: 0.2rem;
}
.p-top_interview_slider_controller {
  display: flex;
  justify-content: center;
}
.p-top_interview_slider_prev,
.p-top_interview_slider_next {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #4FA3C2;
  color: #4FA3C2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.1s ease;
  padding: 0;
  margin: 0;
}
@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_prev,
  .p-top_interview_slider_next {
    width: 44px;
    height: 44px;
    font-size: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_prev,
  .p-top_interview_slider_next {
    width: 11.2820512821vw;
    height: 11.2820512821vw;
    font-size: 7.6923076923vw;
  }
}
.p-top_interview_slider_prev:active,
.p-top_interview_slider_next:active {
  transform: scale(0.9);
}
.p-top_interview_slider_prev::before,
.p-top_interview_slider_next::before {
  transition: margin 0.2s ease;
}
.p-top_interview_slider_prev[disabled],
.p-top_interview_slider_next[disabled] {
  filter: saturate(0%);
}

.p-top_interview_slider_prev::before {
  font-family: "Material Icons";
  content: "chevron_left";
}
@media (any-hover: hover) {
  .p-top_interview_slider_prev:hover::before {
    margin-right: 0.25rem;
  }
}

@media print, screen and (min-width: 781px) {
  .p-top_interview_slider_next {
    margin-left: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_interview_slider_next {
    margin-left: 5.1282051282vw;
  }
}
.p-top_interview_slider_next::before {
  font-family: "Material Icons";
  content: "chevron_right";
}
@media (any-hover: hover) {
  .p-top_interview_slider_next:hover::before {
    margin-left: 0.25rem;
  }
}

.p-top_about {
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_about_card {
    display: block;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card {
    margin-top: 7.6923076923vw;
  }
}

.p-top_about_card_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_item {
    border-radius: 15px;
    width: 48.5%;
    padding: 50px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_about_card_item {
    width: 100%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_item {
    padding: 10.2564102564vw 7.6923076923vw;
    border-radius: 3.8461538462vw;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_about_card_item + .p-top_about_card_item {
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_item + .p-top_about_card_item {
    margin-top: 5.1282051282vw;
  }
}

.p-top_about_card_title {
  color: #B2A86E;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #DEDEDE;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_title {
    font-size: 22px;
    padding-bottom: 25px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_title {
    font-size: 5.1282051282vw;
    padding-bottom: 5.1282051282vw;
  }
}

.p-top_about_card_value {
  font-weight: 800;
  color: #4FA3C2;
  text-align: center;
  border-bottom: 1px solid #DEDEDE;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_value {
    font-size: 38px;
    padding: 20px 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_value {
    font-size: 7.6923076923vw;
    padding: 5.1282051282vw 0;
  }
}

.p-top_about_card_area {
  font-weight: 800;
  color: #4FA3C2;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_area {
    font-size: 38px;
    padding: 20px 0 25px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_area {
    padding: 5.1282051282vw 0 6.4102564103vw;
    font-size: 7.6923076923vw;
  }
}

.p-top_about_card_areaitem {
  font-weight: 500;
  line-height: 1.7;
}
.p-top_about_card_list {
  border-bottom: 1px solid #DEDEDE;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_list {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }
}
.p-top_about_card_list_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_list_wrap {
    padding: 5.1282051282vw 0;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_list_wrap + .p-top_about_card_list_wrap {
    border-left: 1px solid #DEDEDE;
    margin-left: 20px;
    padding-left: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_list_wrap + .p-top_about_card_list_wrap {
    border-top: 1px solid #DEDEDE;
  }
}

.p-top_about_card_list_term {
  font-weight: 500;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_list_term {
    margin-right: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_list_term {
    margin-right: 5.1282051282vw;
  }
}

.p-top_about_card_list_value {
  font-weight: 800;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_list_value {
    font-size: 28px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_list_value {
    font-size: 6.6666666667vw;
  }
}

.p-top_about_card_date {
  font-weight: 500;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-top_about_card_date {
    font-size: 14px;
    padding-top: 25px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_about_card_date {
    font-size: 3.5897435897vw;
    padding-top: 5.1282051282vw;
  }
}

.p-top_other {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-top_other_card {
    display: flex;
    justify-content: space-between;
  }
}
.p-top_other_card_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-top_other_card_item {
    width: 48.5%;
    border-radius: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_other_card_item {
    border-radius: 3.8461538462vw;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_other_card_item + .p-top_other_card_item {
    margin-top: 5.1282051282vw;
  }
}

.p-top_other_card_image {
  overflow: hidden;
}
@media print, screen and (min-width: 781px) {
  .p-top_other_card_image {
    border-radius: 15px 15px 0 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_other_card_image {
    border-radius: 3.8461538462vw 3.8461538462vw 0 0;
  }
}

@media print, screen and (min-width: 781px) {
  .p-top_other_card_content {
    padding: 40px 60px 50px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_other_card_content {
    padding: 35px 40px 45px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_other_card_content {
    padding: 7.6923076923vw 7.6923076923vw 10.2564102564vw 7.6923076923vw;
  }
}

.p-top_other_card_title {
  font-weight: 900;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-top_other_card_title {
    font-size: 38px;
    margin-bottom: 25px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_other_card_title {
    font-size: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_other_card_title {
    font-size: 7.1794871795vw;
  }
}

.p-top_other_card_body {
  line-height: 1.777;
}
@media print, screen and (min-width: 781px) {
  .p-top_other_card_body {
    margin-bottom: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_other_card_body {
    margin-bottom: 5.1282051282vw;
  }
}

.p-top_other_card_anchor {
  font-weight: 700;
  color: #4FA3C2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-top_membership {
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_card {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_membership_card {
    display: block;
  }
}
.p-top_membership_item {
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_item {
    border-radius: 15px;
    width: 48.5%;
    padding: 50px;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_membership_item {
    width: 100%;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_item {
    border-radius: 3.8461538462vw;
    padding: 10.2564102564vw 7.6923076923vw;
  }
}
@media print, screen and (min-width: 781px) and (max-width: 1336px) {
  .p-top_membership_item + .p-top_membership_item {
    margin-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_item + .p-top_membership_item {
    margin-top: 5.1282051282vw;
  }
}

.p-top_membership_title {
  font-weight: 700;
  text-align: center;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_title {
    font-size: 26px;
    margin-bottom: 24px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_title {
    font-size: 5.1282051282vw;
    margin-bottom: 5.1282051282vw;
  }
}

.p-top_membership_list {
  border-bottom: 1px solid #DEDEDE;
}
.p-top_membership_list_wrap {
  font-weight: 500;
  border-top: 1px solid #DEDEDE;
  line-height: 1.5;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_list_wrap {
    display: flex;
    padding: 15px 0;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_list_wrap {
    padding: 3.8461538462vw 0;
  }
}

.p-top_membership_list_term {
  color: #B2A86E;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_list_term {
    margin-right: 10px;
    width: 7.5rem;
    flex: 0 0 auto;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_list_term {
    margin-bottom: 1.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .p-top_membership_list_desc {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_list_desc {
    font-size: 4.6153846154vw;
  }
}

.p-top_membership_magazineImage {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_magazineImage {
    width: 280px;
    margin: 0 auto 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_magazineImage {
    width: 71.7948717949vw;
    margin: 0 auto 7.6923076923vw;
  }
}

.p-top_membership_magazineFile {
  border-top: 1px solid #DEDEDE;
  text-align: center;
  color: #4FA3C2;
  font-weight: 500;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_magazineFile {
    padding: 15px 0;
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_magazineFile {
    padding: 3.8461538462vw 0;
    font-size: 4.6153846154vw;
  }
}
.p-top_membership_magazineFile .m-icon {
  vertical-align: middle;
  margin-left: 0.5rem;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_magazineFile .m-icon {
    font-size: 28px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_magazineFile .m-icon {
    font-size: 6.1538461538vw;
  }
}

.p-top_membership_magazineFile_anchor {
  line-height: 1.4;
  text-decoration: underline;
  color: #4FA3C2;
}
.p-top_membership_magazineFile_anchor:hover {
  text-decoration: none;
}

.p-top_membership_magazine_backnumber {
  border-top: 1px solid #DEDEDE;
}
@media print, screen and (min-width: 781px) {
  .p-top_membership_magazine_backnumber {
    padding-top: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_membership_magazine_backnumber {
    padding-top: 7.6923076923vw;
  }
}

.p-top_membership_magazine_backnumber_anchor {
  color: #4FA3C2;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-top_news {
  position: relative;
}
@media print, screen and (min-width: 781px) {
  .p-top_news_container {
    display: flex;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_news_head {
    flex: 0 0 auto;
    margin-right: 50px;
  }
}
@media print, screen and (min-width: 781px) {
  .p-top_news_content {
    width: 100%;
  }
}
.p-top_news_list {
  border-bottom: 1px solid #DEDEDE;
}
.p-top_news_list_item {
  border-top: 1px solid #DEDEDE;
  line-height: 1.6;
  display: flex;
  align-items: flex-start;
}
@media print, screen and (min-width: 781px) {
  .p-top_news_list_item {
    font-size: 16px;
    padding: 30px 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_news_list_item {
    flex-wrap: wrap;
    padding: 5.1282051282vw 0;
  }
}

.p-top_news_list_time {
  font-weight: 500;
}
@media print, screen and (min-width: 781px) {
  .p-top_news_list_time {
    flex: 0 0 auto;
  }
}
.p-top_news_list_category {
  font-weight: 500;
  color: #fff;
  padding: 0.15rem 2rem;
  border-radius: 4px;
}
@media print, screen and (min-width: 781px) {
  .p-top_news_list_category {
    font-size: 14px;
    margin: 0 20px;
    flex: 0 0 auto;
  }
}
@media print, screen and (max-width: 780px) {
  .p-top_news_list_category {
    margin-left: 5.1282051282vw;
    font-size: 3.5897435897vw;
  }
}
.p-top_news_list_category[data-category=info] {
  background-color: #4FA3C2;
}
.p-top_news_list_category[data-category=update] {
  background-color: #B2A86E;
}

@media print, screen and (max-width: 780px) {
  .p-top_news_list_title {
    width: 100%;
    margin-top: 2.5641025641vw;
  }
}

.p-top_news_list_title_anchor {
  text-decoration: underline;
  color: inherit;
}
.p-top_news_list_title_anchor:hover {
  text-decoration: none;
}

@media print, screen and (min-width: 781px) {
  .p-top_blank {
    height: 100vh;
  }
}
.u-tal {
  text-align: left;
}

@media print, screen and (min-width: 781px) {
  .u-tal-pc {
    text-align: left !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-tal-sp {
    text-align: left !important;
  }
}

.u-tac {
  text-align: center !important;
}

@media print, screen and (min-width: 781px) {
  .u-tac-pc {
    text-align: center !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-tac-sp {
    text-align: center !important;
  }
}

.u-tar {
  text-align: right !important;
}

@media print, screen and (min-width: 781px) {
  .u-tar-pc {
    text-align: right !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-tar-sp {
    text-align: right !important;
  }
}

.u-fl {
  float: left !important;
}

@media print, screen and (min-width: 781px) {
  .u-fl-pc {
    float: left !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fl-sp {
    float: left !important;
  }
}

.u-fr {
  float: right !important;
}

@media print, screen and (min-width: 781px) {
  .u-fr-pc {
    float: right !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fr-sp {
    float: right !important;
  }
}

.u-fw-300 {
  font-weight: 300 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-300-pc {
    font-weight: 300 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-300-sp {
    font-weight: 300 !important;
  }
}

.u-fw-400 {
  font-weight: 400 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-400-pc {
    font-weight: 400 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-400-sp {
    font-weight: 400 !important;
  }
}

.u-fw-500 {
  font-weight: 500 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-500-pc {
    font-weight: 500 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-500-sp {
    font-weight: 500 !important;
  }
}

.u-fw-600 {
  font-weight: 600 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-600-pc {
    font-weight: 600 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-600-sp {
    font-weight: 600 !important;
  }
}

.u-fw-700 {
  font-weight: 700 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-700-pc {
    font-weight: 700 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-700-sp {
    font-weight: 700 !important;
  }
}

.u-fw-800 {
  font-weight: 800 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-800-pc {
    font-weight: 800 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-800-sp {
    font-weight: 800 !important;
  }
}

.u-fw-900 {
  font-weight: 900 !important;
}

@media print, screen and (min-width: 781px) {
  .u-fw-900-pc {
    font-weight: 900 !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-fw-900-sp {
    font-weight: 900 !important;
  }
}

.u-width-1rem {
  width: 1rem;
}

.u-width-1rem-over {
  width: 1.3rem;
}

.u-width-2rem {
  width: 2rem;
}

.u-width-2rem-over {
  width: 2.3rem;
}

.u-width-3rem {
  width: 3rem;
}

.u-width-3rem-over {
  width: 3.3rem;
}

.u-width-4rem {
  width: 4rem;
}

.u-width-4rem-over {
  width: 4.3rem;
}

.u-width-5rem {
  width: 5rem;
}

.u-width-5rem-over {
  width: 5.3rem;
}

.u-cf:before, .u-cf:after {
  content: " ";
  display: table;
}
.u-cf:after {
  clear: both;
}
.u-cf {
  *zoom: 1;
}

.u-tdu {
  text-decoration: underline;
}

@media print, screen and (max-width: 780px) {
  .u-pc {
    display: none !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-sp {
    display: none !important;
  }
}

.u-flex {
  display: flex;
}

@media print, screen and (min-width: 781px) {
  .u-flex-pc {
    display: flex;
  }
}

@media print, screen and (max-width: 780px) {
  .u-flex-sp {
    display: flex;
  }
}

.u-jcc {
  justify-content: center;
}

@media print, screen and (min-width: 781px) {
  .u-jcc-pc {
    justify-content: center;
  }
}

@media print, screen and (max-width: 780px) {
  .u-jcc-sp {
    justify-content: center;
  }
}

.u-jcs {
  justify-content: flex-start;
}

@media print, screen and (min-width: 781px) {
  .u-jcs-pc {
    justify-content: flex-start;
  }
}

@media print, screen and (max-width: 780px) {
  .u-jcs-sp {
    justify-content: flex-start;
  }
}

.u-jce {
  justify-content: flex-end;
}

@media print, screen and (min-width: 781px) {
  .u-jce-pc {
    justify-content: flex-end;
  }
}

@media print, screen and (max-width: 780px) {
  .u-jce-sp {
    justify-content: flex-end;
  }
}

.u-aic {
  align-items: center;
}

@media print, screen and (min-width: 781px) {
  .u-aic-pc {
    align-items: center;
  }
}

@media print, screen and (max-width: 780px) {
  .u-aic-sp {
    align-items: center;
  }
}

.u-ais {
  align-items: flex-start;
}

@media print, screen and (min-width: 781px) {
  .u-ais-pc {
    align-items: flex-start;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ais-sp {
    align-items: flex-start;
  }
}

.u-aie {
  align-items: flex-end;
}

@media print, screen and (min-width: 781px) {
  .u-aie-pc {
    align-items: flex-end;
  }
}

@media print, screen and (max-width: 780px) {
  .u-aie-sp {
    align-items: flex-end;
  }
}

@media print, screen and (min-width: 781px) {
  .u-radius-10 {
    border-radius: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-radius-10 {
    border-radius: 2.5641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-radius-15 {
    border-radius: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-radius-15 {
    border-radius: 3.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-radius-20 {
    border-radius: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-radius-20 {
    border-radius: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-radius-10-pc {
    border-radius: 10px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-radius-15-pc {
    border-radius: 15px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-radius-20-pc {
    border-radius: 20px;
  }
}

@media print, screen and (max-width: 780px) {
  .u-radius-10-sp {
    border-radius: 2.5641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-radius-15-sp {
    border-radius: 3.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-radius-20-sp {
    border-radius: 5.1282051282vw;
  }
}

.u-color-primary {
  color: #4FA3C2;
}

.u-color-secondary {
  color: #B2A86E;
}

.u-color-link {
  color: #4688EC;
}

.u-bgc-f7f7ed {
  background-color: #F7F7ED;
}

.u-bgc-fbfbf6 {
  background-color: #FBFBF6;
}

.u-bgc-white {
  background-color: #fff;
}

.u-bgc-black {
  background-color: #000;
}

.u-width-100per {
  width: 100%;
}

.u-block {
  display: block;
}

@media print, screen and (min-width: 781px) {
  .u-block-pc {
    display: block;
  }
}

@media print, screen and (max-width: 780px) {
  .u-block-sp {
    display: block;
  }
}

.u-family-mincho {
  font-family: "Shippori Mincho", serif;
}

@media print, screen and (min-width: 781px) {
  .u-mt-0 {
    margin-top: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-0 {
    margin-top: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-5 {
    margin-top: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-5 {
    margin-top: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-10 {
    margin-top: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-10 {
    margin-top: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-15 {
    margin-top: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-15 {
    margin-top: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-20 {
    margin-top: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-20 {
    margin-top: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-25 {
    margin-top: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-25 {
    margin-top: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-30 {
    margin-top: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-30 {
    margin-top: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-35 {
    margin-top: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-35 {
    margin-top: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-40 {
    margin-top: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-40 {
    margin-top: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-45 {
    margin-top: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-45 {
    margin-top: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-50 {
    margin-top: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-50 {
    margin-top: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-55 {
    margin-top: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-55 {
    margin-top: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-60 {
    margin-top: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-60 {
    margin-top: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-65 {
    margin-top: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-65 {
    margin-top: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-70 {
    margin-top: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-70 {
    margin-top: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-75 {
    margin-top: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-75 {
    margin-top: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-80 {
    margin-top: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-80 {
    margin-top: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-85 {
    margin-top: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-85 {
    margin-top: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-90 {
    margin-top: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-90 {
    margin-top: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-95 {
    margin-top: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-95 {
    margin-top: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-100 {
    margin-top: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-100 {
    margin-top: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-105 {
    margin-top: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-105 {
    margin-top: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-110 {
    margin-top: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-110 {
    margin-top: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-115 {
    margin-top: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-115 {
    margin-top: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-120 {
    margin-top: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-120 {
    margin-top: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-125 {
    margin-top: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-125 {
    margin-top: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-130 {
    margin-top: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-130 {
    margin-top: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-135 {
    margin-top: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-135 {
    margin-top: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-140 {
    margin-top: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-140 {
    margin-top: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-145 {
    margin-top: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-145 {
    margin-top: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-150 {
    margin-top: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-150 {
    margin-top: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-155 {
    margin-top: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-155 {
    margin-top: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-160 {
    margin-top: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-160 {
    margin-top: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-165 {
    margin-top: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-165 {
    margin-top: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-170 {
    margin-top: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-170 {
    margin-top: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-175 {
    margin-top: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-175 {
    margin-top: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-180 {
    margin-top: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-180 {
    margin-top: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-185 {
    margin-top: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-185 {
    margin-top: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-190 {
    margin-top: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-190 {
    margin-top: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-195 {
    margin-top: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-195 {
    margin-top: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-200 {
    margin-top: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mt-200 {
    margin-top: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-0 {
    margin-right: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-0 {
    margin-right: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-5 {
    margin-right: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-5 {
    margin-right: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-10 {
    margin-right: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-10 {
    margin-right: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-15 {
    margin-right: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-15 {
    margin-right: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-20 {
    margin-right: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-20 {
    margin-right: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-25 {
    margin-right: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-25 {
    margin-right: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-30 {
    margin-right: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-30 {
    margin-right: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-35 {
    margin-right: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-35 {
    margin-right: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-40 {
    margin-right: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-40 {
    margin-right: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-45 {
    margin-right: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-45 {
    margin-right: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-50 {
    margin-right: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-50 {
    margin-right: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-55 {
    margin-right: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-55 {
    margin-right: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-60 {
    margin-right: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-60 {
    margin-right: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-65 {
    margin-right: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-65 {
    margin-right: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-70 {
    margin-right: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-70 {
    margin-right: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-75 {
    margin-right: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-75 {
    margin-right: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-80 {
    margin-right: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-80 {
    margin-right: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-85 {
    margin-right: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-85 {
    margin-right: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-90 {
    margin-right: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-90 {
    margin-right: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-95 {
    margin-right: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-95 {
    margin-right: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-100 {
    margin-right: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-100 {
    margin-right: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-105 {
    margin-right: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-105 {
    margin-right: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-110 {
    margin-right: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-110 {
    margin-right: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-115 {
    margin-right: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-115 {
    margin-right: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-120 {
    margin-right: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-120 {
    margin-right: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-125 {
    margin-right: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-125 {
    margin-right: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-130 {
    margin-right: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-130 {
    margin-right: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-135 {
    margin-right: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-135 {
    margin-right: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-140 {
    margin-right: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-140 {
    margin-right: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-145 {
    margin-right: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-145 {
    margin-right: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-150 {
    margin-right: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-150 {
    margin-right: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-155 {
    margin-right: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-155 {
    margin-right: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-160 {
    margin-right: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-160 {
    margin-right: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-165 {
    margin-right: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-165 {
    margin-right: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-170 {
    margin-right: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-170 {
    margin-right: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-175 {
    margin-right: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-175 {
    margin-right: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-180 {
    margin-right: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-180 {
    margin-right: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-185 {
    margin-right: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-185 {
    margin-right: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-190 {
    margin-right: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-190 {
    margin-right: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-195 {
    margin-right: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-195 {
    margin-right: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-200 {
    margin-right: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mr-200 {
    margin-right: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-0 {
    margin-bottom: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-0 {
    margin-bottom: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-5 {
    margin-bottom: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-5 {
    margin-bottom: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-10 {
    margin-bottom: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-10 {
    margin-bottom: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-15 {
    margin-bottom: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-15 {
    margin-bottom: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-20 {
    margin-bottom: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-20 {
    margin-bottom: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-25 {
    margin-bottom: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-25 {
    margin-bottom: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-30 {
    margin-bottom: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-30 {
    margin-bottom: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-35 {
    margin-bottom: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-35 {
    margin-bottom: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-40 {
    margin-bottom: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-40 {
    margin-bottom: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-45 {
    margin-bottom: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-45 {
    margin-bottom: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-50 {
    margin-bottom: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-50 {
    margin-bottom: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-55 {
    margin-bottom: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-55 {
    margin-bottom: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-60 {
    margin-bottom: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-60 {
    margin-bottom: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-65 {
    margin-bottom: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-65 {
    margin-bottom: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-70 {
    margin-bottom: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-70 {
    margin-bottom: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-75 {
    margin-bottom: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-75 {
    margin-bottom: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-80 {
    margin-bottom: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-80 {
    margin-bottom: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-85 {
    margin-bottom: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-85 {
    margin-bottom: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-90 {
    margin-bottom: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-90 {
    margin-bottom: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-95 {
    margin-bottom: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-95 {
    margin-bottom: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-100 {
    margin-bottom: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-100 {
    margin-bottom: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-105 {
    margin-bottom: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-105 {
    margin-bottom: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-110 {
    margin-bottom: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-110 {
    margin-bottom: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-115 {
    margin-bottom: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-115 {
    margin-bottom: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-120 {
    margin-bottom: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-120 {
    margin-bottom: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-125 {
    margin-bottom: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-125 {
    margin-bottom: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-130 {
    margin-bottom: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-130 {
    margin-bottom: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-135 {
    margin-bottom: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-135 {
    margin-bottom: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-140 {
    margin-bottom: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-140 {
    margin-bottom: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-145 {
    margin-bottom: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-145 {
    margin-bottom: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-150 {
    margin-bottom: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-150 {
    margin-bottom: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-155 {
    margin-bottom: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-155 {
    margin-bottom: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-160 {
    margin-bottom: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-160 {
    margin-bottom: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-165 {
    margin-bottom: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-165 {
    margin-bottom: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-170 {
    margin-bottom: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-170 {
    margin-bottom: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-175 {
    margin-bottom: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-175 {
    margin-bottom: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-180 {
    margin-bottom: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-180 {
    margin-bottom: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-185 {
    margin-bottom: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-185 {
    margin-bottom: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-190 {
    margin-bottom: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-190 {
    margin-bottom: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-195 {
    margin-bottom: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-195 {
    margin-bottom: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-200 {
    margin-bottom: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-mb-200 {
    margin-bottom: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-0 {
    margin-left: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-0 {
    margin-left: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-5 {
    margin-left: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-5 {
    margin-left: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-10 {
    margin-left: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-10 {
    margin-left: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-15 {
    margin-left: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-15 {
    margin-left: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-20 {
    margin-left: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-20 {
    margin-left: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-25 {
    margin-left: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-25 {
    margin-left: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-30 {
    margin-left: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-30 {
    margin-left: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-35 {
    margin-left: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-35 {
    margin-left: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-40 {
    margin-left: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-40 {
    margin-left: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-45 {
    margin-left: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-45 {
    margin-left: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-50 {
    margin-left: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-50 {
    margin-left: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-55 {
    margin-left: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-55 {
    margin-left: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-60 {
    margin-left: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-60 {
    margin-left: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-65 {
    margin-left: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-65 {
    margin-left: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-70 {
    margin-left: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-70 {
    margin-left: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-75 {
    margin-left: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-75 {
    margin-left: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-80 {
    margin-left: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-80 {
    margin-left: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-85 {
    margin-left: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-85 {
    margin-left: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-90 {
    margin-left: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-90 {
    margin-left: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-95 {
    margin-left: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-95 {
    margin-left: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-100 {
    margin-left: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-100 {
    margin-left: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-105 {
    margin-left: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-105 {
    margin-left: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-110 {
    margin-left: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-110 {
    margin-left: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-115 {
    margin-left: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-115 {
    margin-left: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-120 {
    margin-left: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-120 {
    margin-left: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-125 {
    margin-left: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-125 {
    margin-left: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-130 {
    margin-left: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-130 {
    margin-left: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-135 {
    margin-left: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-135 {
    margin-left: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-140 {
    margin-left: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-140 {
    margin-left: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-145 {
    margin-left: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-145 {
    margin-left: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-150 {
    margin-left: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-150 {
    margin-left: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-155 {
    margin-left: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-155 {
    margin-left: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-160 {
    margin-left: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-160 {
    margin-left: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-165 {
    margin-left: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-165 {
    margin-left: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-170 {
    margin-left: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-170 {
    margin-left: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-175 {
    margin-left: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-175 {
    margin-left: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-180 {
    margin-left: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-180 {
    margin-left: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-185 {
    margin-left: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-185 {
    margin-left: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-190 {
    margin-left: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-190 {
    margin-left: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-195 {
    margin-left: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-195 {
    margin-left: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-200 {
    margin-left: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-ml-200 {
    margin-left: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-0-pc {
    margin-top: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-5-pc {
    margin-top: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-10-pc {
    margin-top: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-15-pc {
    margin-top: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-20-pc {
    margin-top: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-25-pc {
    margin-top: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-30-pc {
    margin-top: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-35-pc {
    margin-top: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-40-pc {
    margin-top: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-45-pc {
    margin-top: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-50-pc {
    margin-top: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-55-pc {
    margin-top: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-60-pc {
    margin-top: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-65-pc {
    margin-top: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-70-pc {
    margin-top: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-75-pc {
    margin-top: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-80-pc {
    margin-top: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-85-pc {
    margin-top: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-90-pc {
    margin-top: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-95-pc {
    margin-top: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-100-pc {
    margin-top: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-105-pc {
    margin-top: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-110-pc {
    margin-top: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-115-pc {
    margin-top: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-120-pc {
    margin-top: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-125-pc {
    margin-top: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-130-pc {
    margin-top: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-135-pc {
    margin-top: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-140-pc {
    margin-top: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-145-pc {
    margin-top: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-150-pc {
    margin-top: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-155-pc {
    margin-top: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-160-pc {
    margin-top: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-165-pc {
    margin-top: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-170-pc {
    margin-top: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-175-pc {
    margin-top: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-180-pc {
    margin-top: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-185-pc {
    margin-top: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-190-pc {
    margin-top: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-195-pc {
    margin-top: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mt-200-pc {
    margin-top: 200px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-0-pc {
    margin-right: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-5-pc {
    margin-right: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-10-pc {
    margin-right: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-15-pc {
    margin-right: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-20-pc {
    margin-right: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-25-pc {
    margin-right: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-30-pc {
    margin-right: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-35-pc {
    margin-right: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-40-pc {
    margin-right: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-45-pc {
    margin-right: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-50-pc {
    margin-right: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-55-pc {
    margin-right: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-60-pc {
    margin-right: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-65-pc {
    margin-right: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-70-pc {
    margin-right: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-75-pc {
    margin-right: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-80-pc {
    margin-right: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-85-pc {
    margin-right: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-90-pc {
    margin-right: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-95-pc {
    margin-right: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-100-pc {
    margin-right: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-105-pc {
    margin-right: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-110-pc {
    margin-right: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-115-pc {
    margin-right: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-120-pc {
    margin-right: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-125-pc {
    margin-right: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-130-pc {
    margin-right: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-135-pc {
    margin-right: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-140-pc {
    margin-right: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-145-pc {
    margin-right: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-150-pc {
    margin-right: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-155-pc {
    margin-right: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-160-pc {
    margin-right: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-165-pc {
    margin-right: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-170-pc {
    margin-right: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-175-pc {
    margin-right: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-180-pc {
    margin-right: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-185-pc {
    margin-right: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-190-pc {
    margin-right: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-195-pc {
    margin-right: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mr-200-pc {
    margin-right: 200px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-0-pc {
    margin-bottom: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-5-pc {
    margin-bottom: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-10-pc {
    margin-bottom: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-15-pc {
    margin-bottom: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-20-pc {
    margin-bottom: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-25-pc {
    margin-bottom: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-30-pc {
    margin-bottom: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-35-pc {
    margin-bottom: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-40-pc {
    margin-bottom: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-45-pc {
    margin-bottom: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-50-pc {
    margin-bottom: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-55-pc {
    margin-bottom: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-60-pc {
    margin-bottom: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-65-pc {
    margin-bottom: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-70-pc {
    margin-bottom: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-75-pc {
    margin-bottom: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-80-pc {
    margin-bottom: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-85-pc {
    margin-bottom: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-90-pc {
    margin-bottom: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-95-pc {
    margin-bottom: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-100-pc {
    margin-bottom: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-105-pc {
    margin-bottom: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-110-pc {
    margin-bottom: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-115-pc {
    margin-bottom: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-120-pc {
    margin-bottom: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-125-pc {
    margin-bottom: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-130-pc {
    margin-bottom: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-135-pc {
    margin-bottom: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-140-pc {
    margin-bottom: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-145-pc {
    margin-bottom: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-150-pc {
    margin-bottom: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-155-pc {
    margin-bottom: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-160-pc {
    margin-bottom: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-165-pc {
    margin-bottom: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-170-pc {
    margin-bottom: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-175-pc {
    margin-bottom: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-180-pc {
    margin-bottom: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-185-pc {
    margin-bottom: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-190-pc {
    margin-bottom: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-195-pc {
    margin-bottom: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-mb-200-pc {
    margin-bottom: 200px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-0-pc {
    margin-left: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-5-pc {
    margin-left: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-10-pc {
    margin-left: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-15-pc {
    margin-left: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-20-pc {
    margin-left: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-25-pc {
    margin-left: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-30-pc {
    margin-left: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-35-pc {
    margin-left: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-40-pc {
    margin-left: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-45-pc {
    margin-left: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-50-pc {
    margin-left: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-55-pc {
    margin-left: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-60-pc {
    margin-left: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-65-pc {
    margin-left: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-70-pc {
    margin-left: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-75-pc {
    margin-left: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-80-pc {
    margin-left: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-85-pc {
    margin-left: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-90-pc {
    margin-left: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-95-pc {
    margin-left: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-100-pc {
    margin-left: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-105-pc {
    margin-left: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-110-pc {
    margin-left: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-115-pc {
    margin-left: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-120-pc {
    margin-left: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-125-pc {
    margin-left: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-130-pc {
    margin-left: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-135-pc {
    margin-left: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-140-pc {
    margin-left: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-145-pc {
    margin-left: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-150-pc {
    margin-left: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-155-pc {
    margin-left: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-160-pc {
    margin-left: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-165-pc {
    margin-left: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-170-pc {
    margin-left: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-175-pc {
    margin-left: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-180-pc {
    margin-left: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-185-pc {
    margin-left: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-190-pc {
    margin-left: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-195-pc {
    margin-left: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-ml-200-pc {
    margin-left: 200px !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-0-sp {
    margin-top: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-5-sp {
    margin-top: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-10-sp {
    margin-top: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-15-sp {
    margin-top: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-20-sp {
    margin-top: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-25-sp {
    margin-top: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-30-sp {
    margin-top: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-35-sp {
    margin-top: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-40-sp {
    margin-top: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-45-sp {
    margin-top: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-50-sp {
    margin-top: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-55-sp {
    margin-top: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-60-sp {
    margin-top: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-65-sp {
    margin-top: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-70-sp {
    margin-top: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-75-sp {
    margin-top: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-80-sp {
    margin-top: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-85-sp {
    margin-top: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-90-sp {
    margin-top: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-95-sp {
    margin-top: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-100-sp {
    margin-top: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-105-sp {
    margin-top: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-110-sp {
    margin-top: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-115-sp {
    margin-top: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-120-sp {
    margin-top: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-125-sp {
    margin-top: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-130-sp {
    margin-top: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-135-sp {
    margin-top: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-140-sp {
    margin-top: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-145-sp {
    margin-top: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-150-sp {
    margin-top: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-155-sp {
    margin-top: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-160-sp {
    margin-top: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-165-sp {
    margin-top: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-170-sp {
    margin-top: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-175-sp {
    margin-top: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-180-sp {
    margin-top: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-185-sp {
    margin-top: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-190-sp {
    margin-top: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-195-sp {
    margin-top: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mt-200-sp {
    margin-top: 51.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-0-sp {
    margin-right: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-5-sp {
    margin-right: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-10-sp {
    margin-right: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-15-sp {
    margin-right: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-20-sp {
    margin-right: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-25-sp {
    margin-right: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-30-sp {
    margin-right: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-35-sp {
    margin-right: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-40-sp {
    margin-right: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-45-sp {
    margin-right: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-50-sp {
    margin-right: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-55-sp {
    margin-right: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-60-sp {
    margin-right: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-65-sp {
    margin-right: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-70-sp {
    margin-right: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-75-sp {
    margin-right: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-80-sp {
    margin-right: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-85-sp {
    margin-right: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-90-sp {
    margin-right: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-95-sp {
    margin-right: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-100-sp {
    margin-right: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-105-sp {
    margin-right: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-110-sp {
    margin-right: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-115-sp {
    margin-right: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-120-sp {
    margin-right: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-125-sp {
    margin-right: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-130-sp {
    margin-right: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-135-sp {
    margin-right: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-140-sp {
    margin-right: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-145-sp {
    margin-right: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-150-sp {
    margin-right: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-155-sp {
    margin-right: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-160-sp {
    margin-right: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-165-sp {
    margin-right: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-170-sp {
    margin-right: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-175-sp {
    margin-right: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-180-sp {
    margin-right: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-185-sp {
    margin-right: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-190-sp {
    margin-right: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-195-sp {
    margin-right: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mr-200-sp {
    margin-right: 51.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-0-sp {
    margin-bottom: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-5-sp {
    margin-bottom: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-10-sp {
    margin-bottom: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-15-sp {
    margin-bottom: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-20-sp {
    margin-bottom: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-25-sp {
    margin-bottom: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-30-sp {
    margin-bottom: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-35-sp {
    margin-bottom: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-40-sp {
    margin-bottom: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-45-sp {
    margin-bottom: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-50-sp {
    margin-bottom: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-55-sp {
    margin-bottom: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-60-sp {
    margin-bottom: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-65-sp {
    margin-bottom: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-70-sp {
    margin-bottom: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-75-sp {
    margin-bottom: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-80-sp {
    margin-bottom: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-85-sp {
    margin-bottom: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-90-sp {
    margin-bottom: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-95-sp {
    margin-bottom: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-100-sp {
    margin-bottom: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-105-sp {
    margin-bottom: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-110-sp {
    margin-bottom: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-115-sp {
    margin-bottom: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-120-sp {
    margin-bottom: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-125-sp {
    margin-bottom: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-130-sp {
    margin-bottom: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-135-sp {
    margin-bottom: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-140-sp {
    margin-bottom: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-145-sp {
    margin-bottom: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-150-sp {
    margin-bottom: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-155-sp {
    margin-bottom: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-160-sp {
    margin-bottom: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-165-sp {
    margin-bottom: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-170-sp {
    margin-bottom: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-175-sp {
    margin-bottom: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-180-sp {
    margin-bottom: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-185-sp {
    margin-bottom: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-190-sp {
    margin-bottom: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-195-sp {
    margin-bottom: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-mb-200-sp {
    margin-bottom: 51.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-0-sp {
    margin-left: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-5-sp {
    margin-left: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-10-sp {
    margin-left: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-15-sp {
    margin-left: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-20-sp {
    margin-left: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-25-sp {
    margin-left: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-30-sp {
    margin-left: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-35-sp {
    margin-left: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-40-sp {
    margin-left: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-45-sp {
    margin-left: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-50-sp {
    margin-left: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-55-sp {
    margin-left: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-60-sp {
    margin-left: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-65-sp {
    margin-left: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-70-sp {
    margin-left: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-75-sp {
    margin-left: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-80-sp {
    margin-left: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-85-sp {
    margin-left: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-90-sp {
    margin-left: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-95-sp {
    margin-left: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-100-sp {
    margin-left: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-105-sp {
    margin-left: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-110-sp {
    margin-left: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-115-sp {
    margin-left: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-120-sp {
    margin-left: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-125-sp {
    margin-left: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-130-sp {
    margin-left: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-135-sp {
    margin-left: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-140-sp {
    margin-left: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-145-sp {
    margin-left: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-150-sp {
    margin-left: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-155-sp {
    margin-left: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-160-sp {
    margin-left: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-165-sp {
    margin-left: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-170-sp {
    margin-left: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-175-sp {
    margin-left: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-180-sp {
    margin-left: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-185-sp {
    margin-left: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-190-sp {
    margin-left: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-195-sp {
    margin-left: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-ml-200-sp {
    margin-left: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-0 {
    padding-top: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-0 {
    padding-top: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-5 {
    padding-top: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-5 {
    padding-top: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-10 {
    padding-top: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-10 {
    padding-top: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-15 {
    padding-top: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-15 {
    padding-top: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-20 {
    padding-top: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-20 {
    padding-top: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-25 {
    padding-top: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-25 {
    padding-top: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-30 {
    padding-top: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-30 {
    padding-top: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-35 {
    padding-top: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-35 {
    padding-top: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-40 {
    padding-top: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-40 {
    padding-top: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-45 {
    padding-top: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-45 {
    padding-top: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-50 {
    padding-top: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-50 {
    padding-top: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-55 {
    padding-top: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-55 {
    padding-top: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-60 {
    padding-top: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-60 {
    padding-top: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-65 {
    padding-top: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-65 {
    padding-top: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-70 {
    padding-top: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-70 {
    padding-top: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-75 {
    padding-top: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-75 {
    padding-top: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-80 {
    padding-top: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-80 {
    padding-top: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-85 {
    padding-top: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-85 {
    padding-top: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-90 {
    padding-top: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-90 {
    padding-top: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-95 {
    padding-top: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-95 {
    padding-top: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-100 {
    padding-top: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-100 {
    padding-top: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-105 {
    padding-top: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-105 {
    padding-top: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-110 {
    padding-top: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-110 {
    padding-top: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-115 {
    padding-top: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-115 {
    padding-top: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-120 {
    padding-top: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-120 {
    padding-top: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-125 {
    padding-top: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-125 {
    padding-top: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-130 {
    padding-top: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-130 {
    padding-top: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-135 {
    padding-top: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-135 {
    padding-top: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-140 {
    padding-top: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-140 {
    padding-top: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-145 {
    padding-top: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-145 {
    padding-top: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-150 {
    padding-top: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-150 {
    padding-top: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-155 {
    padding-top: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-155 {
    padding-top: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-160 {
    padding-top: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-160 {
    padding-top: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-165 {
    padding-top: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-165 {
    padding-top: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-170 {
    padding-top: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-170 {
    padding-top: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-175 {
    padding-top: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-175 {
    padding-top: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-180 {
    padding-top: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-180 {
    padding-top: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-185 {
    padding-top: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-185 {
    padding-top: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-190 {
    padding-top: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-190 {
    padding-top: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-195 {
    padding-top: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-195 {
    padding-top: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-200 {
    padding-top: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pt-200 {
    padding-top: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-0 {
    padding-right: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-0 {
    padding-right: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-5 {
    padding-right: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-5 {
    padding-right: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-10 {
    padding-right: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-10 {
    padding-right: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-15 {
    padding-right: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-15 {
    padding-right: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-20 {
    padding-right: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-20 {
    padding-right: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-25 {
    padding-right: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-25 {
    padding-right: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-30 {
    padding-right: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-30 {
    padding-right: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-35 {
    padding-right: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-35 {
    padding-right: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-40 {
    padding-right: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-40 {
    padding-right: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-45 {
    padding-right: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-45 {
    padding-right: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-50 {
    padding-right: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-50 {
    padding-right: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-55 {
    padding-right: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-55 {
    padding-right: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-60 {
    padding-right: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-60 {
    padding-right: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-65 {
    padding-right: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-65 {
    padding-right: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-70 {
    padding-right: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-70 {
    padding-right: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-75 {
    padding-right: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-75 {
    padding-right: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-80 {
    padding-right: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-80 {
    padding-right: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-85 {
    padding-right: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-85 {
    padding-right: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-90 {
    padding-right: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-90 {
    padding-right: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-95 {
    padding-right: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-95 {
    padding-right: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-100 {
    padding-right: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-100 {
    padding-right: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-105 {
    padding-right: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-105 {
    padding-right: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-110 {
    padding-right: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-110 {
    padding-right: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-115 {
    padding-right: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-115 {
    padding-right: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-120 {
    padding-right: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-120 {
    padding-right: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-125 {
    padding-right: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-125 {
    padding-right: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-130 {
    padding-right: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-130 {
    padding-right: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-135 {
    padding-right: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-135 {
    padding-right: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-140 {
    padding-right: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-140 {
    padding-right: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-145 {
    padding-right: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-145 {
    padding-right: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-150 {
    padding-right: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-150 {
    padding-right: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-155 {
    padding-right: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-155 {
    padding-right: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-160 {
    padding-right: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-160 {
    padding-right: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-165 {
    padding-right: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-165 {
    padding-right: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-170 {
    padding-right: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-170 {
    padding-right: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-175 {
    padding-right: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-175 {
    padding-right: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-180 {
    padding-right: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-180 {
    padding-right: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-185 {
    padding-right: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-185 {
    padding-right: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-190 {
    padding-right: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-190 {
    padding-right: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-195 {
    padding-right: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-195 {
    padding-right: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-200 {
    padding-right: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pr-200 {
    padding-right: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-0 {
    padding-bottom: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-0 {
    padding-bottom: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-5 {
    padding-bottom: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-5 {
    padding-bottom: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-10 {
    padding-bottom: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-10 {
    padding-bottom: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-15 {
    padding-bottom: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-15 {
    padding-bottom: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-20 {
    padding-bottom: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-20 {
    padding-bottom: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-25 {
    padding-bottom: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-25 {
    padding-bottom: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-30 {
    padding-bottom: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-30 {
    padding-bottom: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-35 {
    padding-bottom: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-35 {
    padding-bottom: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-40 {
    padding-bottom: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-40 {
    padding-bottom: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-45 {
    padding-bottom: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-45 {
    padding-bottom: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-50 {
    padding-bottom: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-50 {
    padding-bottom: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-55 {
    padding-bottom: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-55 {
    padding-bottom: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-60 {
    padding-bottom: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-60 {
    padding-bottom: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-65 {
    padding-bottom: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-65 {
    padding-bottom: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-70 {
    padding-bottom: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-70 {
    padding-bottom: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-75 {
    padding-bottom: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-75 {
    padding-bottom: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-80 {
    padding-bottom: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-80 {
    padding-bottom: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-85 {
    padding-bottom: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-85 {
    padding-bottom: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-90 {
    padding-bottom: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-90 {
    padding-bottom: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-95 {
    padding-bottom: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-95 {
    padding-bottom: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-100 {
    padding-bottom: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-100 {
    padding-bottom: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-105 {
    padding-bottom: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-105 {
    padding-bottom: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-110 {
    padding-bottom: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-110 {
    padding-bottom: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-115 {
    padding-bottom: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-115 {
    padding-bottom: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-120 {
    padding-bottom: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-120 {
    padding-bottom: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-125 {
    padding-bottom: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-125 {
    padding-bottom: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-130 {
    padding-bottom: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-130 {
    padding-bottom: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-135 {
    padding-bottom: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-135 {
    padding-bottom: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-140 {
    padding-bottom: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-140 {
    padding-bottom: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-145 {
    padding-bottom: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-145 {
    padding-bottom: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-150 {
    padding-bottom: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-150 {
    padding-bottom: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-155 {
    padding-bottom: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-155 {
    padding-bottom: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-160 {
    padding-bottom: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-160 {
    padding-bottom: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-165 {
    padding-bottom: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-165 {
    padding-bottom: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-170 {
    padding-bottom: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-170 {
    padding-bottom: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-175 {
    padding-bottom: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-175 {
    padding-bottom: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-180 {
    padding-bottom: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-180 {
    padding-bottom: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-185 {
    padding-bottom: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-185 {
    padding-bottom: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-190 {
    padding-bottom: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-190 {
    padding-bottom: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-195 {
    padding-bottom: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-195 {
    padding-bottom: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-200 {
    padding-bottom: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pb-200 {
    padding-bottom: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-0 {
    padding-left: 0px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-0 {
    padding-left: 0vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-5 {
    padding-left: 5px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-5 {
    padding-left: 1.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-10 {
    padding-left: 10px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-10 {
    padding-left: 2.5641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-15 {
    padding-left: 15px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-15 {
    padding-left: 3.8461538462vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-20 {
    padding-left: 20px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-20 {
    padding-left: 5.1282051282vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-25 {
    padding-left: 25px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-25 {
    padding-left: 6.4102564103vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-30 {
    padding-left: 30px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-30 {
    padding-left: 7.6923076923vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-35 {
    padding-left: 35px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-35 {
    padding-left: 8.9743589744vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-40 {
    padding-left: 40px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-40 {
    padding-left: 10.2564102564vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-45 {
    padding-left: 45px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-45 {
    padding-left: 11.5384615385vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-50 {
    padding-left: 50px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-50 {
    padding-left: 12.8205128205vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-55 {
    padding-left: 55px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-55 {
    padding-left: 14.1025641026vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-60 {
    padding-left: 60px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-60 {
    padding-left: 15.3846153846vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-65 {
    padding-left: 65px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-65 {
    padding-left: 16.6666666667vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-70 {
    padding-left: 70px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-70 {
    padding-left: 17.9487179487vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-75 {
    padding-left: 75px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-75 {
    padding-left: 19.2307692308vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-80 {
    padding-left: 80px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-80 {
    padding-left: 20.5128205128vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-85 {
    padding-left: 85px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-85 {
    padding-left: 21.7948717949vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-90 {
    padding-left: 90px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-90 {
    padding-left: 23.0769230769vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-95 {
    padding-left: 95px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-95 {
    padding-left: 24.358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-100 {
    padding-left: 100px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-100 {
    padding-left: 25.641025641vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-105 {
    padding-left: 105px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-105 {
    padding-left: 26.9230769231vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-110 {
    padding-left: 110px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-110 {
    padding-left: 28.2051282051vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-115 {
    padding-left: 115px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-115 {
    padding-left: 29.4871794872vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-120 {
    padding-left: 120px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-120 {
    padding-left: 30.7692307692vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-125 {
    padding-left: 125px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-125 {
    padding-left: 32.0512820513vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-130 {
    padding-left: 130px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-130 {
    padding-left: 33.3333333333vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-135 {
    padding-left: 135px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-135 {
    padding-left: 34.6153846154vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-140 {
    padding-left: 140px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-140 {
    padding-left: 35.8974358974vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-145 {
    padding-left: 145px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-145 {
    padding-left: 37.1794871795vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-150 {
    padding-left: 150px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-150 {
    padding-left: 38.4615384615vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-155 {
    padding-left: 155px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-155 {
    padding-left: 39.7435897436vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-160 {
    padding-left: 160px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-160 {
    padding-left: 41.0256410256vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-165 {
    padding-left: 165px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-165 {
    padding-left: 42.3076923077vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-170 {
    padding-left: 170px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-170 {
    padding-left: 43.5897435897vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-175 {
    padding-left: 175px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-175 {
    padding-left: 44.8717948718vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-180 {
    padding-left: 180px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-180 {
    padding-left: 46.1538461538vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-185 {
    padding-left: 185px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-185 {
    padding-left: 47.4358974359vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-190 {
    padding-left: 190px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-190 {
    padding-left: 48.7179487179vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-195 {
    padding-left: 195px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-195 {
    padding-left: 50vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-200 {
    padding-left: 200px !important;
  }
}
@media print, screen and (max-width: 780px) {
  .u-pl-200 {
    padding-left: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-0-pc {
    padding-top: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-5-pc {
    padding-top: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-10-pc {
    padding-top: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-15-pc {
    padding-top: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-20-pc {
    padding-top: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-25-pc {
    padding-top: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-30-pc {
    padding-top: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-35-pc {
    padding-top: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-40-pc {
    padding-top: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-45-pc {
    padding-top: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-50-pc {
    padding-top: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-55-pc {
    padding-top: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-60-pc {
    padding-top: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-65-pc {
    padding-top: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-70-pc {
    padding-top: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-75-pc {
    padding-top: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-80-pc {
    padding-top: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-85-pc {
    padding-top: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-90-pc {
    padding-top: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-95-pc {
    padding-top: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-100-pc {
    padding-top: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-105-pc {
    padding-top: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-110-pc {
    padding-top: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-115-pc {
    padding-top: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-120-pc {
    padding-top: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-125-pc {
    padding-top: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-130-pc {
    padding-top: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-135-pc {
    padding-top: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-140-pc {
    padding-top: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-145-pc {
    padding-top: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-150-pc {
    padding-top: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-155-pc {
    padding-top: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-160-pc {
    padding-top: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-165-pc {
    padding-top: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-170-pc {
    padding-top: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-175-pc {
    padding-top: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-180-pc {
    padding-top: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-185-pc {
    padding-top: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-190-pc {
    padding-top: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-195-pc {
    padding-top: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pt-200-pc {
    padding-top: 200px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-0-pc {
    padding-right: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-5-pc {
    padding-right: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-10-pc {
    padding-right: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-15-pc {
    padding-right: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-20-pc {
    padding-right: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-25-pc {
    padding-right: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-30-pc {
    padding-right: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-35-pc {
    padding-right: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-40-pc {
    padding-right: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-45-pc {
    padding-right: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-50-pc {
    padding-right: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-55-pc {
    padding-right: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-60-pc {
    padding-right: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-65-pc {
    padding-right: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-70-pc {
    padding-right: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-75-pc {
    padding-right: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-80-pc {
    padding-right: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-85-pc {
    padding-right: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-90-pc {
    padding-right: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-95-pc {
    padding-right: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-100-pc {
    padding-right: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-105-pc {
    padding-right: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-110-pc {
    padding-right: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-115-pc {
    padding-right: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-120-pc {
    padding-right: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-125-pc {
    padding-right: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-130-pc {
    padding-right: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-135-pc {
    padding-right: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-140-pc {
    padding-right: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-145-pc {
    padding-right: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-150-pc {
    padding-right: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-155-pc {
    padding-right: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-160-pc {
    padding-right: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-165-pc {
    padding-right: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-170-pc {
    padding-right: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-175-pc {
    padding-right: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-180-pc {
    padding-right: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-185-pc {
    padding-right: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-190-pc {
    padding-right: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-195-pc {
    padding-right: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pr-200-pc {
    padding-right: 200px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-0-pc {
    padding-bottom: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-5-pc {
    padding-bottom: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-10-pc {
    padding-bottom: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-15-pc {
    padding-bottom: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-20-pc {
    padding-bottom: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-25-pc {
    padding-bottom: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-30-pc {
    padding-bottom: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-35-pc {
    padding-bottom: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-40-pc {
    padding-bottom: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-45-pc {
    padding-bottom: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-50-pc {
    padding-bottom: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-55-pc {
    padding-bottom: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-60-pc {
    padding-bottom: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-65-pc {
    padding-bottom: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-70-pc {
    padding-bottom: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-75-pc {
    padding-bottom: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-80-pc {
    padding-bottom: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-85-pc {
    padding-bottom: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-90-pc {
    padding-bottom: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-95-pc {
    padding-bottom: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-100-pc {
    padding-bottom: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-105-pc {
    padding-bottom: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-110-pc {
    padding-bottom: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-115-pc {
    padding-bottom: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-120-pc {
    padding-bottom: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-125-pc {
    padding-bottom: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-130-pc {
    padding-bottom: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-135-pc {
    padding-bottom: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-140-pc {
    padding-bottom: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-145-pc {
    padding-bottom: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-150-pc {
    padding-bottom: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-155-pc {
    padding-bottom: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-160-pc {
    padding-bottom: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-165-pc {
    padding-bottom: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-170-pc {
    padding-bottom: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-175-pc {
    padding-bottom: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-180-pc {
    padding-bottom: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-185-pc {
    padding-bottom: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-190-pc {
    padding-bottom: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-195-pc {
    padding-bottom: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pb-200-pc {
    padding-bottom: 200px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-0-pc {
    padding-left: 0px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-5-pc {
    padding-left: 5px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-10-pc {
    padding-left: 10px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-15-pc {
    padding-left: 15px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-20-pc {
    padding-left: 20px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-25-pc {
    padding-left: 25px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-30-pc {
    padding-left: 30px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-35-pc {
    padding-left: 35px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-40-pc {
    padding-left: 40px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-45-pc {
    padding-left: 45px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-50-pc {
    padding-left: 50px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-55-pc {
    padding-left: 55px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-60-pc {
    padding-left: 60px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-65-pc {
    padding-left: 65px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-70-pc {
    padding-left: 70px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-75-pc {
    padding-left: 75px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-80-pc {
    padding-left: 80px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-85-pc {
    padding-left: 85px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-90-pc {
    padding-left: 90px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-95-pc {
    padding-left: 95px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-100-pc {
    padding-left: 100px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-105-pc {
    padding-left: 105px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-110-pc {
    padding-left: 110px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-115-pc {
    padding-left: 115px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-120-pc {
    padding-left: 120px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-125-pc {
    padding-left: 125px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-130-pc {
    padding-left: 130px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-135-pc {
    padding-left: 135px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-140-pc {
    padding-left: 140px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-145-pc {
    padding-left: 145px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-150-pc {
    padding-left: 150px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-155-pc {
    padding-left: 155px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-160-pc {
    padding-left: 160px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-165-pc {
    padding-left: 165px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-170-pc {
    padding-left: 170px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-175-pc {
    padding-left: 175px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-180-pc {
    padding-left: 180px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-185-pc {
    padding-left: 185px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-190-pc {
    padding-left: 190px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-195-pc {
    padding-left: 195px !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-pl-200-pc {
    padding-left: 200px !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-0-sp {
    padding-top: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-5-sp {
    padding-top: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-10-sp {
    padding-top: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-15-sp {
    padding-top: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-20-sp {
    padding-top: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-25-sp {
    padding-top: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-30-sp {
    padding-top: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-35-sp {
    padding-top: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-40-sp {
    padding-top: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-45-sp {
    padding-top: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-50-sp {
    padding-top: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-55-sp {
    padding-top: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-60-sp {
    padding-top: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-65-sp {
    padding-top: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-70-sp {
    padding-top: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-75-sp {
    padding-top: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-80-sp {
    padding-top: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-85-sp {
    padding-top: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-90-sp {
    padding-top: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-95-sp {
    padding-top: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-100-sp {
    padding-top: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-105-sp {
    padding-top: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-110-sp {
    padding-top: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-115-sp {
    padding-top: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-120-sp {
    padding-top: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-125-sp {
    padding-top: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-130-sp {
    padding-top: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-135-sp {
    padding-top: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-140-sp {
    padding-top: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-145-sp {
    padding-top: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-150-sp {
    padding-top: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-155-sp {
    padding-top: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-160-sp {
    padding-top: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-165-sp {
    padding-top: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-170-sp {
    padding-top: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-175-sp {
    padding-top: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-180-sp {
    padding-top: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-185-sp {
    padding-top: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-190-sp {
    padding-top: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-195-sp {
    padding-top: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pt-200-sp {
    padding-top: 51.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-0-sp {
    padding-right: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-5-sp {
    padding-right: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-10-sp {
    padding-right: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-15-sp {
    padding-right: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-20-sp {
    padding-right: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-25-sp {
    padding-right: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-30-sp {
    padding-right: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-35-sp {
    padding-right: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-40-sp {
    padding-right: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-45-sp {
    padding-right: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-50-sp {
    padding-right: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-55-sp {
    padding-right: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-60-sp {
    padding-right: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-65-sp {
    padding-right: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-70-sp {
    padding-right: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-75-sp {
    padding-right: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-80-sp {
    padding-right: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-85-sp {
    padding-right: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-90-sp {
    padding-right: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-95-sp {
    padding-right: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-100-sp {
    padding-right: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-105-sp {
    padding-right: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-110-sp {
    padding-right: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-115-sp {
    padding-right: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-120-sp {
    padding-right: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-125-sp {
    padding-right: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-130-sp {
    padding-right: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-135-sp {
    padding-right: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-140-sp {
    padding-right: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-145-sp {
    padding-right: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-150-sp {
    padding-right: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-155-sp {
    padding-right: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-160-sp {
    padding-right: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-165-sp {
    padding-right: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-170-sp {
    padding-right: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-175-sp {
    padding-right: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-180-sp {
    padding-right: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-185-sp {
    padding-right: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-190-sp {
    padding-right: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-195-sp {
    padding-right: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pr-200-sp {
    padding-right: 51.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-0-sp {
    padding-bottom: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-5-sp {
    padding-bottom: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-10-sp {
    padding-bottom: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-15-sp {
    padding-bottom: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-20-sp {
    padding-bottom: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-25-sp {
    padding-bottom: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-30-sp {
    padding-bottom: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-35-sp {
    padding-bottom: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-40-sp {
    padding-bottom: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-45-sp {
    padding-bottom: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-50-sp {
    padding-bottom: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-55-sp {
    padding-bottom: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-60-sp {
    padding-bottom: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-65-sp {
    padding-bottom: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-70-sp {
    padding-bottom: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-75-sp {
    padding-bottom: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-80-sp {
    padding-bottom: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-85-sp {
    padding-bottom: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-90-sp {
    padding-bottom: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-95-sp {
    padding-bottom: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-100-sp {
    padding-bottom: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-105-sp {
    padding-bottom: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-110-sp {
    padding-bottom: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-115-sp {
    padding-bottom: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-120-sp {
    padding-bottom: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-125-sp {
    padding-bottom: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-130-sp {
    padding-bottom: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-135-sp {
    padding-bottom: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-140-sp {
    padding-bottom: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-145-sp {
    padding-bottom: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-150-sp {
    padding-bottom: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-155-sp {
    padding-bottom: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-160-sp {
    padding-bottom: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-165-sp {
    padding-bottom: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-170-sp {
    padding-bottom: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-175-sp {
    padding-bottom: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-180-sp {
    padding-bottom: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-185-sp {
    padding-bottom: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-190-sp {
    padding-bottom: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-195-sp {
    padding-bottom: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pb-200-sp {
    padding-bottom: 51.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-0-sp {
    padding-left: 0vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-5-sp {
    padding-left: 1.2820512821vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-10-sp {
    padding-left: 2.5641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-15-sp {
    padding-left: 3.8461538462vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-20-sp {
    padding-left: 5.1282051282vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-25-sp {
    padding-left: 6.4102564103vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-30-sp {
    padding-left: 7.6923076923vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-35-sp {
    padding-left: 8.9743589744vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-40-sp {
    padding-left: 10.2564102564vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-45-sp {
    padding-left: 11.5384615385vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-50-sp {
    padding-left: 12.8205128205vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-55-sp {
    padding-left: 14.1025641026vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-60-sp {
    padding-left: 15.3846153846vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-65-sp {
    padding-left: 16.6666666667vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-70-sp {
    padding-left: 17.9487179487vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-75-sp {
    padding-left: 19.2307692308vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-80-sp {
    padding-left: 20.5128205128vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-85-sp {
    padding-left: 21.7948717949vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-90-sp {
    padding-left: 23.0769230769vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-95-sp {
    padding-left: 24.358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-100-sp {
    padding-left: 25.641025641vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-105-sp {
    padding-left: 26.9230769231vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-110-sp {
    padding-left: 28.2051282051vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-115-sp {
    padding-left: 29.4871794872vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-120-sp {
    padding-left: 30.7692307692vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-125-sp {
    padding-left: 32.0512820513vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-130-sp {
    padding-left: 33.3333333333vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-135-sp {
    padding-left: 34.6153846154vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-140-sp {
    padding-left: 35.8974358974vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-145-sp {
    padding-left: 37.1794871795vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-150-sp {
    padding-left: 38.4615384615vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-155-sp {
    padding-left: 39.7435897436vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-160-sp {
    padding-left: 41.0256410256vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-165-sp {
    padding-left: 42.3076923077vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-170-sp {
    padding-left: 43.5897435897vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-175-sp {
    padding-left: 44.8717948718vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-180-sp {
    padding-left: 46.1538461538vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-185-sp {
    padding-left: 47.4358974359vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-190-sp {
    padding-left: 48.7179487179vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-195-sp {
    padding-left: 50vw !important;
  }
}

@media print, screen and (max-width: 780px) {
  .u-pl-200-sp {
    padding-left: 51.2820512821vw !important;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-1 {
    font-size: 1px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-1 {
    font-size: 0.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-2 {
    font-size: 2px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-2 {
    font-size: 0.5128205128vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-3 {
    font-size: 3px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-3 {
    font-size: 0.7692307692vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-4 {
    font-size: 4px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-4 {
    font-size: 1.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-5 {
    font-size: 5px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-5 {
    font-size: 1.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-6 {
    font-size: 6px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-6 {
    font-size: 1.5384615385vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-7 {
    font-size: 7px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-7 {
    font-size: 1.7948717949vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-8 {
    font-size: 8px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-8 {
    font-size: 2.0512820513vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-9 {
    font-size: 9px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-9 {
    font-size: 2.3076923077vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-10 {
    font-size: 10px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-10 {
    font-size: 2.5641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-11 {
    font-size: 11px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-11 {
    font-size: 2.8205128205vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-12 {
    font-size: 12px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-12 {
    font-size: 3.0769230769vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-13 {
    font-size: 13px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-13 {
    font-size: 3.3333333333vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-14 {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-14 {
    font-size: 3.5897435897vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-15 {
    font-size: 15px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-15 {
    font-size: 3.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-16 {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-16 {
    font-size: 4.1025641026vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-17 {
    font-size: 17px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-17 {
    font-size: 4.358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-18 {
    font-size: 18px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-18 {
    font-size: 4.6153846154vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-19 {
    font-size: 19px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-19 {
    font-size: 4.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-20 {
    font-size: 20px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-20 {
    font-size: 5.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-21 {
    font-size: 21px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-21 {
    font-size: 5.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-22 {
    font-size: 22px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-22 {
    font-size: 5.641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-23 {
    font-size: 23px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-23 {
    font-size: 5.8974358974vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-24 {
    font-size: 24px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-24 {
    font-size: 6.1538461538vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-25 {
    font-size: 25px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-25 {
    font-size: 6.4102564103vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-26 {
    font-size: 26px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-26 {
    font-size: 6.6666666667vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-27 {
    font-size: 27px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-27 {
    font-size: 6.9230769231vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-28 {
    font-size: 28px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-28 {
    font-size: 7.1794871795vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-29 {
    font-size: 29px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-29 {
    font-size: 7.4358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-30 {
    font-size: 30px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-30 {
    font-size: 7.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-31 {
    font-size: 31px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-31 {
    font-size: 7.9487179487vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-32 {
    font-size: 32px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-32 {
    font-size: 8.2051282051vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-33 {
    font-size: 33px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-33 {
    font-size: 8.4615384615vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-34 {
    font-size: 34px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-34 {
    font-size: 8.7179487179vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-35 {
    font-size: 35px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-35 {
    font-size: 8.9743589744vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-36 {
    font-size: 36px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-36 {
    font-size: 9.2307692308vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-37 {
    font-size: 37px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-37 {
    font-size: 9.4871794872vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-38 {
    font-size: 38px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-38 {
    font-size: 9.7435897436vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-39 {
    font-size: 39px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-39 {
    font-size: 10vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-40 {
    font-size: 40px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-40 {
    font-size: 10.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-41 {
    font-size: 41px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-41 {
    font-size: 10.5128205128vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-42 {
    font-size: 42px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-42 {
    font-size: 10.7692307692vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-43 {
    font-size: 43px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-43 {
    font-size: 11.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-44 {
    font-size: 44px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-44 {
    font-size: 11.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-45 {
    font-size: 45px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-45 {
    font-size: 11.5384615385vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-46 {
    font-size: 46px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-46 {
    font-size: 11.7948717949vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-47 {
    font-size: 47px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-47 {
    font-size: 12.0512820513vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-48 {
    font-size: 48px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-48 {
    font-size: 12.3076923077vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-49 {
    font-size: 49px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-49 {
    font-size: 12.5641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-50 {
    font-size: 50px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-50 {
    font-size: 12.8205128205vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-51 {
    font-size: 51px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-51 {
    font-size: 13.0769230769vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-52 {
    font-size: 52px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-52 {
    font-size: 13.3333333333vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-53 {
    font-size: 53px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-53 {
    font-size: 13.5897435897vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-54 {
    font-size: 54px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-54 {
    font-size: 13.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-55 {
    font-size: 55px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-55 {
    font-size: 14.1025641026vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-56 {
    font-size: 56px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-56 {
    font-size: 14.358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-57 {
    font-size: 57px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-57 {
    font-size: 14.6153846154vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-58 {
    font-size: 58px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-58 {
    font-size: 14.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-59 {
    font-size: 59px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-59 {
    font-size: 15.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-60 {
    font-size: 60px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-60 {
    font-size: 15.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-61 {
    font-size: 61px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-61 {
    font-size: 15.641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-62 {
    font-size: 62px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-62 {
    font-size: 15.8974358974vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-63 {
    font-size: 63px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-63 {
    font-size: 16.1538461538vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-64 {
    font-size: 64px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-64 {
    font-size: 16.4102564103vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-65 {
    font-size: 65px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-65 {
    font-size: 16.6666666667vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-66 {
    font-size: 66px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-66 {
    font-size: 16.9230769231vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-67 {
    font-size: 67px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-67 {
    font-size: 17.1794871795vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-68 {
    font-size: 68px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-68 {
    font-size: 17.4358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-69 {
    font-size: 69px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-69 {
    font-size: 17.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-70 {
    font-size: 70px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-70 {
    font-size: 17.9487179487vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-71 {
    font-size: 71px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-71 {
    font-size: 18.2051282051vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-72 {
    font-size: 72px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-72 {
    font-size: 18.4615384615vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-73 {
    font-size: 73px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-73 {
    font-size: 18.7179487179vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-74 {
    font-size: 74px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-74 {
    font-size: 18.9743589744vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-75 {
    font-size: 75px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-75 {
    font-size: 19.2307692308vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-76 {
    font-size: 76px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-76 {
    font-size: 19.4871794872vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-77 {
    font-size: 77px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-77 {
    font-size: 19.7435897436vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-78 {
    font-size: 78px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-78 {
    font-size: 20vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-79 {
    font-size: 79px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-79 {
    font-size: 20.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-80 {
    font-size: 80px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-80 {
    font-size: 20.5128205128vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-81 {
    font-size: 81px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-81 {
    font-size: 20.7692307692vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-82 {
    font-size: 82px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-82 {
    font-size: 21.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-83 {
    font-size: 83px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-83 {
    font-size: 21.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-84 {
    font-size: 84px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-84 {
    font-size: 21.5384615385vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-85 {
    font-size: 85px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-85 {
    font-size: 21.7948717949vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-86 {
    font-size: 86px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-86 {
    font-size: 22.0512820513vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-87 {
    font-size: 87px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-87 {
    font-size: 22.3076923077vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-88 {
    font-size: 88px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-88 {
    font-size: 22.5641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-89 {
    font-size: 89px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-89 {
    font-size: 22.8205128205vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-90 {
    font-size: 90px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-90 {
    font-size: 23.0769230769vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-91 {
    font-size: 91px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-91 {
    font-size: 23.3333333333vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-92 {
    font-size: 92px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-92 {
    font-size: 23.5897435897vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-93 {
    font-size: 93px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-93 {
    font-size: 23.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-94 {
    font-size: 94px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-94 {
    font-size: 24.1025641026vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-95 {
    font-size: 95px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-95 {
    font-size: 24.358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-96 {
    font-size: 96px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-96 {
    font-size: 24.6153846154vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-97 {
    font-size: 97px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-97 {
    font-size: 24.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-98 {
    font-size: 98px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-98 {
    font-size: 25.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-99 {
    font-size: 99px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-99 {
    font-size: 25.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-100 {
    font-size: 100px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-100 {
    font-size: 25.641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-101 {
    font-size: 101px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-101 {
    font-size: 25.8974358974vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-102 {
    font-size: 102px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-102 {
    font-size: 26.1538461538vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-103 {
    font-size: 103px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-103 {
    font-size: 26.4102564103vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-104 {
    font-size: 104px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-104 {
    font-size: 26.6666666667vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-105 {
    font-size: 105px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-105 {
    font-size: 26.9230769231vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-106 {
    font-size: 106px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-106 {
    font-size: 27.1794871795vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-107 {
    font-size: 107px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-107 {
    font-size: 27.4358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-108 {
    font-size: 108px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-108 {
    font-size: 27.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-109 {
    font-size: 109px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-109 {
    font-size: 27.9487179487vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-110 {
    font-size: 110px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-110 {
    font-size: 28.2051282051vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-111 {
    font-size: 111px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-111 {
    font-size: 28.4615384615vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-112 {
    font-size: 112px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-112 {
    font-size: 28.7179487179vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-113 {
    font-size: 113px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-113 {
    font-size: 28.9743589744vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-114 {
    font-size: 114px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-114 {
    font-size: 29.2307692308vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-115 {
    font-size: 115px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-115 {
    font-size: 29.4871794872vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-116 {
    font-size: 116px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-116 {
    font-size: 29.7435897436vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-117 {
    font-size: 117px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-117 {
    font-size: 30vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-118 {
    font-size: 118px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-118 {
    font-size: 30.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-119 {
    font-size: 119px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-119 {
    font-size: 30.5128205128vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-120 {
    font-size: 120px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-120 {
    font-size: 30.7692307692vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-121 {
    font-size: 121px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-121 {
    font-size: 31.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-122 {
    font-size: 122px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-122 {
    font-size: 31.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-123 {
    font-size: 123px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-123 {
    font-size: 31.5384615385vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-124 {
    font-size: 124px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-124 {
    font-size: 31.7948717949vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-125 {
    font-size: 125px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-125 {
    font-size: 32.0512820513vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-126 {
    font-size: 126px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-126 {
    font-size: 32.3076923077vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-127 {
    font-size: 127px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-127 {
    font-size: 32.5641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-128 {
    font-size: 128px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-128 {
    font-size: 32.8205128205vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-129 {
    font-size: 129px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-129 {
    font-size: 33.0769230769vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-130 {
    font-size: 130px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-130 {
    font-size: 33.3333333333vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-131 {
    font-size: 131px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-131 {
    font-size: 33.5897435897vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-132 {
    font-size: 132px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-132 {
    font-size: 33.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-133 {
    font-size: 133px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-133 {
    font-size: 34.1025641026vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-134 {
    font-size: 134px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-134 {
    font-size: 34.358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-135 {
    font-size: 135px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-135 {
    font-size: 34.6153846154vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-136 {
    font-size: 136px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-136 {
    font-size: 34.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-137 {
    font-size: 137px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-137 {
    font-size: 35.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-138 {
    font-size: 138px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-138 {
    font-size: 35.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-139 {
    font-size: 139px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-139 {
    font-size: 35.641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-140 {
    font-size: 140px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-140 {
    font-size: 35.8974358974vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-141 {
    font-size: 141px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-141 {
    font-size: 36.1538461538vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-142 {
    font-size: 142px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-142 {
    font-size: 36.4102564103vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-143 {
    font-size: 143px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-143 {
    font-size: 36.6666666667vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-144 {
    font-size: 144px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-144 {
    font-size: 36.9230769231vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-145 {
    font-size: 145px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-145 {
    font-size: 37.1794871795vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-146 {
    font-size: 146px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-146 {
    font-size: 37.4358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-147 {
    font-size: 147px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-147 {
    font-size: 37.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-148 {
    font-size: 148px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-148 {
    font-size: 37.9487179487vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-149 {
    font-size: 149px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-149 {
    font-size: 38.2051282051vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-150 {
    font-size: 150px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-150 {
    font-size: 38.4615384615vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-151 {
    font-size: 151px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-151 {
    font-size: 38.7179487179vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-152 {
    font-size: 152px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-152 {
    font-size: 38.9743589744vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-153 {
    font-size: 153px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-153 {
    font-size: 39.2307692308vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-154 {
    font-size: 154px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-154 {
    font-size: 39.4871794872vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-155 {
    font-size: 155px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-155 {
    font-size: 39.7435897436vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-156 {
    font-size: 156px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-156 {
    font-size: 40vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-157 {
    font-size: 157px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-157 {
    font-size: 40.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-158 {
    font-size: 158px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-158 {
    font-size: 40.5128205128vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-159 {
    font-size: 159px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-159 {
    font-size: 40.7692307692vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-160 {
    font-size: 160px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-160 {
    font-size: 41.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-161 {
    font-size: 161px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-161 {
    font-size: 41.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-162 {
    font-size: 162px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-162 {
    font-size: 41.5384615385vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-163 {
    font-size: 163px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-163 {
    font-size: 41.7948717949vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-164 {
    font-size: 164px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-164 {
    font-size: 42.0512820513vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-165 {
    font-size: 165px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-165 {
    font-size: 42.3076923077vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-166 {
    font-size: 166px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-166 {
    font-size: 42.5641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-167 {
    font-size: 167px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-167 {
    font-size: 42.8205128205vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-168 {
    font-size: 168px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-168 {
    font-size: 43.0769230769vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-169 {
    font-size: 169px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-169 {
    font-size: 43.3333333333vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-170 {
    font-size: 170px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-170 {
    font-size: 43.5897435897vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-171 {
    font-size: 171px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-171 {
    font-size: 43.8461538462vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-172 {
    font-size: 172px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-172 {
    font-size: 44.1025641026vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-173 {
    font-size: 173px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-173 {
    font-size: 44.358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-174 {
    font-size: 174px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-174 {
    font-size: 44.6153846154vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-175 {
    font-size: 175px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-175 {
    font-size: 44.8717948718vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-176 {
    font-size: 176px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-176 {
    font-size: 45.1282051282vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-177 {
    font-size: 177px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-177 {
    font-size: 45.3846153846vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-178 {
    font-size: 178px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-178 {
    font-size: 45.641025641vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-179 {
    font-size: 179px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-179 {
    font-size: 45.8974358974vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-180 {
    font-size: 180px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-180 {
    font-size: 46.1538461538vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-181 {
    font-size: 181px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-181 {
    font-size: 46.4102564103vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-182 {
    font-size: 182px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-182 {
    font-size: 46.6666666667vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-183 {
    font-size: 183px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-183 {
    font-size: 46.9230769231vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-184 {
    font-size: 184px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-184 {
    font-size: 47.1794871795vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-185 {
    font-size: 185px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-185 {
    font-size: 47.4358974359vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-186 {
    font-size: 186px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-186 {
    font-size: 47.6923076923vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-187 {
    font-size: 187px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-187 {
    font-size: 47.9487179487vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-188 {
    font-size: 188px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-188 {
    font-size: 48.2051282051vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-189 {
    font-size: 189px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-189 {
    font-size: 48.4615384615vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-190 {
    font-size: 190px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-190 {
    font-size: 48.7179487179vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-191 {
    font-size: 191px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-191 {
    font-size: 48.9743589744vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-192 {
    font-size: 192px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-192 {
    font-size: 49.2307692308vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-193 {
    font-size: 193px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-193 {
    font-size: 49.4871794872vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-194 {
    font-size: 194px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-194 {
    font-size: 49.7435897436vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-195 {
    font-size: 195px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-195 {
    font-size: 50vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-196 {
    font-size: 196px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-196 {
    font-size: 50.2564102564vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-197 {
    font-size: 197px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-197 {
    font-size: 50.5128205128vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-198 {
    font-size: 198px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-198 {
    font-size: 50.7692307692vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-199 {
    font-size: 199px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-199 {
    font-size: 51.0256410256vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-200 {
    font-size: 200px;
  }
}
@media print, screen and (max-width: 780px) {
  .u-font-200 {
    font-size: 51.2820512821vw;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-1-pc {
    font-size: 1px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-2-pc {
    font-size: 2px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-3-pc {
    font-size: 3px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-4-pc {
    font-size: 4px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-5-pc {
    font-size: 5px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-6-pc {
    font-size: 6px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-7-pc {
    font-size: 7px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-8-pc {
    font-size: 8px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-9-pc {
    font-size: 9px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-10-pc {
    font-size: 10px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-11-pc {
    font-size: 11px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-12-pc {
    font-size: 12px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-13-pc {
    font-size: 13px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-14-pc {
    font-size: 14px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-15-pc {
    font-size: 15px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-16-pc {
    font-size: 16px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-17-pc {
    font-size: 17px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-18-pc {
    font-size: 18px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-19-pc {
    font-size: 19px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-20-pc {
    font-size: 20px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-21-pc {
    font-size: 21px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-22-pc {
    font-size: 22px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-23-pc {
    font-size: 23px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-24-pc {
    font-size: 24px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-25-pc {
    font-size: 25px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-26-pc {
    font-size: 26px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-27-pc {
    font-size: 27px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-28-pc {
    font-size: 28px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-29-pc {
    font-size: 29px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-30-pc {
    font-size: 30px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-31-pc {
    font-size: 31px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-32-pc {
    font-size: 32px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-33-pc {
    font-size: 33px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-34-pc {
    font-size: 34px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-35-pc {
    font-size: 35px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-36-pc {
    font-size: 36px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-37-pc {
    font-size: 37px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-38-pc {
    font-size: 38px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-39-pc {
    font-size: 39px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-40-pc {
    font-size: 40px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-41-pc {
    font-size: 41px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-42-pc {
    font-size: 42px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-43-pc {
    font-size: 43px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-44-pc {
    font-size: 44px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-45-pc {
    font-size: 45px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-46-pc {
    font-size: 46px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-47-pc {
    font-size: 47px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-48-pc {
    font-size: 48px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-49-pc {
    font-size: 49px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-50-pc {
    font-size: 50px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-51-pc {
    font-size: 51px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-52-pc {
    font-size: 52px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-53-pc {
    font-size: 53px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-54-pc {
    font-size: 54px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-55-pc {
    font-size: 55px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-56-pc {
    font-size: 56px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-57-pc {
    font-size: 57px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-58-pc {
    font-size: 58px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-59-pc {
    font-size: 59px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-60-pc {
    font-size: 60px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-61-pc {
    font-size: 61px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-62-pc {
    font-size: 62px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-63-pc {
    font-size: 63px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-64-pc {
    font-size: 64px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-65-pc {
    font-size: 65px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-66-pc {
    font-size: 66px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-67-pc {
    font-size: 67px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-68-pc {
    font-size: 68px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-69-pc {
    font-size: 69px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-70-pc {
    font-size: 70px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-71-pc {
    font-size: 71px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-72-pc {
    font-size: 72px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-73-pc {
    font-size: 73px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-74-pc {
    font-size: 74px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-75-pc {
    font-size: 75px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-76-pc {
    font-size: 76px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-77-pc {
    font-size: 77px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-78-pc {
    font-size: 78px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-79-pc {
    font-size: 79px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-80-pc {
    font-size: 80px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-81-pc {
    font-size: 81px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-82-pc {
    font-size: 82px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-83-pc {
    font-size: 83px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-84-pc {
    font-size: 84px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-85-pc {
    font-size: 85px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-86-pc {
    font-size: 86px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-87-pc {
    font-size: 87px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-88-pc {
    font-size: 88px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-89-pc {
    font-size: 89px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-90-pc {
    font-size: 90px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-91-pc {
    font-size: 91px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-92-pc {
    font-size: 92px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-93-pc {
    font-size: 93px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-94-pc {
    font-size: 94px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-95-pc {
    font-size: 95px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-96-pc {
    font-size: 96px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-97-pc {
    font-size: 97px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-98-pc {
    font-size: 98px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-99-pc {
    font-size: 99px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-100-pc {
    font-size: 100px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-101-pc {
    font-size: 101px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-102-pc {
    font-size: 102px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-103-pc {
    font-size: 103px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-104-pc {
    font-size: 104px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-105-pc {
    font-size: 105px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-106-pc {
    font-size: 106px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-107-pc {
    font-size: 107px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-108-pc {
    font-size: 108px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-109-pc {
    font-size: 109px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-110-pc {
    font-size: 110px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-111-pc {
    font-size: 111px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-112-pc {
    font-size: 112px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-113-pc {
    font-size: 113px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-114-pc {
    font-size: 114px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-115-pc {
    font-size: 115px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-116-pc {
    font-size: 116px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-117-pc {
    font-size: 117px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-118-pc {
    font-size: 118px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-119-pc {
    font-size: 119px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-120-pc {
    font-size: 120px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-121-pc {
    font-size: 121px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-122-pc {
    font-size: 122px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-123-pc {
    font-size: 123px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-124-pc {
    font-size: 124px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-125-pc {
    font-size: 125px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-126-pc {
    font-size: 126px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-127-pc {
    font-size: 127px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-128-pc {
    font-size: 128px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-129-pc {
    font-size: 129px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-130-pc {
    font-size: 130px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-131-pc {
    font-size: 131px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-132-pc {
    font-size: 132px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-133-pc {
    font-size: 133px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-134-pc {
    font-size: 134px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-135-pc {
    font-size: 135px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-136-pc {
    font-size: 136px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-137-pc {
    font-size: 137px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-138-pc {
    font-size: 138px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-139-pc {
    font-size: 139px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-140-pc {
    font-size: 140px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-141-pc {
    font-size: 141px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-142-pc {
    font-size: 142px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-143-pc {
    font-size: 143px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-144-pc {
    font-size: 144px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-145-pc {
    font-size: 145px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-146-pc {
    font-size: 146px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-147-pc {
    font-size: 147px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-148-pc {
    font-size: 148px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-149-pc {
    font-size: 149px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-150-pc {
    font-size: 150px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-151-pc {
    font-size: 151px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-152-pc {
    font-size: 152px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-153-pc {
    font-size: 153px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-154-pc {
    font-size: 154px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-155-pc {
    font-size: 155px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-156-pc {
    font-size: 156px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-157-pc {
    font-size: 157px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-158-pc {
    font-size: 158px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-159-pc {
    font-size: 159px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-160-pc {
    font-size: 160px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-161-pc {
    font-size: 161px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-162-pc {
    font-size: 162px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-163-pc {
    font-size: 163px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-164-pc {
    font-size: 164px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-165-pc {
    font-size: 165px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-166-pc {
    font-size: 166px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-167-pc {
    font-size: 167px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-168-pc {
    font-size: 168px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-169-pc {
    font-size: 169px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-170-pc {
    font-size: 170px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-171-pc {
    font-size: 171px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-172-pc {
    font-size: 172px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-173-pc {
    font-size: 173px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-174-pc {
    font-size: 174px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-175-pc {
    font-size: 175px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-176-pc {
    font-size: 176px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-177-pc {
    font-size: 177px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-178-pc {
    font-size: 178px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-179-pc {
    font-size: 179px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-180-pc {
    font-size: 180px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-181-pc {
    font-size: 181px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-182-pc {
    font-size: 182px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-183-pc {
    font-size: 183px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-184-pc {
    font-size: 184px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-185-pc {
    font-size: 185px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-186-pc {
    font-size: 186px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-187-pc {
    font-size: 187px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-188-pc {
    font-size: 188px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-189-pc {
    font-size: 189px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-190-pc {
    font-size: 190px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-191-pc {
    font-size: 191px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-192-pc {
    font-size: 192px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-193-pc {
    font-size: 193px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-194-pc {
    font-size: 194px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-195-pc {
    font-size: 195px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-196-pc {
    font-size: 196px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-197-pc {
    font-size: 197px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-198-pc {
    font-size: 198px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-199-pc {
    font-size: 199px;
  }
}

@media print, screen and (min-width: 781px) {
  .u-font-200-pc {
    font-size: 200px;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-1-sp {
    font-size: 0.2564102564vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-2-sp {
    font-size: 0.5128205128vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-3-sp {
    font-size: 0.7692307692vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-4-sp {
    font-size: 1.0256410256vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-5-sp {
    font-size: 1.2820512821vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-6-sp {
    font-size: 1.5384615385vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-7-sp {
    font-size: 1.7948717949vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-8-sp {
    font-size: 2.0512820513vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-9-sp {
    font-size: 2.3076923077vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-10-sp {
    font-size: 2.5641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-11-sp {
    font-size: 2.8205128205vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-12-sp {
    font-size: 3.0769230769vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-13-sp {
    font-size: 3.3333333333vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-14-sp {
    font-size: 3.5897435897vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-15-sp {
    font-size: 3.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-16-sp {
    font-size: 4.1025641026vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-17-sp {
    font-size: 4.358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-18-sp {
    font-size: 4.6153846154vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-19-sp {
    font-size: 4.8717948718vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-20-sp {
    font-size: 5.1282051282vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-21-sp {
    font-size: 5.3846153846vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-22-sp {
    font-size: 5.641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-23-sp {
    font-size: 5.8974358974vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-24-sp {
    font-size: 6.1538461538vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-25-sp {
    font-size: 6.4102564103vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-26-sp {
    font-size: 6.6666666667vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-27-sp {
    font-size: 6.9230769231vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-28-sp {
    font-size: 7.1794871795vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-29-sp {
    font-size: 7.4358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-30-sp {
    font-size: 7.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-31-sp {
    font-size: 7.9487179487vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-32-sp {
    font-size: 8.2051282051vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-33-sp {
    font-size: 8.4615384615vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-34-sp {
    font-size: 8.7179487179vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-35-sp {
    font-size: 8.9743589744vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-36-sp {
    font-size: 9.2307692308vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-37-sp {
    font-size: 9.4871794872vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-38-sp {
    font-size: 9.7435897436vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-39-sp {
    font-size: 10vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-40-sp {
    font-size: 10.2564102564vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-41-sp {
    font-size: 10.5128205128vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-42-sp {
    font-size: 10.7692307692vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-43-sp {
    font-size: 11.0256410256vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-44-sp {
    font-size: 11.2820512821vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-45-sp {
    font-size: 11.5384615385vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-46-sp {
    font-size: 11.7948717949vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-47-sp {
    font-size: 12.0512820513vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-48-sp {
    font-size: 12.3076923077vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-49-sp {
    font-size: 12.5641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-50-sp {
    font-size: 12.8205128205vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-51-sp {
    font-size: 13.0769230769vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-52-sp {
    font-size: 13.3333333333vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-53-sp {
    font-size: 13.5897435897vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-54-sp {
    font-size: 13.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-55-sp {
    font-size: 14.1025641026vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-56-sp {
    font-size: 14.358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-57-sp {
    font-size: 14.6153846154vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-58-sp {
    font-size: 14.8717948718vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-59-sp {
    font-size: 15.1282051282vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-60-sp {
    font-size: 15.3846153846vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-61-sp {
    font-size: 15.641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-62-sp {
    font-size: 15.8974358974vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-63-sp {
    font-size: 16.1538461538vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-64-sp {
    font-size: 16.4102564103vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-65-sp {
    font-size: 16.6666666667vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-66-sp {
    font-size: 16.9230769231vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-67-sp {
    font-size: 17.1794871795vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-68-sp {
    font-size: 17.4358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-69-sp {
    font-size: 17.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-70-sp {
    font-size: 17.9487179487vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-71-sp {
    font-size: 18.2051282051vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-72-sp {
    font-size: 18.4615384615vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-73-sp {
    font-size: 18.7179487179vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-74-sp {
    font-size: 18.9743589744vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-75-sp {
    font-size: 19.2307692308vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-76-sp {
    font-size: 19.4871794872vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-77-sp {
    font-size: 19.7435897436vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-78-sp {
    font-size: 20vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-79-sp {
    font-size: 20.2564102564vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-80-sp {
    font-size: 20.5128205128vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-81-sp {
    font-size: 20.7692307692vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-82-sp {
    font-size: 21.0256410256vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-83-sp {
    font-size: 21.2820512821vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-84-sp {
    font-size: 21.5384615385vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-85-sp {
    font-size: 21.7948717949vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-86-sp {
    font-size: 22.0512820513vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-87-sp {
    font-size: 22.3076923077vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-88-sp {
    font-size: 22.5641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-89-sp {
    font-size: 22.8205128205vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-90-sp {
    font-size: 23.0769230769vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-91-sp {
    font-size: 23.3333333333vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-92-sp {
    font-size: 23.5897435897vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-93-sp {
    font-size: 23.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-94-sp {
    font-size: 24.1025641026vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-95-sp {
    font-size: 24.358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-96-sp {
    font-size: 24.6153846154vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-97-sp {
    font-size: 24.8717948718vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-98-sp {
    font-size: 25.1282051282vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-99-sp {
    font-size: 25.3846153846vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-100-sp {
    font-size: 25.641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-101-sp {
    font-size: 25.8974358974vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-102-sp {
    font-size: 26.1538461538vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-103-sp {
    font-size: 26.4102564103vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-104-sp {
    font-size: 26.6666666667vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-105-sp {
    font-size: 26.9230769231vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-106-sp {
    font-size: 27.1794871795vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-107-sp {
    font-size: 27.4358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-108-sp {
    font-size: 27.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-109-sp {
    font-size: 27.9487179487vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-110-sp {
    font-size: 28.2051282051vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-111-sp {
    font-size: 28.4615384615vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-112-sp {
    font-size: 28.7179487179vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-113-sp {
    font-size: 28.9743589744vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-114-sp {
    font-size: 29.2307692308vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-115-sp {
    font-size: 29.4871794872vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-116-sp {
    font-size: 29.7435897436vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-117-sp {
    font-size: 30vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-118-sp {
    font-size: 30.2564102564vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-119-sp {
    font-size: 30.5128205128vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-120-sp {
    font-size: 30.7692307692vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-121-sp {
    font-size: 31.0256410256vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-122-sp {
    font-size: 31.2820512821vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-123-sp {
    font-size: 31.5384615385vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-124-sp {
    font-size: 31.7948717949vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-125-sp {
    font-size: 32.0512820513vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-126-sp {
    font-size: 32.3076923077vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-127-sp {
    font-size: 32.5641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-128-sp {
    font-size: 32.8205128205vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-129-sp {
    font-size: 33.0769230769vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-130-sp {
    font-size: 33.3333333333vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-131-sp {
    font-size: 33.5897435897vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-132-sp {
    font-size: 33.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-133-sp {
    font-size: 34.1025641026vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-134-sp {
    font-size: 34.358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-135-sp {
    font-size: 34.6153846154vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-136-sp {
    font-size: 34.8717948718vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-137-sp {
    font-size: 35.1282051282vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-138-sp {
    font-size: 35.3846153846vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-139-sp {
    font-size: 35.641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-140-sp {
    font-size: 35.8974358974vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-141-sp {
    font-size: 36.1538461538vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-142-sp {
    font-size: 36.4102564103vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-143-sp {
    font-size: 36.6666666667vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-144-sp {
    font-size: 36.9230769231vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-145-sp {
    font-size: 37.1794871795vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-146-sp {
    font-size: 37.4358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-147-sp {
    font-size: 37.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-148-sp {
    font-size: 37.9487179487vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-149-sp {
    font-size: 38.2051282051vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-150-sp {
    font-size: 38.4615384615vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-151-sp {
    font-size: 38.7179487179vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-152-sp {
    font-size: 38.9743589744vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-153-sp {
    font-size: 39.2307692308vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-154-sp {
    font-size: 39.4871794872vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-155-sp {
    font-size: 39.7435897436vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-156-sp {
    font-size: 40vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-157-sp {
    font-size: 40.2564102564vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-158-sp {
    font-size: 40.5128205128vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-159-sp {
    font-size: 40.7692307692vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-160-sp {
    font-size: 41.0256410256vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-161-sp {
    font-size: 41.2820512821vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-162-sp {
    font-size: 41.5384615385vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-163-sp {
    font-size: 41.7948717949vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-164-sp {
    font-size: 42.0512820513vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-165-sp {
    font-size: 42.3076923077vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-166-sp {
    font-size: 42.5641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-167-sp {
    font-size: 42.8205128205vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-168-sp {
    font-size: 43.0769230769vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-169-sp {
    font-size: 43.3333333333vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-170-sp {
    font-size: 43.5897435897vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-171-sp {
    font-size: 43.8461538462vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-172-sp {
    font-size: 44.1025641026vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-173-sp {
    font-size: 44.358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-174-sp {
    font-size: 44.6153846154vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-175-sp {
    font-size: 44.8717948718vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-176-sp {
    font-size: 45.1282051282vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-177-sp {
    font-size: 45.3846153846vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-178-sp {
    font-size: 45.641025641vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-179-sp {
    font-size: 45.8974358974vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-180-sp {
    font-size: 46.1538461538vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-181-sp {
    font-size: 46.4102564103vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-182-sp {
    font-size: 46.6666666667vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-183-sp {
    font-size: 46.9230769231vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-184-sp {
    font-size: 47.1794871795vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-185-sp {
    font-size: 47.4358974359vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-186-sp {
    font-size: 47.6923076923vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-187-sp {
    font-size: 47.9487179487vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-188-sp {
    font-size: 48.2051282051vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-189-sp {
    font-size: 48.4615384615vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-190-sp {
    font-size: 48.7179487179vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-191-sp {
    font-size: 48.9743589744vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-192-sp {
    font-size: 49.2307692308vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-193-sp {
    font-size: 49.4871794872vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-194-sp {
    font-size: 49.7435897436vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-195-sp {
    font-size: 50vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-196-sp {
    font-size: 50.2564102564vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-197-sp {
    font-size: 50.5128205128vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-198-sp {
    font-size: 50.7692307692vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-199-sp {
    font-size: 51.0256410256vw;
  }
}

@media print, screen and (max-width: 780px) {
  .u-font-200-sp {
    font-size: 51.2820512821vw;
  }
}